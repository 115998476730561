import React, { useState, useEffect } from "react";
import { MuiModal, LrxBtnAction, LrxBtn } from "../../../components";
import { createExcelFile } from "../../../utils";

export function ModalDownloadCsv(props) {
  const { logo, text_btn, name_csv, data_to_export, data_keys } = props;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [checkedItems, setCheckedItems] = useState(data_keys);

  useEffect(() => {
    setCheckedItems(data_keys);
  }, [data_keys]);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    if (checked) {
      setCheckedItems((prevCheckedItems) => [...prevCheckedItems, name]);
    } else {
      setCheckedItems((prevCheckedItems) =>
        prevCheckedItems.filter((item) => item !== name)
      );
    }
  };

  const handleSelectAll = () => {
    if (checkedItems.length === 0) {
      setCheckedItems(data_keys);
    } else {
      setCheckedItems([]);
    }
  };

  const filteredItems = data_keys.filter(
    (item) => !checkedItems.includes(item)
  );

  return (
    <div>
      <LrxBtnAction
        action_btn={() => handleOpen()}
        logo={logo}
        text_btn={text_btn}
      />
      <MuiModal open={open} onClose={handleClose}>
        <div className="modal-csv-export">
          <div className="modal-csv-export__header">
            <h1 className="need-premium-modal__explain__text">
              Custom your export
            </h1>
            <h1 className="lrx-color-text">
                Choose columns to include
            </h1>          
          </div>
          <div>
            <div className="modal-csv-check-box-checkall">
              <label class="btn-phase-container">
                <input 
                  type="checkbox" 
                  checked={checkedItems.length === 0 ? false : true} 
                  onChange={handleSelectAll}>
                </input>
                <svg viewBox="0 0 64 64" height="2em" width="2em">
                  <path d="M 0 16 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 16 L 32 48 L 64 16 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 16" pathLength="575.0541381835938" class="path"></path>
                </svg>
              </label> 
              <span>
                {checkedItems.length === 0 ? "Select all" : "Deselect all"}
              </span>                 
            </div>
            <div className="modal-csv-check-box">
              <ul>
                {data_keys.map((fruit) => (
                  <li key={fruit}>
                    <label class="btn-phase-container">
                      <input 
                        type="checkbox" 
                        checked={checkedItems.includes(fruit)} 
                        onChange={handleCheckboxChange}
                        name={fruit}>
                      </input>
                      <svg viewBox="0 0 64 64" height="2em" width="2em">
                        <path d="M 0 16 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 16 L 32 48 L 64 16 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 16" pathLength="575.0541381835938" class="path"></path>
                      </svg>
                    </label> 
                    <span>
                      {fruit}
                    </span>  
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="need-premium-modal__validate">
            <LrxBtn
              action_btn={() =>
                createExcelFile(data_to_export, name_csv, filteredItems)
              }
              logo={"saveIcon"}
              text_btn={"Export to excel"}
              my_className={"need-premium-modal__validate--btn"}
            />
          </div>
        </div>
      </MuiModal>
    </div>
  );
}
