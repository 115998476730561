import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import { DataTableInteraction, LoadingAnimation } from "../../components";

import { filterDataDrug, get_filter_key } from "../../utils";
import { getAllDrugCompetitive, setcompetitiveDataLoadingAnimation } from "../../Action";

export function CompetitiveAllDrugTable() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const depth = useSelector((state) => state.diease.statusDrugDisplay);
  //const MOA = useSelector ( state => state.diease.MOADisplay);
  const type = useSelector((state) => state.diease.drugTypeDisplay);
  const disease = useSelector((state) => state.diease.diseaseDisplay);
  const theraFranchise = useSelector(
    (state) => state.diease.theraFranchiseDisplay
  );
  const targetDisplay = useSelector((state) => state.diease.drugTargetDisplay);
  const alldrug = useSelector((state) => state.drug.nameDrug);
  const data = filterDataDrug(
    alldrug,
    depth,
    disease,
    type,
    [],
    theraFranchise,
    targetDisplay
  );
  const load = useSelector((state) => state.config.tabCompetitiveDataLoading);

  useEffect(() => {
    if (alldrug && alldrug.length === 0) {
      dispatch(getAllDrugCompetitive());
      dispatch(setcompetitiveDataLoadingAnimation(true));
    }
  }, [dispatch, alldrug]);

  const go_to = (name, id) => {
    if (name === "drug") {
      navigate(`/drug/${id}`);
    }
    if (name === "company_display_name") {
      navigate(`/company/${id}`);
    }
  };

  const columns = [
    {
      name: "drug_true_name",
      header: "Drug",
      defaultFlex: 1,
      minWidth: 80,
      maxWidth: 150,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("drug_true_name", data),
      },
      render: ({ value, data }) => (
        <span className= "title-link" onClick={() => go_to("drug", data.id_drug)}>{value}</span>
      ),
    },
    {
      name: "disease_display_name",
      header: "Disease",
      defaultFlex: 1,      
      minWidth: 160,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("disease_display_name", data),
      },
    },
    {
      name: "drug_current_phase",
      header: "Phase",
      minWidth: 110,
      maxWidth: 130,
      defaultFlex: 1,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("drug_current_phase", data),
      },
    },
    {
      name: "target",
      header: "Biological Target",
      minWidth: 100,
      defaultFlex: 1,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("target", data),
      },
    },
    {
      name: "drug_type",
      header: "Drug Type",
      minWidth: 155,
      defaultFlex: 1,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("drug_type", data),
      },
    },
    {
      name: "company_display_name",
      header: "Lead",
      minWidth: 215,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("company_display_name", data),
      },
      render: ({ value, data }) => (
        <span className= "title-link" onClick={() => go_to("company_display_name", data.id_company)}>
          {value}
        </span>
      ),
    },
  ];

  const filterValue = [
    { name: "drug_true_name", operator: "inlist", type: "select", value: "" },
    {
      name: "disease_display_name",
      operator: "inlist",
      type: "select",
      value: "",
    },
    {
      name: "therapeutic_franchise_name",
      operator: "inlist",
      type: "select",
      value: "",
    },
    {
      name: "drug_current_phase",
      operator: "inlist",
      type: "select",
      value: "",
    },
    { name: "target", operator: "inlist", type: "select", value: "" },
    { name: "drug_type", operator: "inlist", type: "select", value: "" },
    {
      name: "company_display_name",
      operator: "inlist",
      type: "select",
      value: "",
    },
  ];

  return (
    <div style={{ height: 700, width: "100%" }}>
      {load === false ? (
        <DataTableInteraction
          dataSource={data}
          columns={columns}
          filterValue={filterValue}
          defaultFilterName={[]}
        />
      ) : (
        <div style={{height: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
          <LoadingAnimation type={"circular"} />
        </div>
      )}
    </div>
  );
}
