import React from "react";
import { useNavigate } from "react-router-dom";

import { MuiCard } from "../../components";

export function CompanyCard(props) {
  const { content, allDrug } = props;
  const navigate = useNavigate();

  const selectThisCompany = (content) => {
    navigate(`/company/${content.id_company}`);
  };
  return (
    <div className="company-card" onClick={() => selectThisCompany(content)}>
      <MuiCard mui_class={"company-card__mui-card"}>
        <div className="company-card__mui-card__title">
          <div className="company-card__mui-card__title--company">
            {" "}
            {content.company_display_name}{" "}
          </div>
          <div className="company-card__mui-card__title--logo-container">
            {" "}
            <img
              className="company-card__mui-card__title--logo-image"
              src={"https://logo.clearbit.com/https:/" + content.website}
              alt=""
            ></img>
          </div>
        </div>
        <div>
          {" "}
          <span className="company-card__mui-card__spec--title">ticker: </span>
          {content.company_ticker}{" "}
        </div>
        <div>
          {" "}
          <span className="company-card__mui-card__spec--title">
            market cap:{" "}
          </span>
          {content.company_market_cap_type}{" "}
        </div>
        <div>
          {allDrug.length !== 0 ? (
            <span className="company-card__mui-card__spec--title">Drugs: </span>
          ) : null}
        </div>
        {allDrug.map((el) => (
          <div>
            {" - "}
            {el.drug_true_name}
            {" ("}
            {el.phase}
            {el.id_company_lead
              .split("/")
              .flat()
              .map((elem) => parseInt(elem, 10))
              .includes(content.id_company)
              ? "Lead"
              : "Partner"}
            {")"}
          </div>
        ))}
      </MuiCard>
    </div>
  );
}
