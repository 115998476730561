const initialState = {
  statusDrugDisplay: [
    'off-patent',
    "approved",
    "nda/bla",
    "phase 3",
    "phase 2",
    "phase 1",
    "preclinical",
  ],
  statusCompanyDisplay: [
    "Nano-cap",
    "Micro-cap",
    "Small-cap",
    "Mid-cap",
    "Big-cap",
    "Listed",
    "Private",
  ],
  drugDisplay: null,
  companyDisplay: [],
  diseaseDisplay: [],
  drugTypeDisplay: [],
  drugTargetDisplay: [],
  MOADisplay: [],
  theraFranchiseDisplay: [],
  nameDisease: [],
  nameDrugTarget: [],
  nameDrugType: [],
  nameMOA: [],
  nameTheraFranchise: [],
  speciality_user: [],
  speciality_name: [],
  profession_group_user: [],
  profession_group_name: [],
  location_user: [],
  location_name: [],
};

export function getDiseaseBQ(state = initialState, action) {
  let nextState;
  switch (action.type) {
    case "SET_TREEMAP_TD_DEPTH":
      const td = state.statusDrugDisplay.findIndex(
        (item) => item === action.value
      );
      if (td !== -1) {
        nextState = {
          ...state,
          statusDrugDisplay: state.statusDrugDisplay.filter(
            (item) => item !== action.value
          ),
        };
      } else {
        nextState = {
          ...state,
          statusDrugDisplay: [...state.statusDrugDisplay, action.value],
        };
      }
      return nextState || state;

    case "SET_TREEMAP_TT_DEPTH":
      const d = state.statusCompanyDisplay.findIndex(
        (item) => item === action.value
      );
      if (d !== -1) {
        nextState = {
          ...state,
          statusCompanyDisplay: state.statusCompanyDisplay.filter(
            (item) => item !== action.value
          ),
        };
      } else {
        nextState = {
          ...state,
          statusCompanyDisplay: [...state.statusCompanyDisplay, action.value],
        };
      }
      return nextState || state;

    case "GET_ALL_DISEASE":
      nextState = {
        ...state,
        nameDisease: action.value,
      };
      return nextState || state;

    case "SET_DISEASE_DISPLAY":
      const f = state.diseaseDisplay.findIndex(
        (item) => item.id_disease === action.value.id_disease
      );
      if (f !== -1) {
        nextState = {
          ...state,
        };
      } else {
        nextState = {
          ...state,
          diseaseDisplay: [...state.diseaseDisplay, action.value],
        };
      }
      return nextState || state;

    case "REMOVE_DISEASE_DISPLAY":
      const fRemove = state.diseaseDisplay.findIndex(
        (item) => item.id_disease === action.value
      );
      if (fRemove !== -1) {
        nextState = {
          ...state,
          diseaseDisplay: state.diseaseDisplay.filter(
            (item) => item.id_disease !== action.value
          ),
        };
      } else {
        nextState = {
          ...state,
        };
      }
      return nextState || state;

    case "GET_ALL_DRUG_TYPE":
      nextState = {
        ...state,
        nameDrugType: action.value,
      };
      return nextState || state;

    case "SET_DRUG_TYPE_DISPLAY":
      const t = state.drugTypeDisplay.findIndex(
        (item) => item.id_drug_type === action.value.id_drug_type
      );
      if (t !== -1) {
        nextState = {
          ...state,
        };
      } else {
        nextState = {
          ...state,
          drugTypeDisplay: [...state.drugTypeDisplay, action.value],
        };
      }
      return nextState || state;

    case "REMOVE_DRUG_TYPE_DISPLAY":
      const ttoRemove = state.drugTypeDisplay.findIndex(
        (item) => item.id_drug_type === action.value
      );
      if (ttoRemove !== -1) {
        nextState = {
          ...state,
          drugTypeDisplay: state.drugTypeDisplay.filter(
            (item) => item.id_drug_type !== action.value
          ),
        };
      } else {
        nextState = {
          ...state,
        };
      }
      return nextState || state;

    case "GET_ALL_MOA":
      nextState = {
        ...state,
        nameMOA: action.value,
      };
      return nextState || state;

    case "SET_MOA_DISPLAY":
      const m = state.MOADisplay.findIndex(
        (item) => item.id_moa === action.value
      );
      if (m !== -1) {
        nextState = {
          ...state,
          MOADisplay: state.MOADisplay.filter(
            (item) => item.id_moa !== action.value
          ),
        };
      } else {
        nextState = {
          ...state,
          MOADisplay: [...state.MOADisplay, action.value],
        };
      }
      return nextState || state;

    case "GET_ALL_THERA_FRANCHISE":
      nextState = {
        ...state,
        nameTheraFranchise: action.value,
      };
      return nextState || state;

    case "SET_THERA_FRANCHISE":
      const tf = state.theraFranchiseDisplay.findIndex(
        (item) =>
          item.id_therapeutic_franchise ===
          action.value.id_therapeutic_franchise
      );
      if (tf !== -1) {
        nextState = {
          ...state,
        };
      } else {
        nextState = {
          ...state,
          theraFranchiseDisplay: [...state.theraFranchiseDisplay, action.value],
        };
      }
      return nextState || state;

    case "REMOVE_THERA_FRANCHISE":
      const tftoRemove = state.theraFranchiseDisplay.findIndex(
        (item) => item.id_therapeutic_franchise === action.value
      );
      if (tftoRemove !== -1) {
        nextState = {
          ...state,
          theraFranchiseDisplay: state.theraFranchiseDisplay.filter(
            (item) => item.id_therapeutic_franchise !== action.value
          ),
        };
      } else {
        nextState = {
          ...state,
        };
      }
      return nextState || state;

    case "GET_ALL_TARGET":
      nextState = {
        ...state,
        nameDrugTarget: action.value,
      };
      return nextState || state;

    case "SET_TARGET":
      const tg = state.drugTargetDisplay.findIndex(
        (item) => item.id_drug_target === action.value.id_drug_target
      );
      if (tg !== -1) {
        nextState = {
          ...state,
        };
      } else {
        nextState = {
          ...state,
          drugTargetDisplay: [...state.drugTargetDisplay, action.value],
        };
      }
      return nextState || state;

    case "REMOVE_TARGET":
      const tgtoRemove = state.drugTargetDisplay.findIndex(
        (item) => item.id_drug_target === action.value
      );
      if (tgtoRemove !== -1) {
        nextState = {
          ...state,
          drugTargetDisplay: state.drugTargetDisplay.filter(
            (item) => item.id_drug_target !== action.value
          ),
        };
      } else {
        nextState = {
          ...state,
        };
      }
      return nextState || state;

    case "GET_ALL_SPECIALITY":
      nextState = {
        ...state,
        speciality_name: action.value,
      };
      return nextState || state;

    case "SET_SPECIALITY_DOL_USER":
      const s = state.speciality_user.findIndex(
        (item) =>
          item.id_profession ===
          action.value.id_profession
      );
      if (s !== -1) {
        nextState = {
          ...state,
        };
      } else {
        nextState = {
          ...state,
          speciality_user: [...state.speciality_user, action.value],
        };
      }
      return nextState || state;      
    
    case "REMOVE_SPECIALITY_DOL_USER":
      const sRemove = state.speciality_user.findIndex(
        (item) => item.id_profession === action.value
      );
      if (sRemove !== -1) {
        nextState = {
          ...state,
          speciality_user: state.speciality_user.filter(
            (item) => item.id_profession !== action.value
          ),
        };
      } else {
        nextState = {
          ...state,
        };
      }
      return nextState || state;

    case "GET_ALL_PROFESSION_GROUP":
      nextState = {
        ...state,
        profession_group_name: action.value,
      };
      return nextState || state;

    case "SET_PROFESSION_GROUP_DOL_USER":
      const pg = state.profession_group_user.findIndex(
        (item) =>
          item.id_profession_group ===
          action.value.id_profession_group
      );
      if (pg !== -1) {
        nextState = {
          ...state,
        };
      } else {
        nextState = {
          ...state,
          profession_group_user: [...state.profession_group_user, action.value],
        };
      }
      return nextState || state;      
    
    case "REMOVE_PROFESSION_GROUP_DOL_USER":
      const pgRemove = state.profession_group_user.findIndex(
        (item) => item.id_profession_group === action.value
      );
      if (pgRemove !== -1) {
        nextState = {
          ...state,
          profession_group_user: state.profession_group_user.filter(
            (item) => item.id_profession_group !== action.value
          ),
        };
      } else {
        nextState = {
          ...state,
        };
      }
      return nextState || state;

    case "GET_ALL_LOCATION":
      nextState = {
        ...state,
        location_name: action.value,
      };
      return nextState || state;

    case "SET_LOCATION_DOL_USER":
      const l = state.location_user.findIndex(
        (item) =>
          item.location ===
          action.value.location
      );
      if (l !== -1) {
        nextState = {
          ...state,
        };
      } else {
        nextState = {
          ...state,
          location_user: [...state.location_user, action.value],
        };
      }
      return nextState || state;      
    
    case "REMOVE_LOCATION_DOL_USER":
      const lRemove = state.location_user.findIndex(
        (item) => item.location === action.value
      );
      if (lRemove !== -1) {
        nextState = {
          ...state,
          location_user: state.location_user.filter(
            (item) => item.location !== action.value
          ),
        };
      } else {
        nextState = {
          ...state,
        };
      }
      return nextState || state;

    case "SET_DRUG_DISPLAY":
      nextState = {
        ...state,
        drugDisplay: action.value,
      };
      return nextState || state;

    case "RESET_DISPLAY_METADATA":
      nextState = {
        ...state,
        diseaseDisplay: [],
        drugTypeDisplay: [],
        drugTargetDisplay: [],
        MOADisplay: [],
        theraFranchiseDisplay: [],
        speciality_user: [],
        profession_group_user: [],
        location_user: [],
        statusDrugDisplay: [
          'off-patent',
          "approved",
          "nda/bla",
          "phase 3",
          "phase 2",
          "phase 1",
          "preclinical",
        ],
        statusCompanyDisplay: [
          "Nano-cap",
          "Micro-cap",
          "Small-cap",
          "Mid-cap",
          "Big-cap",
          "Listed",
          "Private",
        ],
      };
      return nextState || state;

    case "ERROR_DATA_DISEASE":
      return state;

    default:
      return state;
  }
}
