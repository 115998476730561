const initialState = {
authLoading :false,
connected :false,
auth_name : null,
}


export function authentication(state  = initialState, action) {
  let nextState;
    switch (action.type) {
        case 'LOGIN_SUCCESS' :
          nextState= {
            ...state, 
            connected  : true,
            auth_name : action.value.user.username
          }
          localStorage.setItem('token', action.value.token);
          return nextState || state;
          
        case 'CHECK_CONNECTION' :
          nextState= {
            ...state, 
            connected  : true,
            auth_name : action.value
          }
          return nextState || state;
          

        case 'CHECK_CONNECTION_FAIL' :
            return  state;

        case 'SET_AUTH_LOADING_ANIMATION' :
          nextState= {
            ...state, 
            authLoading  : action.value,
          }
          return nextState || state;
        case 'LOGIN_FAIL' :
          return  state;
        default:
            return state;
    }
  }