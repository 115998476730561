const initialState = {
  treemap: [],
  tweet: [],
  data: [],
  drugComapny: [],
  drugInfo: {},
  databyId: {
    by_drug: [],
    by_disease: [],
    by_disease_dol: [],
    by_dol: [],
  },
  dataDisease: [],
  dataDiseaseTable: [],
  publicationTD: [],
  RSSTD: [],
  NCTTD: [],
  treatmentTD: [],
  displayed: 50,
  nameDrug: [],
  nameDynamicDrug: [],
  topics: [],
  worldCloudDisease: [],
  publications: [],
  Allpublications: [],
  NCTTab: [],
  NCTgraph: [],
  infoByNCT: [],
  TreatmentGraph: [],
  RSSTab: [],
  graphPhaseTimeline: [],
};

export function getDataBQ(state = initialState, action) {
  let nextState;
  switch (action.type) {
    case "GET_ALL_TD_DATA":
      nextState = {
        ...state,
        data: action.value,
      };
      return nextState || state;

    case "GET_DRUG_TWEET":
      nextState = {
        ...state,
        tweet: action.value,
      };
      return nextState || state;

    case "GET_ALERTS_PUBLICATION_DATA":
      nextState = {
        ...state,
        publicationTD: action.value,
      };
      return nextState || state;

    case "GET_ALERTS_RSS_DATA":
      nextState = {
        ...state,
        RSSTD: action.value,
      };
      return nextState || state;

    case "GET_ALERTS_NCT_DATA":
      nextState = {
        ...state,
        NCTTD: action.value,
      };
      return nextState || state;

    case "GET_ALERTS_TREATMENT_DATA":
      nextState = {
        ...state,
        treatmentTD: action.value,
      };
      return nextState || state;

    case "GET_ALL_DRUG":
      nextState = {
        ...state,
        nameDrug: action.value,
      };
      return nextState || state;

    case "GET_DRUG_DYNAMIC_SEARCH":
      nextState = {
        ...state,
        nameDynamicDrug: action.value,
      };
      return nextState || state;

    case "GET_DATA_BY_DRUG":
      nextState = {
        ...state,
        databyId: {
          ...state.databyId,
          by_drug: action.value,
        },
      };
      return nextState || state;

    case "GET_DATA_BY_DRUG_DISEASE":
      nextState = {
        ...state,
        databyId: {
          ...state.databyId,
          by_disease: action.value,
        },
      };
      return nextState || state;

    case "GET_DATA_BY_DRUG_DOL":
      nextState = {
        ...state,
        databyId: {
          ...state.databyId,
          by_dol: action.value,
        },
      };
      return nextState || state;

    case "GET_DATA_BY_DRUG_DISEASE_DOL":
      nextState = {
        ...state,
        databyId: {
          ...state.databyId,
          by_disease_dol: action.value,
        },
      };
      return nextState || state;

    case "GET_DATA_VOLUME_BY_DISEASE":
      nextState = {
        ...state,
        dataDisease: action.value,
      };
      return nextState || state;

    case "GET_DATA_TABLE_VOLUME_BY_DISEASE":
      nextState = {
        ...state,
        dataDiseaseTable: action.value,
      };
      return nextState || state;

    case "GET_DATA_BY_DISEASE":
      nextState = {
        ...state,
        databyId: action.value,
      };
      return nextState || state;

    case "GET_INFO_BY_DRUG":
      nextState = {
        ...state,
        drugInfo: action.value,
      };
      return nextState || state;

    case "GET_WORLD_CLOUD_DRUG_DISEASE":
      nextState = {
        ...state,
        worldCloudDisease: action.value,
      };
      return nextState || state;

    case "GET_DATA_TREEMAP_TD":
      nextState = {
        ...state,
        treemap: action.value,
      };
      return nextState || state;

    case "SET_NB_DRUGS_DISPLAY":
      nextState = {
        ...state,
        displayed: action.value,
      };
      return nextState || state;

    case "GET_TOPICS":
      nextState = {
        ...state,
        topics: action.value,
      };
      return nextState || state;

    case "GET_PUBLICATIONS":
      nextState = {
        ...state,
        publications: action.value,
      };
      return nextState || state;

    case "GET_ALL_PUBLICATIONS":
      nextState = {
        ...state,
        Allpublications: action.value,
      };
      return nextState || state;

    case "GET_NCT_GRAPH":
      nextState = {
        ...state,
        NCTgraph: action.value,
      };
      return nextState || state;

    case "GET_INFO_BY_NCT":
      nextState = {
        ...state,
        infoByNCT: action.value,
      };
      return nextState || state;

    case "GET_DRUG_TREATMENT_GRAPH":
      nextState = {
        ...state,
        TreatmentGraph: action.value,
      };
      return nextState || state;

    case "GET_NCT_TAB":
      nextState = {
        ...state,
        NCTTab: action.value,
      };
      return nextState || state;

    case "GET_RSS_TAB":
      nextState = {
        ...state,
        RSSTab: action.value,
      };
      return nextState || state;
    case "GET_PHASE_TIMELINE_GRAPH":
      nextState = {
        ...state,
        graphPhaseTimeline: action.value,
      };
      return nextState || state;

    case "ERROR_DATA_DRUG":
      return state;

    default:
      return state;
  }
}
