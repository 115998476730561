import React from 'react';
import {
  BarChart,
  Bar,
  LabelList,
  YAxis,
  XAxis,
  ResponsiveContainer
} from 'recharts';

export function HorizontalBarChart(props) {
const {data} = props

const data2 = [
    {
      name: data.conference_name + ' '+data.year,
      'volume' :((data.volume / data.volume_max) * 100).toFixed(1),
      'volume_max': 100 - ((data.volume / data.volume_max) * 100).toFixed(1),
    }
  ];

const renderCustomizedLabel = (props) => {
    const { x, y , height,width , value } = props;
    return (
      <g>
        <text x={x + width  / 2} y={y + height / 2} fill="#212121" textAnchor="middle" dominantBaseline="middle">
          {value} {'%'}
        </text>
      </g>
    );
  };

  return (
    <ResponsiveContainer width={500} height={45}>
    <BarChart
    layout="vertical" 
      width={70}
      height={250}
      data={data2} >
      <XAxis type="number" hide />
       <YAxis type="category" width={150} padding={{ left: 20 }} dataKey="name"/>
      <Bar dataKey="volume" stackId="a" fill={data.sentiement !== 'negative' ? "#2abc68" : "#f63333"}>
      </Bar>
      <Bar dataKey="volume_max" stackId="a" fill="#cfcfcf">
      <LabelList dataKey="volume" content={renderCustomizedLabel} />
      </Bar>
    </BarChart>
    </ResponsiveContainer>
  );
}