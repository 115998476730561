import React from "react";
import { useDispatch } from "react-redux";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
  ResponsiveContainer,
} from "recharts";

import { set_dolprofile_graph_year } from "../../../../Action";

const get_color = (sentiment) => {
if (sentiment < 0) {
    return '#f49999'
}
return '#94deb4'
};

export function PositiveNegativeBarChart(props) {
  const { data } = props;
  const dispatch = useDispatch();

  const handleChange = (event) => {
    if (event) {
      dispatch(set_dolprofile_graph_year(event.activeLabel));
    }
  };

  return (
    <ResponsiveContainer width="100%" height={160}>
      <BarChart
        data={data}
        margin={{
          top: 20,
          right: 25,
          left: 15,
          bottom: 0,
        }}
        onClick={handleChange}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" height={40} />
        <YAxis />
        <Tooltip />
        <ReferenceLine y={0} stroke="#000" />
        <Bar dataKey="user" fill="#8884d8">
          {" "}
          {data.map((entry, index) => {
            return <Cell key={`cell-${index}`} fill={get_color(entry.user)} />;
          })}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}
