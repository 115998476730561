import React, {useState} from "react";
import { useDispatch } from 'react-redux'
import { setDrugCompetiveChoose} from '../../../../Action';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css'
import "@inovua/reactdatagrid-community/theme/blue-light.css";
import "@inovua/reactdatagrid-community/base.css";
import "@inovua/reactdatagrid-community/index.css";

const gridStyle = { 
  minHeight: '100%',   
  fontFamily: 'Lato',
  fontSize: 11 
};



export function DataTableInteraction(props) {
    const [selected, setSelected] = useState({});
    const {columns, dataSource, filterValue, enableFiltering, customsFilter = {}} = props
    const dispatch = useDispatch();

    const filterTypes = Object.assign({}, ReactDataGrid.defaultProps.filterTypes, {
      customsFilter
    });

      const onSelectionChange = ({ selected }) => {
        if (selected === true ) {
            setSelected(selected)
            const selectedRowsData = dataSource.map(function (el) { return el['id_drug']; });
            dispatch(setDrugCompetiveChoose(selectedRowsData))
        }
        else {
            setSelected(selected)
            const selectedRowsData = Object.values(selected).map(function (el) { return el['id_drug']; });
            dispatch(setDrugCompetiveChoose(selectedRowsData))
        }
      }
    return <ReactDataGrid
      idProperty="id"
      selected={selected}
      checkboxColumn
      onSelectionChange={onSelectionChange}
      scrollTopOnFilter={false}
      columns={columns}
      dataSource={dataSource}
      style={gridStyle}
      enableFiltering={enableFiltering}
      defaultFilterValue={filterValue}
      showDefaultFooter={false}
      filterTypes={filterTypes}
  />
  }
