import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";

import { DataFilterTable, LoadingAnimation } from "../../components";
import { getDataDiseaseTable, setVolumeDiseaseTabLoadingAnimation } from "../../Action";
import { get_filter_key } from "../../utils";

export function VolumeDiseaseTable(props) {
  const { which_btn, action_btn } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const date = useSelector((state) => state.config.date);
  const disease = useSelector((state) => state.diease.diseaseDisplay);
  const idDisease = disease.length === 0 ? 0 : disease[0].id_disease;
  const load = useSelector((state) => state.config.volumeDiseaseTab);
  const data = useSelector((state) => state.drug.dataDiseaseTable);

  useEffect(() => {
    dispatch(getDataDiseaseTable(date, idDisease, action_btn[which_btn]));
    dispatch(setVolumeDiseaseTabLoadingAnimation(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, idDisease, action_btn[which_btn], dispatch]);

  const go_to = (name, id) => {
    if (name === "drug") {
      navigate(`/drug/${id}`);
    }
    if (name === "company") {
      navigate(`/company/${id}`);
    }
  };

  const columns = [
    {
      name: "drug_true_name",
      header: "Drug",
      defaultFlex: 1,
      minWidth: 100,
      render: ({ value, data }) => (
        <span onClick={() => go_to("drug", data.id_drug)}>{value}</span>
      ),
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("drug_true_name", data),
      },
    },
    {
      name: "disease_display_name",
      header: "Disease",
      defaultFlex: 1,
      minWidth: 300,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("disease_display_name", data),
      },
    },
    {
      name: "target",
      header: "Target",
      defaultFlex: 1,
      minWidth: 300,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("target", data),
      },
    },
    {
      name: "last_phase",
      header: "Phase",
      defaultFlex: 1,
      minWidth: 100,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("last_phase", data),
      },
    },
    {
      name: "volume",
      header: "Volume",
      defaultFlex: 1,
      minWidth: 50,
    },
  ];

  const filterValue = [
    { name: "drug_true_name", operator: "inlist", type: "select", value: "" },
    {
      name: "company_display_name",
      operator: "inlist",
      type: "select",
      value: "",
    },
    {
      name: "disease_display_name",
      operator: "inlist",
      type: "select",
      value: "",
    },
    { name: "target", operator: "inlist", type: "select", value: "" },
    {
      name: "last_phase",
      operator: "inlist",
      type: "select",
      value: "",
    },
  ];

  return (
    <div className="all-drug-trending-table">
      {load === false ? (
        <DataFilterTable
          dataSource={data}
          columns={columns}
          filterValue={filterValue}
          defaultFilterName={[]}
        />
      ) : (
        <div className="all-drug-trending-table--center-animation-loader">
          <LoadingAnimation type={"circular"} />
        </div>
      )}
    </div>
  );
}
