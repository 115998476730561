import React from "react";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { ControlPannel, DrugGraphPannel, DrugCardPannel } from "../../layout";
import { GlobalLrxAnimation } from "../../components";

export function DrugPage() {
  const { id } = useParams();
const load = useSelector((state) => state.config.globalMetaDataLoading);

  return (
    <div>
      {!load ? (
        <div className="dashboard">
          <div className="dashboard--pannel">
            <ControlPannel value={"DrugPage"} />
          </div>
          <div className="dashboard--side">
            {id !== "-1" ? (
              <div>
                <DrugGraphPannel drugId={id} />
              </div>
            ) : (
              <div>
                {" "}
                <DrugCardPannel/>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="dashboard--loading">
          <div className="dashboard--pannel">
            <ControlPannel value={"DrugPage"} />
          </div>
          <div className="global-loading">
          <GlobalLrxAnimation />
          </div>
        </div>
      )}
    </div>
  );
}
