

import { combineReducers} from 'redux';
import {getDataBQ} from './get_drug_bq';
import {ConfigGraph} from './config_graph';
import {getCompanyBQ} from './get_company_bq'
import  {authentication} from './authentication'
import {getDiseaseBQ} from './get_dieases_bq'
import { getCongressBQ } from './get_congress_bq';
import { getCompetitiveBQ } from './competitive_bq';
import { getDolBQ } from './dol_bq';

export const appReducer = combineReducers({
    drug : getDataBQ,
    config: ConfigGraph,
    diease : getDiseaseBQ,
    dol: getDolBQ,
    company: getCompanyBQ,
    congress: getCongressBQ,
    competitive: getCompetitiveBQ,
    auth: authentication,
});

export const rootReducer = (state, action) => {
    if (action.type === 'LOGOUT_SUCCESS') {
        localStorage.removeItem('token');
        return appReducer(undefined, action)
    }
    return appReducer(state, action);
};

export * from './get_drug_bq'