import React from "react";

import { ChartIcon } from "../../../../components";

export function LrxBtnAction(props) {
  const { logo, action_btn, text_btn} = props;
  return (
    <button onClick={action_btn}  className="lrx-btn-action">
    <p className="lrx-btn-action--paragraph">{text_btn}</p>
    <span className="lrx-btn-action--icon-wrapper">
        <ChartIcon logo={logo} />
    </span>
  </button>
  );
}
