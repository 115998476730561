import React from "react";
import { useSelector } from "react-redux";
import { ControlPannel, DolProfilePannel} from "../../layout";
import { GlobalLrxAnimation} from "../../components";



export function DolPageProfile() {
  const load = useSelector((state) => state.config.globalMetaDataLoading);
  return (
    <div>
      {!load ? (
        <div className="dashboard">
          <div className="dashboard--pannel">
            <ControlPannel value={"DashboardDOL"} />
          </div>
          <div className="dashboard--side">
            <DolProfilePannel/>
          </div>
        </div>
      ) : (
        <div className="dashboard--loading">
          <div className="dashboard--pannel">
            <ControlPannel value={"DashboardDOL"} />
          </div>
          <div className="global-loading">
          <GlobalLrxAnimation />
          </div>
        </div>
      )}
    </div>
  );
  }
  