import React from "react";
import { useSelector } from "react-redux";
import { LoadingAnimation, PositiveNegativeBarChart } from "../../components";

export function FollowersGraph() {
  const load = useSelector((state) => state.config.graphicsYearsDOLProfileLoading);
  const data = useSelector((state) => state.dol.graphics_years_dolprofile_info.follower);

  return (
    <div className="item-detail-dol-profile">
      {load === false ? (
        <div>
          {" "}
          <PositiveNegativeBarChart data={data ? data : []} />
        </div>
      ) : (
        <div className="loading-animation" style={{ height: "100%" }}>
          <LoadingAnimation type={"circular"} />
        </div>
      )}
    </div>
  );
}
