import * as XLSX from "xlsx";


export const createExcelFile = (data, name, excludedColumns) => {
    const filteredData = data.map(item => {
      const filteredItem = {};
      Object.entries(item).forEach(([key, value]) => {
        if (!excludedColumns.includes(key)) {
          filteredItem[key] = value;
        }
      });
      return filteredItem;
    });
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataBlob = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });
    const url = window.URL.createObjectURL(dataBlob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${name}.xlsx`;
    link.click();
    window.URL.revokeObjectURL(url);
  };