import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getTweetDrugData,
  setTopicsSelected,
  setTweetDataAnimation,
} from "../../Action";
import { Tweet, LoadingAnimation } from "../../components";

function filter_tweet_by_topics(tweets, topic) {
  if (topic.length !== 0) {
    return tweets.filter((e) =>
      e.tweet_topics.split(",").some((r) => topic.includes(r))
    );
  }
  return tweets;
}

function filter_tweet_by_disease(tweets, disease) {
  if (disease) {
    return tweets.filter((e) => e.id_disease === disease);
  }
  return tweets;
}

function filter_tweet_by_hcp(tweets, list_personas_selected) {
  const tweets_filtered = [];
  tweets.forEach((el) => {
    for (let element of list_personas_selected) {
      if (el.persona === element) {
        tweets_filtered.push(el);
      }
    }
  });
  return tweets_filtered;
}

export function TweetDisplay(props) {
  const { drugId, period } = props;
  const dispatch = useDispatch();
  const date = useSelector((state) => state.config.date);
  const topic = useSelector((state) => state.config.TopicsSet);
  const disease = useSelector((state) => state.config.diseaseWcloud).id_disease;
  const load = useSelector((state) => state.config.tweetLoading);
  const personas = useSelector((state) => state.dol.list_personas);
  const list_personas_selected = Object.keys(personas).filter(persona => personas[persona] === true).map(persona => persona.toUpperCase());

  const tweets = filter_tweet_by_disease(
    filter_tweet_by_topics(
      useSelector((state) => state.drug.tweet),
      topic
    ),
    disease
  );
  const ftweets = list_personas_selected.length === 0 ? tweets : filter_tweet_by_hcp(tweets, list_personas_selected);
  const uniqueIds = [];
  const filteredTweets = ftweets
    .filter((tweet) => {
      if (uniqueIds.includes(tweet.id_tweet)) {
        return false;
      } else {
        uniqueIds.push(tweet.id_tweet);
        return true;
      }
    })
    .slice(0, 10);
    
  useEffect(() => {
    dispatch(getTweetDrugData(drugId, date, period));
    dispatch(setTweetDataAnimation(true));
  }, [drugId, date, period, dispatch]);

  useEffect(() => {
    dispatch(setTopicsSelected(""));
  }, [drugId, dispatch]);
  
  return (
    <div style={{ position: "relative" }}>
      {" "}
      {load === false ? (
        <div className="tweet-display">
          <div className="tweet-display--column">
            {filteredTweets.map((tweet, index) => {
              if (index % 2 === 0) {
                return (
                  <div key={index} className="tweet-display--tweet">
                    <Tweet id={tweet.id_tweet} />
                  </div>
                );
              }
              return null;
            })}
          </div>
          <div className="tweet-display--column">
            {filteredTweets.map((tweet, index) => {
              if (index % 2 !== 0) {
                return (
                  <div key={index} className="tweet-display--tweet">
                    <Tweet id={tweet.id_tweet} />
                  </div>
                );
              }
              return null;
            })}
          </div>
        </div>
      ) : (
        <div className="loading-animation" style={{ height: "598px" }}>
          <LoadingAnimation type={"circular"} />
        </div>
      )}
    </div>
  );
}
