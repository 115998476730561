import React from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
  };

export function MuiModal(props) {
  const { open, onClose } = props;
  return (
    <Modal
    open={open}
    onClose={onClose}
  >
    <Box sx={style}>
        {props.children}
    </Box>
  </Modal>
  );
}
