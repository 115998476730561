import React from "react";
import { useSelector } from "react-redux";
import {Pagination} from '@mui/material';


export function MuiPagination(props){
  const {page, setPage} = props 
  const info = useSelector((state) => state.dol.info_ranking_dol);

  const handleChange = (event, value) => {
    setPage(value);
  };


  return (
    <Pagination count={Math.round(info.total_nb_profile /100) }  page={page}  onChange={handleChange}  variant="outlined" shape="rounded" color="secondary"/>
  );
}