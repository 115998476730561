
import React , {useEffect}  from "react";
import { useForm } from 'react-hook-form';
import { useDispatch,  useSelector  } from 'react-redux';
import { useNavigate  } from "react-router-dom";

import { useSnackbar } from 'react-simple-snackbar'

import {Button} from '@mui/material';
import {TextField} from '@mui/material'
import {FormControlLabel} from '@mui/material'
import {Checkbox} from '@mui/material'
import {Link} from '@mui/material'
import {Grid} from '@mui/material'


import {LoadingAnimation} from '../../components'
import {handleLogin, setAuthLoadingAnimation} from '../../Action'  

export function AuthLoginForm(props){
  const navigate= useNavigate ();
    const dispatch = useDispatch();
    const { register, handleSubmit } = useForm();
    const token = localStorage.getItem('token')
    const auth = useSelector(state => state.auth.authLoading);

    const options = {
      position: 'bottom-left',
      style: {
        backgroundColor: '#44D16A',
        fontSize: '15px',
        textAlign: 'center',
      },
    }

    const optionsFail = {
      position: 'bottom-left',
      style: {
        backgroundColor: '#D7393E',
        fontSize: '15px',
        textAlign: 'center',
      },
    }



    const form = {
      width: '100%', // Fix IE 11 issue.
    }
    const submit =  {
    }

    const [openSnackbar] = useSnackbar(options)
    const [openSnackbarFail] = useSnackbar(optionsFail)

    useEffect(() => {
     if (token !== null) {
      navigate("../home");
     }
    }, [token,navigate]);
    

    const onSubmit = (formValues) => {
        dispatch(handleLogin(formValues, openSnackbar, openSnackbarFail));
        dispatch(setAuthLoadingAnimation(true))
      }

    return (
        <form sx={form} onSubmit={handleSubmit(onSubmit)}>

        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="username"
          label="username"
          name="username"
          autoComplete="username"
          {...register("username", {
            required: "Required",
          })}
        /> 
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          {...register("password", {
            required: "Required",
          })}
        />
        <FormControlLabel
          control={<Checkbox value="remember" color="primary" />}
          label="Remember me"
        />
        {auth ?  <LoadingAnimation type={'circular'}/> : null}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          sx={submit}
        >
          Sign In
        </Button>
        <Grid container>
          <Grid item xs>
            <Link href="#" variant="body2">
              Forgot password?
            </Link>
          </Grid>
          <Grid item>
            <Link href="#" variant="body2">
              {"request access"}
            </Link>
          </Grid>
        </Grid>
      </form>
    );
}