import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { IconButton } from "@mui/material";

import { getParamValueTabs, is_departement_is_social } from "../../utils";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  CompetitiveVolumeGraph,
  CompetitiveVolumeTable,
  CompetitiveAllDrugTable,
  AllDrugWithDiseaseGraph,
  RSSDiseaseTable,
  MuiTabsNavigation,
  LrxChips,
  MuiCard,
  MuiCollapse,
  TreeMapCompetitiveDrug,
  CompetitiveVolumeGraphByTarget,
  //CompetitiveVolumeGraphByCompany,
  CompetitiveVolumeGraphByType,
  CompetitiveDetailsByTarget,
  CompetitiveDetailByTargetLegend,
  AllDrugCompanyDetailByPhaseLegend,
  CompetitiveDetailsByPhase,
  BtnCsvExport,
  PublicationTable,
  NCTDiseaseGraph,
  NCTDiseaseTable,
  CatalystCompetitiveTable,
  CatalystCompetitiveCalendar,
  AllNCTCompanyDetailByPhaseLegend,
  AllNCTTargetCompetitiveDetailByPhase,
  AllNCTTreatmentDetailByPhase,
  CompetitiveDetailsInactiveByPhase,
  CompetitiveDetailInactiveByTarget,
  DolRankingByDisease,
  SliderTwoChoice,
  ModalNeedSocialAccess,
  ChartIcon,
  LrxBtn
} from "../../components";

import { InfoPannel } from "../../layout";
import competInt from "../../../Asset/competInt.png";
import "./style/competitive_graph_pannel.css";

function SocialTabs() {
  const [is_percentage, set_is_percentage] = useState(true);
  const user = useSelector((state) => state.auth.auth_name);
  const date = useSelector((state) => state.config.date);
  const id_drug_competive = useSelector((state) => state.competitive.drugChooseForCompetitive);
  const [is_open, set_open] = useState(false);
  const handleChange = (open) => set_open(open);

  return (
    <div>
      {is_departement_is_social(user) ? (
        <div className="competitive-graph-pannel">
          <h2> Social Engagement</h2>{" "}
          <SliderTwoChoice choice={is_percentage} choice_text={['Percentage', 'Volume']} func_choice={set_is_percentage} />
          <MuiCard mui_class={"competitive-graph-pannel--graph-box"}>
            <div className="competitive_graph_pannel">
              <div className="competitive-graph-pannel__social-tabs__resume-graph--by-target">
                <h3> By target</h3>
                <CompetitiveVolumeGraphByTarget is_percentage={is_percentage} />
              </div>
              <div className="competitive-graph-pannel__social-tabs__resume-graph--by-company">
                <h3> By type</h3>
                {/*<CompetitiveVolumeGraphByCompany is_percentage={is_percentage} />*/}
                <CompetitiveVolumeGraphByType is_percentage={is_percentage} />
              </div>
            </div>
          </MuiCard>
          <h2> Therapeutics</h2>
          <MuiCard mui_class={"competitive-graph-pannel--graph-box"}>
            <h3>
              {" "}
              Social Engagement by drug <LrxChips />
            </h3>
            <div className="competitive-graph-pannel__social-tabs__therapeutics-graph">
              <CompetitiveVolumeTable
                my_className={
                  "competitive-graph-pannel__social-tabs__therapeutics-graph--drug-table"
                }
              />
              <CompetitiveVolumeGraph
                my_className={
                  "competitive-graph-pannel__social-tabs__therapeutics-graph--volume-graph"
                }
                is_percentage={is_percentage}
              />
            </div>
            <TreeMapCompetitiveDrug
              my_className={
                "competitive-graph-pannel__social-tabs__therapeutics-graph--volume-graph"
              }
            />
          </MuiCard>
          <MuiCard mui_class={"competitive-graph-pannel--graph-box"}>
            <h3> Disease Tweets Leaders {id_drug_competive.length === 0 ? null : new Date(date).toLocaleDateString("zh-Hans-CN")} </h3>
            <DolRankingByDisease />
          </MuiCard>
          <MuiCard mui_class={""}>
            <h3> Historical Social News</h3>
            <PublicationTable
              drugId={-1}
              if_history={true}
              which_btn={0}
              action_btn={[""]}
            />
          </MuiCard>
        </div>
      ) : (
        <div className="locked-screen-drug-pannel" style={{height: "60vh"}}>
          <ModalNeedSocialAccess more_info={is_open} onChange={handleChange}/>
          <img className="locked-screen-drug-pannel__img" src={competInt} alt="background-company"/>
          <div className="locked-screen-drug-pannel--icon-button">
            <div className="locked-screen-drug-pannel--icon-button__icon">  
              <ChartIcon logo={"LockedIcon"} />
            </div>
            <div className="need-premium-modal__validate">
              <LrxBtn
                action_btn={() => handleChange(true)}
                logo={"ActionSucess"}
                text_btn={"More Info"}
                my_className={"need-premium-modal__validate--btn"}
              /> 
            </div>    
          </div>
        </div>
      )}
    </div>
  );
}

function TreatmentTabs() {
  const [show_active, set_show] = useState(false);
  const [isRotated, setIsRotated] = useState(false);

  const handleChange = () => {
    set_show(!show_active);
    setIsRotated(!isRotated);
  };

  return (
    <div className="competitive-graph-pannel">
      {/* <div className="competitive-graph-pannel__header">
        <h2 className="competitive-graph-pannel__header--title"> </h2>
      </div> */}
      <div className="competitive-graph-container">
        <MuiCard mui_class={"competitive-graph-pannel-graph"}>
          <div className="competitive-graph-pannel__treatement-tabs">
            <div className="competitive-graph-pannel__treatement-tabs__details-graph">
              <div className="competitive-graph-pannel__treatement-tabs__details-graph__target-graph">
                <h3 className="competitive-graph-pannel__treatement-tabs__details-graph__target-graph--title">
                  {" "}
                  By phases
                </h3>
                <div className="competitive-graph-pannel__treatement-tabs__details-graph__phase-graph--chart">
                  <CompetitiveDetailsByPhase />
                </div>
                <AllDrugCompanyDetailByPhaseLegend is_nct={false} />
              </div>
              <div className="competitive-graph-pannel__treatement-tabs__details-graph__target-graph">
                <h3 className="competitive-graph-pannel__treatement-tabs__details-graph__target-graph--title">
                  {" "}
                  By targets
                </h3>
                <div className="competitive-graph-pannel__treatement-tabs__details-graph__target-graph--chart">
                  <CompetitiveDetailsByTarget />
                </div>
                <CompetitiveDetailByTargetLegend is_active={show_active} />
              </div>
            </div>
          </div>
        </MuiCard>
      </div>
      <h2>
        Therapeutics Details <LrxChips />
      </h2>
      <MuiCard mui_class={"competitive-graph-pannel--graph-box"}>
        <div className="competitive-graph-pannel__treatement-tabs__header-graph">
          <h2 className="competitive-graph-pannel__treatement-tabs__header-graph--title">
            {" "}
            ACTIVE THERAPEUTICS
          </h2>
          <BtnCsvExport
            my_className={
              "competitive-graph-pannel__treatement-tabs__header-graph--action-btn"
            }
            data_to_export={{
              location: "competitive",
              name: "treatment_competitive",
            }}
          />
        </div>
        <AllDrugWithDiseaseGraph is_active={0} />
        <div className="competitive-graph-pannel__treatement-tabs__inactive-header">
          <h2>INACTIVE THERAPEUTICS</h2>
          <div className="controlPannel-box-expandIcon">
            <IconButton
              className={`controlPannel-box-expandIcon ${
                isRotated ? "rotated" : ""
              }`}
              onClick={() => handleChange()}
            >
              <ExpandMoreIcon />
            </IconButton>
          </div>
        </div>
        <MuiCollapse is_open={show_active}>
          <div className="competitive-graph-pannel__treatement-tabs__details-graph">
            <div className="competitive-graph-pannel__treatement-tabs__details-graph__target-graph">
              <div className="competitive-graph-pannel__treatement-tabs__details-graph__phase-graph--chart">
                <CompetitiveDetailsInactiveByPhase />
              </div>
              <AllDrugCompanyDetailByPhaseLegend is_nct={false} />
            </div>
            <div className="competitive-graph-pannel__treatement-tabs__details-graph__target-graph">
              <div className="competitive-graph-pannel__treatement-tabs__details-graph__target-graph--chart">
                <CompetitiveDetailInactiveByTarget />
              </div>
            </div>
          </div>
          <AllDrugWithDiseaseGraph mui_class={"competitive-graph-pannel__treatement-tabs__inactive-container--time-line-chart"} is_active={1} />
        </MuiCollapse>
      </MuiCard>
      <h2 className="company-graph-pannel__treatement-tabs__header-graph--title">
        Therapeutics Details <LrxChips />
      </h2>
      <MuiCard mui_class={"competitive-graph-pannel--graph-box"}>
        <div className="competitive-graph-pannel__treatement-tabs__header-graph">
          <h3 className="competitive-graph-pannel__treatement-tabs__header-graph--title">
            {" "}
            THERAPEUTICS
          </h3>
          <BtnCsvExport
            my_className={
              "competitive-graph-pannel__treatement-tabs__header-graph--action-btn"
            }
            data_to_export={{ location: "drug", name: "all_drug" }}
          />
        </div>
        <CompetitiveAllDrugTable />
      </MuiCard>
    </div>
  );
}

function NewsTabs() {
  return (
    <div className="drug-graph-pannel">
      <h2>Upcoming Events and Press Releases</h2>
      <div className="company-graph-pannel__news-tabs">
        <MuiCard mui_class={""}>
          <div className="company-graph-pannel__treatement-tabs__header-graph">
            <h3 className="company-graph-pannel__treatement-tabs__header-graph--title">
              Catalysts <LrxChips />
            </h3>
            <BtnCsvExport
              my_className={""}
              data_to_export={{ location: "company", name: "catalyst_company" }}
            />
          </div>
          <CatalystCompetitiveCalendar />
          <div className="competitive-graph-pannel__news-tab">
            <CatalystCompetitiveTable />
            <div className="competitive-graph-pannel__news-tab__RSS-table">
              <div className="competitive-graph-pannel__news-tab__RSS-table__header-graph">
                <h3>
                  Press Releases
                </h3>
                <BtnCsvExport
                  my_className={""}
                  data_to_export={{
                    location: "competitive",
                    name: "RSS_competitive",
                  }}
                />
              </div>
              <RSSDiseaseTable />
            </div>
          </div>
        </MuiCard>
      </div>
    </div>
  );
}

function ClinicalTabs() {
  return (
    <div className="drug-graph-pannel">
      {/* <div className="company-graph-pannel__header">
        <h2 className="company-graph-pannel__header--title"> </h2>
      </div> */}
      <div className="competitive-graph-container">
        <MuiCard mui_class={"competitive-graph-pannel-graph"}>
          <div className="company-graph-pannel__treatement-tabs__details-graph">
            <div className="company-graph-pannel__treatement-tabs__details-graph__phase-graph">
              <h3 className="company-graph-pannel__treatement-tabs__details-graph__phase-graph--title">
                {" "}
                Historical by phases (starting date)
              </h3>
              <div>
                <div>
                  <AllNCTTreatmentDetailByPhase />
                </div>
              </div>
            </div>
            <div className="company-graph-pannel__treatement-tabs__details-graph__phase-graph">
              <h3 className="company-graph-pannel__treatement-tabs__details-graph__phase-graph--title">
                {" "}
                Current by target
              </h3>{" "}
              <div>
                <AllNCTTargetCompetitiveDetailByPhase />
              </div>
            </div>
          </div>
          <AllDrugCompanyDetailByPhaseLegend is_nct={true} />
        </MuiCard>
      </div>
      <MuiCard mui_class={""}>
        <div className="competitive-graph-pannel__treatement-tabs__header-graph">
          <h2 className="competitive-graph-pannel__treatement-tabs__header-graph--title">
            {" "}
            Clinical Trials Details <LrxChips />
          </h2>
          <BtnCsvExport
            my_className={
              "competitive-graph-pannel__treatement-tabs__header-graph--action-btn"
            }
            data_to_export={{
              location: "drug",
              name: "nct_competitive",
            }}
          />
        </div>
        <AllNCTCompanyDetailByPhaseLegend />
        <NCTDiseaseGraph />
        <NCTDiseaseTable />
      </MuiCard>
    </div>
  );
}

function TabPanel(props) {
  const { value } = props;
  if (value === "Social") return <SocialTabs />;
  if (value === "Treatment") return <TreatmentTabs />;
  if (value === "News") return <NewsTabs />;
  if (value === "Clinical trial") return <ClinicalTabs />;
  return null;
}

export function CompetitiveGraphPannel() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const type_enum = ["Social", "Treatment", "Clinical trial", "News"];
  const monParametreValue = getParamValueTabs("tabs", params, type_enum.length);
  const [type_of_content, setValue] = useState(monParametreValue);
  const user = useSelector((state) => state.auth.auth_name);

  return (
    <div className="competitive-graph-pannel--page">
      <InfoPannel 
        drugId={0} 
        value={2} 
        type_of_content={type_of_content} 
      />
      <div className="competitive-graph-pannel--page--info">
        <div className="competitive-graph-pannel--page--info__tab-navigation-container">
          <MuiTabsNavigation
            type_enum={type_enum}
            type_of_content={type_of_content}
            setValue={setValue}
            is_premium_user={is_departement_is_social(user)}
          />
        </div>
        <TabPanel value={type_enum[type_of_content]} />
      </div>
    </div>
  );
}
