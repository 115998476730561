import React, {useEffect}  from "react";
import { useDispatch , useSelector} from 'react-redux'
import {getCongressbyDrug, setdrugAllCongressLoadingAnimation} from '../../Action';
import {HorizontalBarChart, LoadingAnimation } from '../../components'

import './style/congress_card.css';

export function DrugCongressPannel(props) {
  const {name, drugId} = props
  const dispatch = useDispatch();
  const nameItem = useSelector ( state =>  state.congress.conferenceDrug).sort((a, b) => b.year - a.year);
  const load = useSelector((state) => state.config.drugAllCongressLoading);
  useEffect(() => {
      dispatch(getCongressbyDrug(name.id_conference, drugId));
      dispatch(setdrugAllCongressLoadingAnimation(true));
  }, [dispatch, name, drugId]);

  return (
      <div style={{ height: 500, width: "100%", overflow: 'auto' }}>
        {load === false ? (
          nameItem.map((el, i)=>
            <div key={i} > <HorizontalBarChart data={el}/></div> 
          )
        
        /*nameItem.map(el=>
          <div className='congress_card-content' onClick={()=>selectThisCongress(el)} >
        <Grow in={checked}
        style={{ transformOrigin: "0 0 0" }}
        {...(checked ? { timeout: 1000 } : {})}>
         <Card className='congress_card-mui-card' >
            <CardContent >
                <div className="congress_card-title-congress"> {el.conference_name}{' '}{el.year}</div>
                <div> <span className="congress_card-title-spec">congress's share of voice:{' '} </span>{((el.volume / el.max_volume) * 100).toFixed(2) + ' %'}</div>
                <div> <span className="congress_card-title-spec">sentiment:{' '} </span>{el.sentiement} </div>
            </CardContent>
        </Card>
      </Grow>
        </div>)*/
        
        ) : (
          <div className="loading-animation" style={{height: "100%"}}>
            <LoadingAnimation type={'circular'}/>
          </div>
        )}
      </div>
    );
}