import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import { DataFilterTable, LoadingAnimation } from "../../components";

import { get_filter_key } from "../../utils";
import { getDolRankingByDrugData, setdataDOLRankingByDrugTabAnimation } from "../../Action";
import numeral from "numeral";

export function DolRankingByDrug(props) {
  const {drugId, period} = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const date = useSelector((state) => state.config.date);
  const load = useSelector((state) => state.config.dataDOLRankingByDrugTabLoading);
  const data = useSelector((state) => state.dol.dol_ranking_by_drug_tab);
  const personas = useSelector((state) => state.dol.list_personas);
  
  function list_name_personas(object) {
    const personasSelected = Object.keys(object).filter(persona => object[persona] === true).map(persona => persona.toUpperCase());
    return personasSelected;
  }

  useEffect(() => {
    dispatch(getDolRankingByDrugData(drugId, date, period, list_name_personas(personas)));
    dispatch(setdataDOLRankingByDrugTabAnimation(true));

  }, [dispatch, drugId, date, period, personas]);

  const go_to = (name, id) => {
    if (name === "dol_profile") {
      navigate(`/dolprofile/${id}`);
    }
  };
  
  const columns = [
    {
      name: "user_name",
      header: "Author ",
      defaultFlex: 2,
      minWidth: 140,
      render: ({ value, data }) => (
        <span
          className="title-link"
          onClick={() => go_to("dol_profile", data.id_dolprofile)}
        >
          {value}
        </span>
      ),
    },
    {
      name: "profession_group_display_name",
      header: "Profession group",
      minWidth: 140,
      defaultFlex: 3,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("profession_group_display_name", data),
      },
    },
    {
      name: "persona",
      header: "Persona",
      minWidth: 120,
      defaultFlex: 1,
    },
    {
      name: "user_followers_count",
      header: "Followers",
      type: "number",
      minWidth: 110,
      defaultFlex: 1,
      onRender: (cellProps, { data }) => {
        cellProps.style.display = "flex";
        cellProps.style.textAlign = 'center';
      },
      render: ({ value }) => <span> {numeral(value).format("0,0")} </span>
    },
    {
      name: "dol_tweet",
      header: "Tweets",
      type:"number",
      minWidth: 100,
      defaultFlex: 1,
      onRender: (cellProps, { data }) => {
        cellProps.style.display = "flex";
        cellProps.style.textAlign = 'center';
      },
    },
    {
      name: "tweet_score",
      header: "Retweets",
      type:"number",
      minWidth: 100,
      defaultFlex: 1, 
      onRender: (cellProps, { data }) => {
        cellProps.style.display = "flex";
        cellProps.style.textAlign = 'center';
      },
    },
  ];

  const filterValue = [
    { name: "user_name", operator: "contains", type: "string", value: "" },
  ];
  
  return (
    <div className="dol-ranking-table-drug">
      {load === false ? (
        <DataFilterTable
          dataSource={data}
          columns={columns}
          filterValue={filterValue}
          enableFiltering={true}
          enablePagination={true}
        />
      ) : (
        <div className="dol-ranking-table--loading-animation">
          <LoadingAnimation type={"circular"} />
        </div>
      )}
    </div>
  );
}
