import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";

import { DataFilterTable, LoadingAnimation, ChartIcon } from "../../components";

import { get_filter_key } from "../../utils";

import { getTTCompanyData, setDataTTLoadingAnimation } from "../../Action";

import numeral from "numeral";

export function AllCompanyTable(props) {
  const { which_btn, action_btn } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const date = useSelector((state) => state.config.date);
  const d = useSelector((state) => state.company.data);
  const load = useSelector((state) => state.config.dataTTLoading);
  const depth = useSelector((state) => state.diease.statusCompanyDisplay);

  const icons = ["DecreaseIcon", "IncreaseIcon", "LittleIncreaseIcon"];
  const iconsValueColor = ["#690505", "#018539", "#045225"];
  const data = d.filter(
    (e) => e.market_cap === depth.find((element) => element === e.market_cap)
  );

  useEffect(() => {
    dispatch(getTTCompanyData(date, action_btn[which_btn]));
    dispatch(setDataTTLoadingAnimation(true));
  }, [date, action_btn, which_btn, dispatch]);

  const go_to = (name, id) => {
    if (name === "company") {
      navigate(`/company/${id}`);
    }
  };

  const columns = [
    {
      name: "company_display_name",
      header: "Company",
      defaultFlex: 1,
      minWidth: 225,
      render: ({ value, data }) => (
        <span
          className="title-link"
          onClick={() => go_to("company", data.id_company)}
        >
          {value}
        </span>
      ),
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("company_display_name", data),
      },
    },
    {
      name: "market_cap",
      header: "Market cap",
      defaultFlex: 1,
      minWidth: 130,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("market_cap", data),
      },
    },
    {
      name: "volume",
      header: "Volume",
      defaultFlex: 1,
      minWidth: 60,
      maxWidth: 100,
      type: "number",
      onRender: (cellProps, { data }) => {
        cellProps.style.display = "flex";
        cellProps.style.textAlign = "center";
      },
      render: ({ value }) => <span> {numeral(value).format("0,0")} </span>
    },
    {
      name: "sentiementEvolution",
      header: "Sentiment Change",
      defaultFlex: 1,
      minWidth: 80,
      maxWidth: 170,
      type: "number",
      render: ({ value }) => {
        return (
          <div className="all-drug-trending-table__trendScore-and-SentimentChange-cells">
            <ChartIcon
              logo={
                value > 75
                  ? icons[1]
                  : value <= 75 && value >= 50
                  ? icons[2]
                  : icons[0]
              }
            />
            <p
              className="all-drug-trending-table__trendScore-and-SentimentChange-cells--value"
              style={{
                color:
                  value > 75
                    ? iconsValueColor[1]
                    : value <= 75 && value >= 50
                    ? iconsValueColor[2]
                    : iconsValueColor[0],
              }}
            >
              {value}
            </p>
          </div>
        );
      },
    },
    {
      name: "volumeEvolution",
      header: "Trend score",
      defaultFlex: 1,
      minWidth: 80,
      maxWidth: 130,
      type: "number",
      render: ({ value }) => {
        return (
          <div className="all-drug-trending-table__trendScore-and-SentimentChange-cells">
            <ChartIcon
              logo={
                value > 75
                  ? icons[1]
                  : value <= 75 && value >= 50
                  ? icons[2]
                  : icons[0]
              }
            />
            <p
              className="all-drug-trending-table__trendScore-and-SentimentChange-cells--value"
              style={{
                color:
                  value > 75
                    ? iconsValueColor[1]
                    : value <= 75 && value >= 50
                    ? iconsValueColor[2]
                    : iconsValueColor[0],
              }}
            >
              {value}
            </p>
          </div>
        );
      },
    },
  ];

  const filterValue = [
    {
      name: "company_display_name",
      operator: "inlist",
      type: "select",
      value: "",
    },
    { name: "market_cap", operator: "inlist", type: "select", value: "" },
  ];

  return (
    <div className="all-company-trending-ticker-table">
      {load === false ? (
        <DataFilterTable
          dataSource={data}
          columns={columns}
          filterValue={filterValue}
          defaultFilterName={[]}
        />
      ) : (
        <div className="loading-animation" style={{ height: "100%" }}>
          <LoadingAnimation type={"circular"} />
        </div>
      )}{" "}
    </div>
  );
}
