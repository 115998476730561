import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LrxMenuInput } from "../../../components";
import { set_filter_profession_group, remove_profession_group } from "../../../Action";

import { Tooltip } from "@mui/material";
import { IconButton } from "@mui/material";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

export function BtnProfessionGroupDisplay() {
  const professionGroupName = useSelector((state) => state.diease.profession_group_name)
  const myProfessionGroup = useSelector((state) => state.diease.profession_group_user)
  const [searchValue, setSearchValue] = useState("");
  const dispatch = useDispatch();

  const professionGroupListFiltered = professionGroupName.filter((user) =>
    user.profession_group_display_name.toLowerCase().includes(searchValue.toLowerCase())
  );

  const handleChange = (event) => {
    const itemName = event.target.name;
    const selectedItem = professionGroupName.find(
      (item) => item.profession_group_display_name === itemName
    );

    if (myProfessionGroup.includes(selectedItem)) {
      dispatch(remove_profession_group(selectedItem.id_profession_group));
    } else {
      dispatch(set_filter_profession_group(selectedItem));
    }
  };

  const clearFilter = () => {
    myProfessionGroup.forEach((item) => {
      dispatch(remove_profession_group(item.id_profession_group));
    });
  };

  return (
    <div className="control-pannel-search-entity">
      <LrxMenuInput
        action_input={setSearchValue}
        name={"Search for a profession group"}
      />
      <h3 className="control-pannel-search-entity--nb-select-filter">
        {" "}
        {professionGroupListFiltered.length === 0
          ? "Profession group not found !"
          : professionGroupListFiltered.length + " profession group(s) available"}{" "}
      </h3>
      <h3 className="control-pannel-search-entity--nb-select-filter">
        {" "}
        {myProfessionGroup.length === 0
          ? null
          : myProfessionGroup.length + " profession group(s) selected"}{" "}
      </h3>
      {myProfessionGroup.length === 0 ? null : (
        <Tooltip title="Delete">
          <IconButton onClick={clearFilter}>
            <RestartAltIcon />
          </IconButton>
        </Tooltip>
      )}
      <div className="control-pannel-search-entity__list-object-filter">
        <div class="control-pannel-search-entity__list-object-filter--form">
          <form>
            {myProfessionGroup.map((item, index) => (
              <div key={index}>
                <label>
                  <input
                    type="checkbox"
                    onChange={handleChange}
                    name={item.profession_group_display_name}
                    checked={myProfessionGroup.includes(item)}
                  ></input>
                  <span>{item.profession_group_display_name}</span>
                </label>
              </div>
            ))}
          </form>
        </div>
        <div class="control-pannel-search-entity__list-object-filter--form">
          <form>
            {professionGroupListFiltered.map((item, index) =>
              myProfessionGroup.includes(item) ? null : (
                <div key={index}>
                  <label>
                    <input
                      type="checkbox"
                      onChange={handleChange}
                      name={item.profession_group_display_name}
                      checked={myProfessionGroup.includes(item)}
                    ></input>
                    <span>{item.profession_group_display_name}</span>
                  </label>
                </div>
              )
            )}
          </form>
        </div>
      </div>
    </div>
  );
}
