import React from "react";
import { TwitterTweetEmbed } from "react-twitter-embed";

export function Tweet(props) {
  const { id } = props;
  return (
      <TwitterTweetEmbed
        key={id}
        onLoad={function noRefCheck() {}}
        options={{
          maxWidth: 800,
          width: '100%',
        }}
        tweetId={id}
      />

  );
}

/*  <div className="tweet-display--iframe">
      <iframe
      title={`display_for_tweet_${id}`}
        border="0"
        frameborder="0"
        height={'650'}
        width={'100%'}
        src={`https://twitframe.com/show?url=https://twitter.com/anyuser/status/${id}`}
      ></iframe>
  </div>*/
