import React from "react";

//import { MuiFab } from "../../components";

export function ActionIconDrug(props) {
  return (
    <div className="action-icon-drug">
     {/* <MuiFab logo={"hearthIcon"} my_className={'action-icon-drug--btn-fab'} />
      <MuiFab logo={"saveIcon"} my_className={"action-icon-drug--btn-fab"} />*/}
    </div>
  );
}
