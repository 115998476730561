import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  getAllDrug,
  setMyMOA,
  getDynamicDrug,
  setDrugDynamicSearchLoadingAnimation,
} from "../../Action";

import {
  filterDataDrug
} from "../../utils";


import { LrxMenuInput } from "../../components";

export function SelectAllDrug(props) {
  const { name, is_filter } = props;
  const alldrug = useSelector((state) => state.drug.nameDrug);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const depth = useSelector((state) => state.diease.statusDrugDisplay);
  const my_disease = useSelector((state) => state.diease.diseaseDisplay);
  const my_theraFranchise = useSelector(
    (state) => state.diease.theraFranchiseDisplay
  );
  const my_type = useSelector((state) => state.diease.drugTypeDisplay);
  const my_target = useSelector((state) => state.diease.drugTargetDisplay);
  const load = useSelector((state) => state.config.dynamicSearchDrugLoading);
  const MOA = useSelector((state) => state.diease.MOADisplay);

  const nameItem = filterDataDrug(
    alldrug,
    depth,
    my_disease,
    my_type,
    [],
    my_theraFranchise,
    my_target
  );
  const dynamicItem = useSelector((state) => state.drug.nameDynamicDrug);
  const [drugSelected, setDrugSelected] = useState("");
  const [is_search, set_search_value] = useState("");
  const ListFiltered = load ? nameItem : dynamicItem;
  const listDrug100first = is_search.length === 0 ? nameItem : ListFiltered;
  
  useEffect(() => {
    if (nameItem && nameItem.length === 0) {
      dispatch(getAllDrug());
    }
  }, [dispatch, nameItem]);

  const go_to = (newValue, is_filter) => {
    if (newValue) {
      if (is_filter === true) {
        if (newValue.moa !== "No Name")
          dispatch(
            setMyMOA({
              index: 1,
              id_moa: newValue.id_moa,
              moa: newValue.moa,
              id_disease: 1,
              disease_display_name: 2,
            })
          );
      } else {
        navigate(`/drug/${newValue.id_drug}`);
      }
      if (drugSelected !== newValue.drug_display_name) {
        setDrugSelected(newValue.drug_display_name);
      }
    }
  };

  const dynamicSearch = (value) => {
    set_search_value(value);
    dispatch(
      getDynamicDrug(value.toLowerCase(), my_theraFranchise, my_disease, MOA)
    );
    dispatch(setDrugDynamicSearchLoadingAnimation(true));
  };

  return (
    <div className="control-pannel-search-entity">
      <LrxMenuInput action_input={dynamicSearch} name={name} />
        <h3 className="control-pannel-search-entity--nb-select-filter">
          {is_search.length === 0 ? `${listDrug100first.length} drugs available`: `${listDrug100first.length} drug names available`}
        </h3>
      <div className="control-pannel-search-entity__list-object-filter">
        <div class="control-pannel-search-entity__list-object-filter--form">
          <form>
            {listDrug100first.slice(0, 50).map((item, index) => (
              <div key={index}>
                <label>
                  <input
                    type="checkbox"
                    onChange={() => {
                      go_to(item, is_filter);
                    }}
                    name={item.drug_display_name}
                    checked={drugSelected.includes(item.drug_display_name)}
                  ></input>
                  <span>{item.drug_display_name}</span>
                </label>
              </div>
            ))}
          </form>
        </div>
      </div>
    </div>
  );
}
