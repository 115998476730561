import {
    LoginPage,
    HomePage,
    TDashboard,
    DrugPage,
    CompanyPage,
    CongressPage,
    CompetitivePage,
    DolPage,
    DolPageProfile
} from '../screens';

export const routesPath = {
    home : '/home',
    trending : '/trending',
    company : '/company/:id',
    drug : '/drug/:id',
    congress : '/congress',
    competitive: '/competitive',
    dolRanking : '/dol/ranking',
    dol : '/dolprofile/:id',
    login : '/',
}

export const routes = [
    {
        path: routesPath.home,
        Component: <HomePage/>,
    },
    {
        path: routesPath.trending,
        Component: <TDashboard/>,
    },
    {
        path: routesPath.drug,
        Component : <DrugPage/>,
    },
    {
        path: routesPath.company,
        Component : <CompanyPage/>,
    },
    {
        path: routesPath.congress,
        Component : <CongressPage/>,
    },
    {
        path: routesPath.competitive,
        Component : <CompetitivePage/>,
    },
    {
        path: routesPath.dolRanking,
        Component : <DolPage/>,
    },
    {
        path: routesPath.dol,
        Component : <DolPageProfile/>,
    },
    {
        path: routesPath.login,
        Component : <LoginPage/>,
    },
];