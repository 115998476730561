import React from "react";
import { useSelector } from "react-redux";

export function RankBox() {
  const info = useSelector((state) => state.drug.drugInfo);
  const nb = info.drugInfo.rank !== "unknown" ? info.drugInfo.rank : "#";

  return (
    <div>
      <div className="info-pannel-content-rank">
        <div className="info-pannel-rank-number"> {nb}</div>
        <div> Rank </div>
      </div>
    </div>
  );
}
