import React from "react";

import "./style//card-animation.css";

export function CardAnimation() {
  return (
    <div class="honeycomb">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
  );
}