import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { filter_by_years } from "../../utils";
import { getAllCongress, setCongressName } from "../../Action";
import { LrxMenuInput } from "../../components";

export function SelectCongressDisplay() {
  const date = useSelector((state) => state.congress.congressYears);
  const nameItem = useSelector((state) => state.congress.congressAllName);
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const [congressSelected, setCongressSelected] = useState("");

  useEffect(() => {
    if (nameItem && nameItem.length === 0) {
      dispatch(getAllCongress());
    }
  }, [dispatch, nameItem]);

  const go_to = (event) => {
    const itemName = event.target.name;
    const selectedItem = my_option.find(
      (item) => item.conference_name === itemName
    );
    if (congressSelected !== itemName) {
      setCongressSelected(itemName);
      dispatch(setCongressName(selectedItem));
    }
  };

  const my_option = filter_by_years(nameItem, new Date(date).getFullYear());

  const congressListFiltered = my_option.filter((user) =>
    user.conference_name.toLowerCase().includes(searchValue.toLowerCase())
  );

  return (
    <div className="control-pannel-search-entity">
      <LrxMenuInput
        action_input={setSearchValue}
        name={"Search for a congress"}
      />
      <h3 className="control-pannel-search-entity--nb-select-filter">
        {congressListFiltered.length === 0
          ? "Congress not found !"
          : congressListFiltered.length + " congresses available"}
      </h3>
      <div className="control-pannel-search-entity__list-object-filter">
        <div class="control-pannel-search-entity__list-object-filter--form">
          <form>
            {congressListFiltered.map((item, index) => (
              <div key={index}>
                <label>
                  <input
                    type="radio"
                    name={item.conference_name}
                    onChange={go_to}
                    checked={congressSelected.includes(item.conference_name)}
                  />
                  <span>{item.conference_name}</span>
                </label>
              </div>
            ))}
          </form>
        </div>
      </div>
    </div>
  );
}
