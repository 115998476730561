import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  DrugCard,
  LimitDisplayByPage,
  LoadingAnimation,
  LrxMenuInput,
  LrxChips,
} from "../../components";
import { filterDataDrug } from "../../utils";
import { getAllDrug, getDynamicDrug, setDrugDynamicSearchLoadingAnimation } from "../../Action";

export function DrugCardPannel() {
  const depth = useSelector((state) => state.diease.statusDrugDisplay);
  const type = useSelector((state) => state.diease.drugTypeDisplay);
  const disease = useSelector((state) => state.diease.diseaseDisplay);
  const dynamicItem = useSelector((state) => state.drug.nameDynamicDrug);
  const theraFranchise = useSelector(
    (state) => state.diease.theraFranchiseDisplay
  );
  const targetDisplay = useSelector((state) => state.diease.drugTargetDisplay);
  const alldrug = useSelector((state) => state.drug.nameDrug);
  const load = useSelector((state) => state.config.dynamicSearchDrugLoading);
  
  const nameItem = filterDataDrug(
    alldrug,
    depth,
    disease,
    type,
    [],
    theraFranchise,
    targetDisplay
  );
  const dispatch = useDispatch();
  const [page, setPage] = useState(98);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    if (alldrug && alldrug.length === 0){
      dispatch(getAllDrug());
    }
  }, [dispatch, alldrug]);

  const uniqueDynamicItem = dynamicItem.filter((drug, index, array) => 
    index === array.findIndex((object) => 
      object.id_drug === drug.id_drug
    )
  );

  const filteredDynamicItem = nameItem.filter((object1) =>
    uniqueDynamicItem.some((object2) =>
      object1.id_drug === object2.id_drug
    )
  )

  const drugListFiltered = searchValue.length === 0 ? nameItem : filteredDynamicItem;

  const addCard = (nb) => {
    setPage(nb);
  };

  useEffect(() => {
    addCard(100);
    dispatch(
      getDynamicDrug(searchValue.toLowerCase(), [], [], [])
    );
    dispatch(setDrugDynamicSearchLoadingAnimation(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [searchValue])

   return(
    <div>
      <div className="drug-card-pannel--header">
        <LrxMenuInput
          action_input={setSearchValue}
          name={"Search for a drug"}
          sx={{width:"550px"}}
        />
        <div className="drug-card-pannel--header__chips-container">
          <LrxChips />
        </div>
        <h3 style={{display: load === false ? null : "None"}}>
          {" "}
          {drugListFiltered.length === 0
            ? "Drug not found !"
            : drugListFiltered.length + " drug(s) available"}{" "}
        </h3>
      </div>
      {load === false ? (
        <div>
          <div className="drug-card-pannel">
            {drugListFiltered.slice(0, page).map((el, i) => (
              <DrugCard key={i} content={el} />
            ))}
          </div>
            {drugListFiltered.length <= page ?
              null 
            :
              <LimitDisplayByPage setPage={setPage} page={page} /> 
            }
        </div>
      ) : (
        <div className="loading-animation" style={{height: "60vh", width: "100%"}}>
          <LoadingAnimation type={"linear"} />
        </div>
      )}
    </div>
  );
}
