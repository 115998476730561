import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { getGraphicsYearsDolProfileData, setdataDOLProfileGraphicsAnimation } from "../../Action";
import {
  MuiCard,
  ActivityGraph,
  FollowersGraph,
  EngagementGraph,
  LrxDolYearChips
} from "../../components";

export function DolGraphics(props) {
  const {dolProfileId} = props;
  
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getGraphicsYearsDolProfileData(dolProfileId));
    dispatch(setdataDOLProfileGraphicsAnimation(true));
  }, [dolProfileId, dispatch]);

  return(
    <div className="dol-profile-pannel__details-container__graphs-and-wordclouds-container">
      <div className="dol-profile-pannel__details-container__graphs-and-wordclouds-container__item-display">
        <h2 className="dol-profile-pannel__details-container__graphs-and-wordclouds-container__item-display--title">
          Followers growth <LrxDolYearChips />
        </h2>
        <MuiCard mui_class={""}>
          <FollowersGraph />
        </MuiCard>
      </div>
      <div className="dol-profile-pannel__details-container__graphs-and-wordclouds-container__item-display">
        <h2 className="dol-profile-pannel__details-container__graphs-and-wordclouds-container__item-display--title">
          Activity <LrxDolYearChips />
        </h2>
        <MuiCard mui_class={""}>
          <ActivityGraph />
        </MuiCard>
      </div>
      <div className="dol-profile-pannel__details-container__graphs-and-wordclouds-container__item-display">
        <h2 className="dol-profile-pannel__details-container__graphs-and-wordclouds-container__item-display--title">
          Engagement <LrxDolYearChips />
        </h2>
        <MuiCard mui_class={""}>
          <EngagementGraph />
        </MuiCard>
      </div>
    </div>
  );
}