import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";

import CookieConsent from "react-cookie-consent";

import { Header } from "./components";
import { routes } from "./config";

import "./App.css";
import "../styles/sass/main.scss";

import icon from "../Asset/favicon.ico";

export default function App() {
  window.addEventListener('error', event => {
    if (event.message.includes('ResizeObserver loop limit exceeded')) {
        event.preventDefault();
        event.stopPropagation();
    }
});
  
  useEffect(() => {
    const favicon = document.getElementById("favicon");
    favicon.setAttribute("href", icon);
  }, []);

  return (
    <div className="App">
      <CookieConsent>
        This website uses cookies to enhance the user experience.
      </CookieConsent>
      <Header />
      <Routes>
        {routes.map((route, i) => (
          <Route key={i} path={route.path} element={route.Component} />
        ))}
      </Routes>
    </div>
  );
}
