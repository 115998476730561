import React from "react";
import ReactWordcloud from "react-wordcloud";
import { select } from "d3-selection";
import { useDispatch} from "react-redux";
import { setDiseaseWCloudSelected } from "../../../../Action";

export function WordCloudChart(props) {
  const { words } = props;
  const dispatch = useDispatch();

  const options = {
    enableTooltip: true,
    deterministic: false,
    fontFamily: "impact",
    fontSizes: [14, 60],
    fontStyle: "normal",
    fontWeight: "normal",
    padding: 1,
    rotations: 1,
    rotationAngles: [0, 90],
    scale: "sqrt",
    spiral: "archimedean",
    transitionDuration: 500,
  };

  function getCallback(callback) {
    return function (word, event) {
      const isActive = callback !== "onWordMouseOut";
      const element = event.target;
      const text = select(element);
      text
        .on("click", () => {
          if (isActive) {
            dispatch(setDiseaseWCloudSelected({disease_display_name: word.text, id_disease: word.id_disease,}));
          }
        })
        .transition()
        .attr("background", "white")
        .attr("font-weight", isActive ? "bold" : "normal");
      //.attr("text-decoration", isActive ? "underline" : "none");
    };
  }
  const callbacks = {
    getWordColor: (word) => word.color,
    //getWordTooltip: (word) =>
    // `The word "${word.text}" appears ${word.value} times.`,
    onWordClick: getCallback("onWordClick"),
    onWordMouseOut: getCallback("onWordMouseOut"),
    onWordMouseOver: getCallback("onWordMouseOver"),
  };

  return (
    <ReactWordcloud options={options} callbacks={callbacks} words={words} />
  );
}
