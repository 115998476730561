import React from "react";
import { useSelector } from "react-redux";
import {interpolateColors, get_nb_drug_by_target, filterDataDrugWithConditionNoClean} from '../../../../utils' 

import "./style/legend_company_details.css";

export function CompetitiveDetailByTargetLegend(props) {
  const { is_active } = props;
  const data_active = useSelector((state) =>
    !is_active
      ? state.competitive.timeChartcompetitiveData[0]
      : state.competitive.timeChartcompetitiveData[1]
  );
  const depth = useSelector((state) => state.diease.statusDrugDisplay);
  const MOA = useSelector((state) => state.diease.MOADisplay);
  const target = useSelector((state) => state.diease.drugTargetDisplay);
  const type = useSelector((state) => state.diease.drugTypeDisplay);
  const theraFranchise = useSelector(
    (state) => state.diease.theraFranchiseDisplay
  );

  const dataAD = data_active
    ? filterDataDrugWithConditionNoClean(
        data_active,
        depth,
        [],
        type,
        MOA,
        theraFranchise,
        target
      )
    : [];

  const set_color = (size) => {
    return interpolateColors(size);
  };
  const data = get_nb_drug_by_target( dataAD);
  const colors = set_color(data.keys.length)

  return (
    <ul className="ul_chart_phases_and_targets">
      {Object.keys(data.data).map((el, index) =>
          <li>
            <div className="container">
              <div className="carre" style={{ "background-color": colors[index] }}>
                {" "}
              </div>
              <div style={{ "padding-left": "5px"}}>
                {el}
              </div>
            </div>
          </li>
        )}
    </ul> 
  );
}
