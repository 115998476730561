import React from "react";

import './style/legend_company_details.css';

export function AllNCTCompanyDetailByPhaseLegend(){

      return (
        <ul className='custom_ul_legend_chart'>
        <li>
          <div className='container'>
          <div className="carre" style={{'backgroundColor' :"#ad345c"}}> </div>
          <div style={{'paddingLeft': '10px',  paddingRight: '15px'}}>Phase 1 (primary date)</div> 
          </div>
        </li>
        <li>
          <div className='container'>
          <div className="carre" style={{'backgroundColor' :"#e08da7"}}> </div>
          <div style={{'paddingLeft': '10px',  paddingRight: '15px'}}>Phase 1 (completion date)</div> 
          </div>
        </li>
  
        <li>
          <div className='container'>
          <div className="carre" style={{'backgroundColor' :"#e2823d"}}> </div>
          <div style={{'paddingLeft': '10px',  paddingRight: '15px'}}>Phase 2 (primary date)</div> 
          </div>
        </li>
        <li>
          <div className='container'>
          <div className="carre" style={{'backgroundColor' :"#f4bd95"}}> </div>
          <div style={{'paddingLeft': '10px',  paddingRight: '15px'}}>Phase 2 (completion date)</div> 
          </div>
        </li>
  
        <li>
          <div className='container'>
          <div className="carre" style={{'backgroundColor' :"#27789E"}}> </div>
          <div style={{'paddingLeft': '10px',  paddingRight: '15px'}}>Phase 3 (primary date)</div> 
          </div>
        </li>
          
        <li>
          <div className='container'>
          <div className="carre" style={{'backgroundColor' :"#8bbed6"}}> </div>
          <div style={{'paddingLeft': '10px',  paddingRight: '15px'}}>Phase 3 (completion date)</div> 
          </div>
        </li>
  
      </ul>
      );
}