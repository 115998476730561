import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  set_filter_personas_user,
} from "../../../Action";

export function BtnDOLPersonasDisplay() {
  const dispatch = useDispatch();
  const personas = useSelector((state) => state.dol.list_personas);
  const [is_checked, set_checked] = useState(personas);

  const handleCheckboxChange = (selectedPersona) => { 
    if(is_checked[selectedPersona]) {
      set_checked({
        ...is_checked,
        [selectedPersona]: !is_checked[selectedPersona],
      });
    }
    else {
      const updatedPersonas = Object.fromEntries(
        Object.entries(is_checked).map(([persona, checked]) => [
          persona,
          persona === selectedPersona,
        ])
      );
      set_checked(updatedPersonas)
    }
  };
  
  useEffect(() => {
    dispatch(set_filter_personas_user(is_checked));
  }, [dispatch, is_checked]);

  return (
    <div className="personas-filter-container">
      {Object.keys(personas).map((content, index) => (
        <div className="personas-filter-container--hcp">
          <label className="btn-phase-container">
            <input
              type="checkbox"
              checked={is_checked[content]}
              onChange={() => handleCheckboxChange(content)}
              name={content}
            ></input>
            <svg viewBox="0 0 64 64" height="2em" width="2em">
              <path
                d="M 0 16 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 16 L 32 48 L 64 16 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 16"
                pathLength="575.0541381835938"
                className="path"
              ></path>
            </svg>
          </label>
          <h2>{content}</h2>
        </div>
      ))}
    </div>
  );
}
