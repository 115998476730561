import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { VerticalBarChart, LoadingAnimation } from "../../components";
import { getCompanyDetailsGraph, setGraphPhaseCompanyLoadingAnimation } from "../../Action";

function transformObject(obj) {
  const transformedArray = Object.entries(obj).map(([year, values]) => {
    return { name: parseInt(year), ...values };
  });
  return transformedArray;
}

export function AllDrugCompanyDetailByPhase(props) {
  const { companyId } = props;
  const dispatch = useDispatch();
  const data = useSelector((state) => state.company.detailsbyPhase);
  const load = useSelector((state) => state.config.graphPhaseCompanyLoading);
  const transformedArray = transformObject(data.length === 0 ? data : data[0]);

  useEffect(() => {
    dispatch(getCompanyDetailsGraph(companyId));
    dispatch(setGraphPhaseCompanyLoadingAnimation(true));
  }, [companyId, dispatch]);

  const datakeys = [
    "PHASE 1",
    "PHASE 1/PHASE 2",
    "PHASE 2",
    "PHASE 3",
    "NDA/BLA",
    "APPROVED",
  ];

  const colors = [
    "#ad345c",
    "#e2823d",
    "#e2823d",
    "#27789E",
    "#2B6530",
    "#59AB46",
  ];

  return (
    <div className="company-graph-pannel__treatement-tabs__details-graph__phase-graph--chart">
      {load === false ? (
        <VerticalBarChart
          data={transformedArray}
          keys={datakeys}
          colors={colors}
        />
        ) : (
        <div className="loading-animation" style={{height:"400px", width:"400px"}}>
          <LoadingAnimation type={"circular"} />
        </div>
        )}
    </div>
  );
}
