import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getDataByCompany,
  setGraphLoadingAnimation,
  getRSSCompanyTab,
} from "../../Action";

import {
  LoadingAnimation,
  LineChartVolume,
  MUItoogleBtn,
} from "../../components";

export function SentimentVolumeCompanyGraph(props) {
  const { drugId, setbtn, action_btn, which_btn } = props;
  const dispatch = useDispatch();
  const load = useSelector((state) => state.config.graphLoading);
  const { databyId } = useSelector((state) => state.company);
  const dataRSS = useSelector((state) => state.company.RSSTab);

  function isNumeric(value) {
    return /^-?\d+$/.test(value);
  }

  useEffect(() => {
    if (drugId !== "-1" && isNumeric(drugId)) {
      dispatch(getDataByCompany(drugId, action_btn[which_btn]));
      dispatch(setGraphLoadingAnimation(true));
      dispatch(getRSSCompanyTab(drugId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drugId, action_btn[which_btn], dispatch]);

  return (
    <div className="sentiment-volume-company-graph">
      <div style={{ paddingBottom: "5px" }}>
        <MUItoogleBtn
          action_btn={action_btn}
          setbtn={setbtn}
          which_btn={which_btn}
        />
      </div>
      {load === false ? (
        <div>
          <LineChartVolume
            data={databyId}
            dataRSS={dataRSS}
            period={action_btn[which_btn]}
          />
        </div>
      ) : (
        <div className="loading-animation" style={{height:"200px"}}>
          <LoadingAnimation type={"circular"} />
        </div>
      )}
    </div>
  );
}
