import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  removeMyDisease,
  removeMyTarget,
  removeMyDrugType,
  removeMyTheraFranchise,
  setTopicsSelected,
  setDiseaseWCloudSelected,
  set_filter_personas_user,
} from "../../../../../Action";

function Chip(props) {
  const { label, action } = props;
  return (
    <div className="chip">
      <div className="chip__content">{label}</div>
      <button className="chip__button" onClick={action}>
        <div className="chip__button-line chip__button-line--first"></div>
        <div className="chip__button-line chip__button-line--second"></div>
      </button>
    </div>
  );
}

export function LrxChips(props) {
  const { drugId , chips_type = "filter", is_congress_cards = false} = props;
  const dispatch = useDispatch();
  const theraFranchise = useSelector(
    (state) => state.diease.theraFranchiseDisplay
  );
  const target = useSelector((state) => state.diease.drugTargetDisplay);
  const disease = useSelector((state) => state.diease.diseaseDisplay);
  const type = useSelector((state) => state.diease.drugTypeDisplay);
  const topic = useSelector((state) => state.config.TopicsSet);
  const wCloudDisease = useSelector((state) => state.config.diseaseWcloud);
  const personas = useSelector((state) => state.dol.list_personas);
  const list_personas_selected = Object.keys(personas).filter(persona => personas[persona] === true);

  useEffect(() => {
    dispatch(setTopicsSelected(""));
    dispatch(setDiseaseWCloudSelected(""));
  }, [drugId, dispatch]);

  if (chips_type === "filter")
    return (
      <span>
        <div>
          {list_personas_selected.map((el, i) => (
            personas[el] ?
              <Chip key={i} label={el} action={() => dispatch(set_filter_personas_user({...personas, [el]: false}))} />
              : null
          ))}
        </div>
        <div className="chip-container">            
          <div>
            {theraFranchise.map((el, i) => (
              <Chip
                key={i}
                label={el.therapeutic_franchise_name}
                action={() =>
                  dispatch(
                    removeMyTheraFranchise(parseInt(el.id_therapeutic_franchise))
                  )
                }
              />
            ))}
          </div>
          {!is_congress_cards ?
            <div>
              {disease.map((el, i) => (
                <Chip
                  key={i}
                  label={el.disease_display_name}
                  action={() => dispatch(removeMyDisease(parseInt(el.id_disease)))}
                />
              ))}
              {target.map((el, i) => (
                <Chip
                  key={i}
                  label={el.display_name}
                  action={() => dispatch(removeMyTarget(parseInt(el.id_drug_target)))}
                />
              ))}
              {type.map((el, i) => (
                <Chip key={i} label={el.drug_type} action={() => dispatch(removeMyDrugType(el.id_drug_type))} />
              ))}
            </div>
            : null
          }
        </div>      
      </span>
    );
  else
    return (
      <span>
        <div>
          {chips_type === "topics" ?
            list_personas_selected.map((el, i) => (
              personas[el] ?
                <Chip key={i} label={el} action={() => dispatch(set_filter_personas_user({...personas, [el]: false}))} />
                : null
            ))
            : null
          }
        </div>
        <div className="chip--wordcloud-and-topics-and-hcp">
          <div>
            {topic !== "" ? (
              <Chip
                key={0}
                label={topic}
                action={() => dispatch(setTopicsSelected(""))}
              />
            ) : null}
          </div>
          <div>
            {wCloudDisease !== "" ? (
              <Chip
                key={0}
                label={wCloudDisease.disease_display_name}
                action={() => dispatch(setDiseaseWCloudSelected(""))}
              />
            ) : null}
          </div>
        </div>
      </span>
    );
}
