import React from "react";
import {useSelector } from 'react-redux'

export function SpecCompanyBox(){
    const info  = useSelector (state => state.company.companyInfo);

    return (
    <div className="info_card_card_content">
      <div className="info-pannel-content"> 
        <span className="info-pannel_box_name_subtitle"> Company Ticker : </span>
        <span> {info.company_ticker}</span>
      </div>
      <div className="info-pannel-content"> 
        <span className="info-pannel_box_name_subtitle"> Market CAP : </span>
        <span> {info.company_market_cap_type}</span>
      </div>
      <div className="info-pannel-content"> 
        <span className="info-pannel_box_name_subtitle"> Country : </span>
        <span> {' '}</span>
      </div>
      <div className="info-pannel-content"> 
        <span className="info-pannel_box_name_subtitle"> Web Site : </span>
        <a className="info-pannel-content__website-link" href={"http://"+ info.website} target="_blank"  rel="noreferrer"><span> {info.website}</span></a>
      </div>
      </div>
      );
}