

export function getParamValueTabs (paramName,params, size ) {
    const paramGet = params.get(paramName);
    const v = parseInt(paramGet);
    if (!v || v >= size) {
        return 0
    }
    return v
  };
  


export function getParamId (paramName,params ) {
    const paramGet = params.get(paramName);
    if (!paramGet) {
        return ""
    }
    return paramGet
  };
  