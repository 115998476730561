import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { VerticalBarChart, LoadingAnimation } from "../../components";
import { getNCTTreatmentDetailsGraph, setNCTTreatmentDetailsGraphLoadingAnimation } from "../../Action";

function transformObject(obj) {
  const transformedArray = Object.entries(obj)
    .map(([year, values]) => {
      return { name: year, ...values };
    });
  return transformedArray;
}

export function AllNCTTreatmentDetailByPhase(props) {
  const disease = useSelector((state) => state.diease.diseaseDisplay);
  const target = useSelector((state) => state.diease.drugTargetDisplay);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.company.detailsbyPhase);
  const load = useSelector((state) => state.config.nctTreatmentDetailsGraphLoading);

  const resultat = data.reduce((acc, { annee, phase, nombre_nct }) => {
    if (!acc[annee]) {
      acc[annee] = {};
    }
    acc[annee][phase] = nombre_nct;
    return acc;
  }, {});

  const transformedArray = transformObject(resultat);

  useEffect(() => {
    dispatch(getNCTTreatmentDetailsGraph(disease, target));
    dispatch(setNCTTreatmentDetailsGraphLoadingAnimation(true));
  }, [disease, target, dispatch]);

  const datakeys = [
    "phase 1",
    "phase 1/phase 2",
    "phase 2",
    "phase 3",
    "nda/bla",
    "approved",
  ];

  const colors = [
    "#ad345c",
    "#e2823d",
    "#e2823d",
    "#27789E",
    "#2B6530",
    "#59AB46",
  ];

  return (
    <div className="company-graph-pannel__treatement-tabs__details-graph__phase-graph--chart">
      {load === false ?
        <VerticalBarChart
          data={transformedArray}
          keys={datakeys}
          colors={colors}
        />
        : (
          <div className="company-graph-pannel__treatement-tabs__details-graph__phase-graph--loading-container">
            <LoadingAnimation type={"circular"} />
          </div>
        )}
    </div>
  )
}
