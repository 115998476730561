import axios from 'axios';
import configData from './react_config.json';


export const api = axios.create({
    //baseURL: 'http://192.168.0.33:8000/api/',
    baseURL: configData.SERVER_URL
    //baseURL: 'https://doltracker.ew.r.appspot.com/api/'
});



api.interceptors.request.use(config => {
    config.headers.common['Authorization'] = `LRX ${localStorage.getItem('token')}`;
    return config;
});
