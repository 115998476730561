import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";

import { DataFilterTable, LoadingAnimation } from "..";

import {  get_filter_key } from "../../utils";
import { getCatalystCompanyTab, setCompanyCatalystTabLoadingAnimation } from "../../Action";

export function CatalystCompanyTable(props) {
  const { id_company } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const date = useSelector (state => state.config.date);
  const data= useSelector((state) => state.company.catalystTab);
  const load = useSelector((state) => state.config.companyCatalystTabLoading);

  const go_to = (name, id) => {
    if (name === "drug") {
      navigate(`/drug/${id}`);
    }
    if (name === "nct") {
      const win = window.open(
        "https://clinicaltrials.gov/ct2/show/" + id,
        "_blank"
      );
      if (win != null) win.focus();
    }
  };

  useEffect(() => {
    dispatch(getCatalystCompanyTab(id_company, date));
    dispatch(setCompanyCatalystTabLoadingAnimation(true));
  }, [id_company, date, dispatch]);

  const columns = [
    {
      name: "catalyst_date",
      header: "Catalyst Date",
      defaultFlex: 1,
      maxWidth: 133,
      minWidth: 100,
    },
    {
      name: "nct",
      header: "NCT",
      defaultFlex: 1,
      minWidth: 100,
      maxWidth: 100,
      render: ({ value, data }) => (
        <span className="title-link" onClick={() => go_to("nct", data.nct)}>{value}</span>
      ),
    },
    {
      name: "drug_display_name",
      header: "Therapeutics",
      defaultFlex: 1,
      minWidth: 145,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("drug_display_name", data),
      },
      render: ({ value, data }) => (
        <span className="title-link" onClick={() => go_to("drug", data.id_drug)}>{value}</span>
      ),
    },
    {
      name: "catalyst_type",
      header: "Catalyst",
      defaultFlex: 1,
      minWidth: 220,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("catalyst_type", data),
      },
    },
    {
      name: "trial_phase",
      header: "Phase",
      defaultFlex: 1,
      minWidth: 120,
      maxWidth: 180,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("trial_phase", data),
      },
    },
    {
      name: "value_date",
      header: "Announcement",
      defaultFlex: 1,
      minWidth: 145,
      maxWidth: 180,
    }
  ];

  const filterValue = [
    { name: "drug_display_name", operator: "inlist", type: "select", value: "" },
    { name: "catalyst_type", operator: "inlist", type: "select", value: "" },
    { name: "trial_phase", operator: "inlist", type: "select", value: "" },
    //{
    //  name: "disease_name",
    //  operator: "inlist",
    //  type: "select",
    //  value: "",
    //},
  ];

  return (
    <div style={{ height: 600, width: "100%", paddingTop: 30 }}>
      {load === false ? (
        <DataFilterTable
          dataSource={data}
          columns={columns}
          enableFiltering={true}
          filterValue={filterValue}
          defaultFilterName={[]}
        />
      ) : (
        <div className="loading-animation" style={{ height:"600px"}}>
          <LoadingAnimation type={"circular"} />
        </div>
      )}
    </div>
  );
}
