import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadingAnimation } from "..";
import { TimeLineChart } from "..";
import { filterDataDrugWithCondition } from "../../utils";

import {
  getCompetitiveDataDrugGraph,
  setGraphCompetitiveDataDrugLoadingAnimation,
} from "../../Action";

export function AllDrugWithDiseaseGraph(props) {
  const { is_active, mui_class } = props;

  const dispatch = useDispatch();
  const load = useSelector((state) => state.config.graphCompetitiveDataDrugLoading);
  const dataAllDrug = useSelector(
    (state) => state.competitive.timeChartcompetitiveData[is_active]
  );
  const depth = useSelector((state) => state.diease.statusDrugDisplay);
  const MOA = useSelector((state) => state.diease.MOADisplay);
  const target = useSelector((state) => state.diease.drugTargetDisplay);
  const type = useSelector((state) => state.diease.drugTypeDisplay);
  const disease = useSelector((state) => state.diease.diseaseDisplay);
  const theraFranchise = useSelector(
    (state) => state.diease.theraFranchiseDisplay
  );

  const filterData = dataAllDrug
    ? filterDataDrugWithCondition(
        dataAllDrug,
        depth,
        disease,
        type,
        MOA,
        theraFranchise,
        target
      )
    : [];
  filterData.map((l, index) => {
    if (index !== 0) {
      l[3] = new Date(l[3]);
      l[4] = new Date(l[4]);
    }
    return [l[3], l[4]];
  });

  useEffect(() => {
    if (is_active === 0) {
      dispatch(getCompetitiveDataDrugGraph(disease, target));
      dispatch(setGraphCompetitiveDataDrugLoadingAnimation(true));
    }
  }, [dispatch, disease, target, is_active]);

  return (
    <div className={mui_class}>
      {load === false ? (
        <TimeLineChart dataAD={filterData} infoDrug={dataAllDrug} />
      ) : (
        <div className="loading-animation" style={{height: "200px"}}>
          <LoadingAnimation type={"circular"} />
        </div>
      )}
    </div>
  );
}
