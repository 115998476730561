import React ,{useEffect} from "react";
import { useDispatch , useSelector} from 'react-redux'
import { useNavigate } from "react-router-dom";

import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter'

import {DataFilterTable, LoadingAnimation} from '..'
import { filterDataDrug, get_filter_key } from "../../utils";
import { getAlertTreatment, setTreatmentTabLoadingAnimation } from '../../Action';

export function TreatmentAlertTable() {
  const dispatch = useDispatch();
  const navigate = useNavigate ();
  
  const date = useSelector (state => state.config.date);
  const data = useSelector(state => state.drug.treatmentTD);
  const load = useSelector (state =>  state.config.tabAlertTreatmentLoading);

  const disease = useSelector ( state => state.diease.diseaseDisplay);
  const depth = useSelector (state =>state.diease.statusDrugDisplay);
  const type = useSelector ( state => state.diease.drugTypeDisplay);
  const theraFranchise = useSelector(state => state.diease.theraFranchiseDisplay);
  const target = useSelector ( state => state.diease.drugTargetDisplay);

  useEffect(() => {
    dispatch(getAlertTreatment(date));
    dispatch(setTreatmentTabLoadingAnimation(true));
}, [date, dispatch])

  const go_to = (name, id) => {
    if(name === "drug") {
       navigate(`/drug/${id}`);
     }
     if(name=== "link") {
       const win = window.open(id, '_blank');
       if (win != null)
       win.focus();
      }
   }

  const columns = [
    { name: 'start_date', header: 'Date', minWidth: 100, maxWidth: 100, defaultFlex: 1 },
    { name: 'phase', header: 'New Phase', maxWidth: 300, minWidth: 171, defaultFlex: 1,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: 'All',
        dataSource: get_filter_key('phase', data)
        },
      },
    {
      name: 'drug_true_name',
      header: 'Drug',
      defaultFlex: 1,
      minWidth: 156,
      render: ({value, data}) => <span className = "title-link" onClick={()=>go_to("drug", data.id_drug)}>{value}</span>,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: 'All',
        dataSource:  get_filter_key('drug_true_name', data)
      },
    },
    { name: 'disease_display_name', header: 'Disease', minWidth: 300, defaultFlex: 1,
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'All',
      dataSource:  get_filter_key('disease_display_name', data)
    }, }
  ];


  const filterValue = [
    {name: 'drug_true_name', operator: 'inlist', type: 'select' , value: ''},
    {name: 'disease_display_name', operator: 'inlist', type: 'select', value: '' },
    {name: 'phase', operator: 'inlist', type: 'select', value: '' },
  ]
    return (
        <div className="treatment-alert-table">
        {load === false ? (
          <DataFilterTable
            dataSource={filterDataDrug(data, depth, disease,type, [], theraFranchise, target)}
            columns={columns}
            filterValue = {filterValue}
            showZebraRows={true}
            defaultFilterName = {[]}
          /> 
        ) : (
          <div className="loading-animation" style={{height: "100%"}}>
          <LoadingAnimation type={"circular"} />
        </div>
      )}
    </div>
  )
}