import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { VerticalBarChart, LoadingAnimation } from "../../components";
import { setNCTCompanyDetailsGraph, setCompanyNCTHistoByPhaseLoadingAnimation } from "../../Action";

export function AllNCTCompanyDetailByPhase(props) {
  const { companyId } = props;
  const dispatch = useDispatch();
  const data = useSelector((state) => state.company.detailsbyPhase);
  const load = useSelector((state) => state.config.companyNCTHistoByPhase);

  const resultat = data.reduce((acc, { annee, phase, nombre_nct }) => {
    if (!acc[annee]) {
      acc[annee] = {};
    }
    acc[annee][phase] = nombre_nct;
    return acc;
  }, {});

  const transformedArray = Object.entries(resultat).map(([year, values]) => {
    return { name: parseInt(year), ...values };
  });

  useEffect(() => {
    dispatch(setNCTCompanyDetailsGraph(companyId));
    dispatch(setCompanyNCTHistoByPhaseLoadingAnimation(true));
  }, [companyId, dispatch]);

  const datakeys = [
    "phase 1",
    "phase 1/phase 2",
    "phase 2",
    "phase 3",
    "nda/bla",
    "approved",
  ];

  const colors = [
    "#ad345c",
    "#e2823d",
    "#e2823d",
    "#27789E",
    "#2B6530",
    "#59AB46",
  ];

  return (
    <div className="company-graph-pannel__treatement-tabs__details-graph__phase-graph--chart">
      {load === false ? (
        <VerticalBarChart
          data={transformedArray}
          keys={datakeys}
          colors={colors}
        />
        ) : (
        <div className="loading-animation" style={{height:"400px", width:"400px"}}>
          <LoadingAnimation type={"circular"} />
        </div>
        )}
    </div>
  );
}
