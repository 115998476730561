import React from "react";
import logo from '../../../../Asset/chevron.png'

export function LimitDisplayByPage(props){

    const {setPage, page} = props

    const handleChange = (page) => {
        setPage(page + 99);
      };

  return (
    <img onClick={()=>handleChange(page)} alt={'add_more'} src={logo} className= 'btn_add_more'></img>
  );
}