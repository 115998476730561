import React from "react";
import { Card , CardContent } from '@mui/material';



export function MuiCard(props){
  const {mui_class, mui_value} = props 

  return (
    <Card className={`${mui_class} lib-mui-card`}   sx={{
      overflow: mui_value === 1 ? 'hidden' : 'visible',
    }} >
      <CardContent>{props.children}</CardContent>
    </Card>
  );
}