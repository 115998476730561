import React from "react";
import { useSelector } from "react-redux";

import { GaugeChartVolume } from "./../../../components";

import { convertDate } from "../../../utils";

export function RankCompanyNewsBox() {
  const date = useSelector((state) => state.config.date);
  const { databyId } = useSelector((state) => state.company);

  const dateSelected = databyId.find((element) => {
    return element.date === convertDate(date);
  });

  return dateSelected ? (
    <div>
      <div className="info_card_title">
        <div className="info_card_box_flex">
          {dateSelected ? (
            <div className="info_gauge_graph_volume">
              <GaugeChartVolume
                dateSelected={dateSelected.deviation_volume_last_week_52}
              />
              <span> News momentum </span>
            </div>
          ) : null}
          {dateSelected ? (
            <div className="info_gauge_graph_volume">
              <GaugeChartVolume
                dateSelected={dateSelected.deviation_sentiment_last_week_2}
              />
              <span> Catalysts indicator </span>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  ) : null;
}
