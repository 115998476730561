import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { IconButton } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { getParamValueTabs, is_departement_is_social } from "../../utils";

import {
  AllDrugTable,
  MuiTabsNavigation,
  SentimentVolumeCompanyGraph,
  TreeMapTrendingDrug,
  AllDrugCompanyTable,
  AllDrugCompanyGraph,
  AllDrugCompanyDetailByPhase,
  AllDrugCompanyDetailByPhaseLegend,
  MuiCard,
  MuiCollapse,
  RSSCompanyTable,
  LrxChips,
  BtnCsvExport,
  CatalystCompanyTable,
  NCTCompanyGraph,
  AllNCTCompanyDetailByPhaseLegend,
  NCTCompanyTable,
  AllTherapeuticFranchiseCompanyDetailByPhase,
  AllNCTTherapeuticFranchiseCompanyDetailByPhase,
  AllNCTCompanyDetailByPhase,
  AllDrugCompanyDetailInactiveByPhase,
  CatalystCompanyCalendar,
  ModalNeedSocialAccess,
  ChartIcon,
  LrxBtn
  //CompanyDetailsByTarget,
  //CoompanyDetailByTargetLegend,
} from "../../components";

import { InfoPannel } from "../../layout";
import "./style/company_graph_pannel.css";
import companySocial from "../../../Asset/company-social.png";

function SocialTabs(props) {
  const { companyId } = props;
  const action_btn = ["day", "week", "month", "quarter"];
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const monParametreValue = getParamValueTabs("periods", params, action_btn.length);
  const [which_btn, setbtn] = useState(monParametreValue);
  const [is_open, set_open] = useState(false);
  const handleChange = (open) => set_open(open);
  const date = useSelector((state) => state.config.date);
  const user = useSelector((state) => state.auth.auth_name);
  return (
    <div>
      {is_departement_is_social(user) ? (
        <div className="company-graph-pannel__social">
          <div>
            <h2> Social Engagement and Sentiment</h2>
            <MuiCard mui_class={""}>
              <SentimentVolumeCompanyGraph
                drugId={companyId}
                setbtn={setbtn}
                which_btn={which_btn}
                action_btn={action_btn}
              />
              <RSSCompanyTable
                rssCompanyValue={0}
                companyId={companyId}
                filter_date={date}
                period={action_btn[which_btn]}
                enableFiltering={false}
                enablePagination={false}
                rsstabclassName={
                  "company-graph-pannel__social-tabs__volume-container__rss-tab"
                }
              />
            </MuiCard>        
          </div>
          <div className="company-graph-pannel__social__trending-drugs">
            <h2>
              {" "}
              Trending Drugs <LrxChips />
            </h2>
            <MuiCard mui_class={"company_pannel_treemap"}>
              <TreeMapTrendingDrug
                value={0}
                filtre={companyId}
                setbtn={setbtn}
                which_btn={which_btn}
                action_btn={action_btn}
              />
              <AllDrugTable
                filtre={companyId}
                which_btn={which_btn}
                action_btn={action_btn}
              />
            </MuiCard>
          </div>
        </div>
      ) : (
        <div className="locked-screen-drug-pannel">
          <ModalNeedSocialAccess more_info={is_open} onChange={handleChange}/>
          <img className="locked-screen-drug-pannel__img" src={companySocial} alt="background-company"/>
          <div className="locked-screen-drug-pannel--icon-button">
            <div className="locked-screen-drug-pannel--icon-button__icon">  
              <ChartIcon logo={"LockedIcon"} />
            </div>
            <div className="need-premium-modal__validate">
              <LrxBtn
                action_btn={() => handleChange(true)}
                logo={"ActionSucess"}
                text_btn={"More Info"}
                my_className={"need-premium-modal__validate--btn"}
              /> 
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function PipelineTabs(props) {
  const { companyId } = props;
  const [show_active, set_show] = useState(false);
  const [isRotated, setIsRotated] = useState(false);

  const handleChange = () => {
    set_show(!show_active);
    setIsRotated(!isRotated);
  };

  return (
    <div className="company-graph-pannel">
      {/* <div className="company-graph-pannel__header">
        <h2 className="company-graph-pannel__header--title"> </h2>
      </div> */}
      <div className="company-graph-container">
        <MuiCard mui_class={"company-graph-pannel-graph"}>
          <div className="company-graph-pannel__treatement-tabs__details-graph">
            <div className="company-graph-pannel__treatement-tabs__details-graph__phase-graph">
              <h3 className="company-graph-pannel__treatement-tabs__details-graph__phase-graph--title">
                {" "}
                Historical by phase
              </h3>
              <div>
                <AllDrugCompanyDetailByPhase companyId={companyId} />
              </div>
            </div>
            <div className="company-graph-pannel__treatement-tabs__details-graph__phase-graph">
              <h3 className="company-graph-pannel__treatement-tabs__details-graph__phase-graph--title">
                {" "}
                Current by therapeutic franchise
              </h3>{" "}
              <div>
                <AllTherapeuticFranchiseCompanyDetailByPhase
                  companyId={companyId}
                />
              </div>
            </div>
          </div>
          <AllDrugCompanyDetailByPhaseLegend is_nct={false} />
        </MuiCard>
      </div>
      <h2>
        Pipeline Details <LrxChips />
      </h2>
      <MuiCard mui_class={"company-graph-pannel__treatement-tabs"}>
        <div className="company-graph-pannel__treatement-tabs__header-graph">
          <h2 className="company-graph-pannel__treatement-tabs__header-graph--title">
            {" "}
            ACTIVE THERAPEUTICS
          </h2>
          <BtnCsvExport
            my_className={
              "company-graph-pannel__treatement-tabs__header-graph--action-btn"
            }
            data_to_export={{ location: "company", name: "treatment_company" }}
          />
        </div>
        <AllDrugCompanyGraph filtre={companyId} is_active={0} />
        <div className="company-graph-pannel__treatement-tabs__inactive-header">
          <h2>
            INACTIVE THERAPEUTICS
          </h2>
          <div className="controlPannel-box-expandIcon">
            <IconButton
              className={`controlPannel-box-expandIcon ${isRotated ? 'rotated' : ''}`}
              onClick={() => handleChange()}
            >
              <ExpandMoreIcon />
            </IconButton>
          </div>
        </div>
        <MuiCollapse is_open={show_active}>
            <AllDrugCompanyDetailInactiveByPhase companyId={companyId} />
            <AllDrugCompanyGraph filtre={companyId} is_active={1} />
        </MuiCollapse>
      </MuiCard>
      <h2 className="company-graph-pannel__treatement-tabs__header-graph--title">
        Therapeutics Details <LrxChips />
      </h2>
      <MuiCard mui_class={""}>
        <div className="company-graph-pannel__treatement-tabs__header-graph">
          <h3 className="company-graph-pannel__treatement-tabs__header-graph--title">
            THERAPEUTICS
          </h3>
          <BtnCsvExport
            my_className={""}
            data_to_export={{ location: "company", name: "drug_company" }}
          />
        </div>
        <AllDrugCompanyTable filtre={companyId} />
      </MuiCard>
    </div>
  );
}

function NewsTabs(props) {
  const { companyId } = props;
  return (
    <div className="drug-graph-pannel">
      <h2>Upcoming Events and Press Releases</h2>
      <div className="company-graph-pannel__news-tabs">
        <MuiCard mui_class={""}>
        <div className="company-graph-pannel__treatement-tabs__header-graph">
          <h3 className="company-graph-pannel__treatement-tabs__header-graph--title">
          Catalysts 
          </h3>
          <BtnCsvExport
            my_className={""}
            data_to_export={{location: "company", name: "catalyst_company"}}
          />
        </div>
        <CatalystCompanyCalendar id_company={companyId}/>
          <CatalystCompanyTable id_company={companyId} />
        </MuiCard>
        <MuiCard mui_class={"company-graph-pannel__tab"}> 
        <div className="company-graph-pannel__treatement-tabs__header-graph">
          <h3 className="company-graph-pannel__treatement-tabs__header-graph--title">
          Press Releases
          </h3>
          <BtnCsvExport
            my_className={""}
            data_to_export={{ location: "company", name: "RSS_company" }}
          />
        </div>
          <RSSCompanyTable
            rssCompanyValue={1}
            companyId={companyId}
            rsstabclassName={"company-graph-pannel__news-tabs__rss-tab"}
          />
        </MuiCard>
      </div>
    </div>
  );
}

function ClinicalTabs(props) {
  const { companyId } = props;
  return (
    <div className="drug-graph-pannel">
      {/* <div className="company-graph-pannel__header">
        {" "}
        <h2 className="company-graph-pannel__header--title"> </h2>
      </div> */}
      <div className="company-graph-container">
        <MuiCard mui_class={"company-graph-pannel-graph"}>
          <div className="company-graph-pannel__treatement-tabs__details-graph">
            <div className="company-graph-pannel__treatement-tabs__details-graph__phase-graph">
              <h3 className="company-graph-pannel__treatement-tabs__details-graph__phase-graph--title">
                {" "}
                Historical by phase (starting date)
              </h3>
              <div>
                <AllNCTCompanyDetailByPhase companyId={companyId} />
              </div>
            </div>
            <div className="company-graph-pannel__treatement-tabs__details-graph__phase-graph">
              <h3 className="company-graph-pannel__treatement-tabs__details-graph__phase-graph--title">
                {" "}
                Current by therapeutic franchise
              </h3>{" "}
              <div>
                <AllNCTTherapeuticFranchiseCompanyDetailByPhase
                  companyId={companyId}
                />
              </div>
            </div>
          </div>
          <AllDrugCompanyDetailByPhaseLegend is_nct={true} />
        </MuiCard>
      </div>
      <h2>
        Clinical Trials Details <LrxChips />
      </h2>
      <MuiCard mui_class={""}>
      <div className="company-graph-pannel__treatement-tabs__header-graph">
          <h3 className="company-graph-pannel__treatement-tabs__header-graph--title">
          </h3>
          <BtnCsvExport
            my_className={""}
            data_to_export={{ location: "company", name: "nct_company" }}
          />
        </div>
        <AllNCTCompanyDetailByPhaseLegend />
        <NCTCompanyGraph companyId={companyId} />
        <NCTCompanyTable companyId={companyId} if_history={true} />
      </MuiCard>
    </div>
  );
}

function TabPanel(props) {
  const { value, companyId } = props;
  if (value === "Social") return <SocialTabs companyId={companyId} />;
  if (value === "Pipeline") return <PipelineTabs companyId={companyId} />;
  if (value === "News") return <NewsTabs companyId={companyId} />;
  if (value === "Clinical trial") return <ClinicalTabs companyId={companyId} />;
  return null;
}

export function CompanyGraphPannel(props) {
  const { companyId } = props;
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const type_enum = ["Social", "Pipeline", "Clinical trial", "News"];
  const monParametreValue = getParamValueTabs("tabs", params, type_enum.length);
  const [type_of_content, setValue] = useState(monParametreValue);
  const user = useSelector((state) => state.auth.auth_name);

  if (companyId !== "-1") {
    return (
      <div className="company-graph-pannel--page">
        <InfoPannel
          drugId={companyId}
          value={1}
          type_of_content={type_of_content}
        />
        <div className="company-graph-pannel--page--info">
          <div className="company-graph-pannel--page--info__tab-navigation-container">
            <MuiTabsNavigation
              type_enum={type_enum}
              type_of_content={type_of_content}
              setValue={setValue}
              is_premium_user={is_departement_is_social(user)}
            />
          </div>
          <TabPanel value={type_enum[type_of_content]} companyId={companyId} />
        </div>
      </div>
    );
  } else {
    return null;
  }
}
