import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { LrxMenuInput } from "../../../components";
import { Tooltip } from "@mui/material";
import { IconButton } from "@mui/material";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

import { setMyTarget, removeMyTarget } from "../../../Action";
import {
  getParamId
} from "../../../utils";

export function BtnDrugTargetDisplay() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const my_option = useSelector((state) => state.diease.nameDrugTarget);
  const my_target = useSelector((state) => state.diease.drugTargetDisplay);

  const updateURLParamsRef = useRef();
  const [searchValue, setSearchValue] = useState("");
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  updateURLParamsRef.current = (paramName, paramValue) => {
    const params = new URLSearchParams(window.location.search);
    params.set(paramName, paramValue);
    const nouvelleURL = `${window.location.pathname}?${params}`;
    navigate(nouvelleURL);
  };

  const paramtarget = getParamId(
    "target",
    params
  );

  useEffect(() => {
    const id_t = paramtarget.split(";");
    for (let i = 0; i < id_t.length; ++i) {
      const tId = parseInt(id_t[i]);
      if (tId) {
        const c = my_option.filter(
          (item) => item.id_drug_target === tId
        );
        if (c) {
          dispatch(setMyTarget(c[0]));
        }
      }
    }
  }, [dispatch, my_option, paramtarget]);


  useEffect(() => {
    updateURL();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, my_target]);


  const updateURL = (event) => {
    let disease_sent = my_target
      ? my_target
          .map(function (el) {
            return el["id_drug_target"];
          })
          .join(";")
      : [];
    updateURLParamsRef.current("target", disease_sent);
  };


  const handleChange = (event) => {
    const itemName = event.target.name;
    const selectedItem = my_option.find(
      (item) => item.display_name === itemName
    );

    if (my_target.includes(selectedItem)) {
      dispatch(removeMyTarget(selectedItem.id_drug_target));
    } else {
      dispatch(setMyTarget(selectedItem));
    }
  };

  const clearFilter = () => {
    my_target.forEach((item) => {
      dispatch(removeMyTarget(item.id_drug_target));
    });
  };

  const drugTargetListFiltered = my_option.filter((user) =>
    user.display_name.toLowerCase().includes(searchValue.toLowerCase())
  );


  return (
    <div className="control-pannel-search-entity">
      <LrxMenuInput
        action_input={setSearchValue}
        name={"Search for a biological target"}
      />
      <h3 className="control-pannel-search-entity--nb-select-filter">
        {" "}
        {drugTargetListFiltered.length === 0
          ? "Biological target not found !"
          : drugTargetListFiltered.length +
            " biological targets available"}{" "}
      </h3>
      <h3 className="control-pannel-search-entity--nb-select-filter">
        {" "}
        {my_target.length === 0
          ? null
          : my_target.length + " biological target(s) selected"}{" "}
      </h3>
      {my_target.length === 0 ? null : (
        <Tooltip title="Delete">
          <IconButton onClick={clearFilter}>
            <RestartAltIcon />
          </IconButton>
        </Tooltip>
      )}
      <div className="control-pannel-search-entity__list-object-filter">
      <div class="control-pannel-search-entity__list-object-filter--form">
          <form>
            {my_target.map((item, index) => (
              <div key={index}>
                <label>
                  <input
                    type="checkbox"
                    onChange={handleChange}
                    name={item.display_name}
                    checked={my_target.includes(item)}
                  ></input>
                  <span>{item.display_name}</span>
                </label>
              </div>
            ))}
          </form>
        </div>
        <div class="control-pannel-search-entity__list-object-filter--form">
          <form>
            {drugTargetListFiltered.map((item, index) =>
              my_target.includes(item) ? null : (
                <div key={index}>
                  <label>
                    <input
                      type="checkbox"
                      onChange={handleChange}
                      name={item.display_name}
                      checked={my_target.includes(item)}
                    ></input>
                    <span>{item.display_name}</span>
                  </label>
                </div>
              )
            )}
          </form>
        </div>
      </div>
    </div>
  );
}
