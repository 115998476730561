import React from "react";
import { useSelector } from "react-redux";
import { format } from "date-fns";

import { LoadingAnimation, PieChartTopics } from "../../components";

export function PersonasGraph() {
  const load = useSelector(
    (state) => state.config.dataDOLRankingByDrugTabLoading
  );
  const data = useSelector((state) => state.dol.dol_ranking_by_drug_tab);
  const personas = useSelector((state) => state.dol.list_personas);
  const databyId = useSelector((state) => state.drug.databyId["by_drug"]);
  const date = useSelector((state) => state.config.date);
  const date_string = format(date, "yyyy-MM-dd");
  const selected_value = databyId.filter((d) => d.date === date_string);

  const listPersonaColor = ["#054b80", "#b53604", "#e8a005", "#3E1919"];
  const personaColorMap = {};

  Object.keys(personas).forEach((el, index) => {
    const persona = el.toUpperCase();
    const color = listPersonaColor[index];
    personaColorMap[persona] = color;
  });


  const personaSum = data.reduce((percentageObj, item) => {
    const { persona } = item;

    percentageObj[persona] = (percentageObj[persona] || 0) + 1;
    return percentageObj;
  }, {});

  const personaPercentage = data.reduce((percentageObj, item) => {
    const { persona } = item;

    percentageObj[persona] =
      (percentageObj[persona] || 0) +
      (1 / (selected_value[0]?.volume_1)) * 100;
    return percentageObj;
  }, {});

  const dataAgreg = Object.keys(personaPercentage).map((persona) => ({
    label: persona,
    value_sum: personaSum[persona],
    value: (personaSum[persona] / (selected_value[0]?.volume_1)) * 100 ,
    color: personaColorMap[persona] || "grey",
  }));

  const sum = dataAgreg.reduce((accumulator, object) => {
    return accumulator + object.value_sum;
  }, 0);

  dataAgreg.push({
    label: "OTHER",
    value_sum: sum,
    value: ((selected_value[0]?.volume_1  - sum )/ (selected_value[0]?.volume_1)) * 100,
    color: "#999B9E",
  });

  return (
    <div style={{ width: "40%", height: "100%", position: "relative" }}>
      {load === false ? (
        <div
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <PieChartTopics
            data={dataAgreg}
            fontSize={15}
            innerRadius={90}
            outerRadius={130}
            displayName={false}
          />
          <ul className="ul_chart_phases_and_targets">
            {Object.keys(personaPercentage).map((el, index) => (
              <li>
                <div className="container">
                  <div
                    className="carre"
                    style={{
                      "background-color": personaColorMap[el] || "black",
                    }}
                  >
                    {" "}
                  </div>
                  <div style={{ "padding-left": "5px" }}>{el}</div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div className="loading-animation" style={{ height: "100%" }}>
          <LoadingAnimation type={"circular"} />
        </div>
      )}
    </div>
  );
}
