import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";

import { DataFilterTable, LoadingAnimation } from "../../components";

import { filterDataDrug, get_filter_key } from "../../utils";
import { getRSSDiseaseTab, setRSSDiseaseLoadingAnimation } from "../../Action";

export function RSSDiseaseTable() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const d = useSelector((state) => state.competitive.competitiveDataNews);
  const load = useSelector((state) => state.config.tabRSSDiseaseLoading);
  const sentimentFontColor = ["#555e68","#1e3628","#690505","#0b361d" ,"#4a0101"];
  const sentimentBackgroundColor = ["#d9d9d9","#94deb4","#f49999","#2abc68", "#fa5252"];

  const disease = useSelector((state) => state.diease.diseaseDisplay);
  const depth = useSelector((state) => state.diease.statusDrugDisplay);
  const type = useSelector((state) => state.diease.drugTypeDisplay);
  const theraFranchise = useSelector(
    (state) => state.diease.theraFranchiseDisplay
  );
  const target = useSelector((state) => state.diease.drugTargetDisplay);
  const data = filterDataDrug(
    d,
    depth,
    disease,
    type,
    [],
    theraFranchise,
    target
  );

  useEffect(() => {
    dispatch(getRSSDiseaseTab(disease, target));
    dispatch(setRSSDiseaseLoadingAnimation(true));
  }, [disease, target, dispatch]);

  const go_to = (name, id) => {
    if (name === "drug") {
      navigate(`/drug/${id}`);
    }
    if (name === "link") {
      const win = window.open(id, "_blank");
      if (win != null) win.focus();
    }
  };

  const columns = [
    {
      name: "published_at",
      header: "Date",
      maxWidth: 85,
      minWidth: 85,
    },
    {
      name: "drug_true_name",
      header: "Drug",
      flex: 1,
      minWidth: 85,
      maxWidth: 180,
      render: ({ value, data }) => (
        <span className="title-link" onClick={() => go_to("drug", data.id_drug)}>{value}</span>
      ),
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("drug_true_name", data),
      },
    },
    {
      name: "disease_display_name",
      header: "Disease",
      flex: 1.5,
      minWidth: 100,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("disease_display_name", data),
      },
    },
    {
      name: "topics",
      header: "Topics",
      flex: 2,
      minWidth: 115,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("topics", data),
      },
    },
    {
      name: "sentiment",
      header: "Sentiment",
      flex: 1,
      minWidth: 110,
      render: ({ value }) => {
        return (
          <div className="all-drug-trending-table--sentiment_cells"
            style={{
              color: value === "positive" ? sentimentFontColor[1] : (value === "negative" ? sentimentFontColor[2] : (value === "very postive" ? sentimentFontColor[3] : (value === "very negative" ? sentimentFontColor[4] : sentimentFontColor[0]))) ,
              background: value === "positive" ? sentimentBackgroundColor[1] : (value === "negative" ? sentimentBackgroundColor[2] : (value === "very postive" ? sentimentBackgroundColor[3] : (value === "very negative" ? sentimentBackgroundColor[4] : sentimentBackgroundColor[0]))) ,
            }}
          >
            {value}
          </div>
        );
      },
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("sentiment", data),
      },
    },
    {
      name: "title",
      header: "Title  ",
      minWidth: 140,
      flex: 7,
      render: ({ value, data }) => (
        <span className="title-link" onClick={() => go_to("link", data.link)}>{value}</span>
      ),
    },
  ];

  const filterValue = [
    { name: "drug_true_name", operator: "inlist", type: "select", value: "" },
    {
      name: "disease_display_name",
      operator: "inlist",
      type: "select",
      value: "",
    },
    { name: "title", operator: "contains", type: "string", value: "" },
    { name: "topics", operator: "inlist", type: "select", value: "" },
    { name: "sentiment", operator: "inlist", type: "select", value: "" },
  ];

  return (
    <div style={{ height: 700, width: "100%" }}>
      {load === false ? (
        <DataFilterTable
          dataSource={data}
          columns={columns}
          filterValue={filterValue}
          defaultFilterName={[]}
        />
      ) : (
        <div className="loading-animation" style={{height: "100%"}}>
          <LoadingAnimation type={"circular"} />
        </div>
      )}
    </div>
  );
}
