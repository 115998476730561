import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAlertNCT, setAlertNCTTabAnimation } from "../../Action";
import { DataFilterTable, LoadingAnimation } from "..";
import { filterDataDrug, get_filter_key } from "../../utils";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";

export function NCTAlertTable() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const date = useSelector((state) => state.config.date);
  const d = useSelector((state) => state.drug.NCTTD);
  const load = useSelector((state) => state.config.tabAlertNCTLoading);

  const disease = useSelector((state) => state.diease.diseaseDisplay);
  const depth = useSelector((state) => state.diease.statusDrugDisplay);
  const type = useSelector((state) => state.diease.drugTypeDisplay);
  const theraFranchise = useSelector(
    (state) => state.diease.theraFranchiseDisplay
  );
  const target = useSelector((state) => state.diease.drugTargetDisplay);

  const data = filterDataDrug(
    d,
    depth,
    disease,
    type,
    [],
    theraFranchise,
    target
  );

  const go_to = (name, id) => {
    if (name === "drug") {
      navigate(`/drug/${id}`);
    }
    if (name === "nct") {
      const win = window.open(
        "https://www.clinicaltrials.gov/study/" + id,
        "_blank"
      );
      if (win != null) win.focus();
    }
  };

  useEffect(() => {
    dispatch(getAlertNCT(date));
    dispatch(setAlertNCTTabAnimation(true));
  }, [date, dispatch]);

  const columns = [
    {
      name: "original_status_since",
      header: "Date",
      defaultFlex: 1,
      minWidth: 90,
      maxWidth: 150,
    },
    {
      name: "status",
      header: "Alert",
      defaultFlex: 1,
      minWidth: 135,
      maxWidth: 135,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("status", data),
      },
    },
    {
      name: "drug_true_name",
      header: "Drug",
      defaultFlex: 1,
      minWidth: 156,
      maxWidth: 250,
      render: ({ value, data }) => (
        <span className = "title-link" onClick={() => go_to("drug", data.id_drug)}>{value}</span>
      ),
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("drug_true_name", data),
      },
    },
    {
      name: "disease_display_name",
      header: "Disease",
      defaultFlex: 1,
      minWidth: 200,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("disease_display_name", data),
      },
    },
    {
      name: "nct",
      header: "NCT Number",
      defaultFlex: 1,
      minWidth: 100,
      maxWidth: 150,
      render: ({ value, data }) => (
        <span className = "title-link" onClick={() => go_to("nct", data.nct)}>{value}</span>
      ),
    },
  ];

  const filterValue = [
    { name: "status", operator: "inlist", type: "select", value: "" },
    { name: "drug_true_name", operator: "inlist", type: "select", value: "" },
    {
      name: "disease_display_name",
      operator: "inlist",
      type: "select",
      value: "",
    },
  ];

  return (
    <div className="nct-alert-table">
      {load === false ? (
        <DataFilterTable
          dataSource={data}
          columns={columns}
          filterValue={filterValue}
          defaultFilterName={[]}
        />
      ) : (
        <div className="loading-animation" style={{height: "100%"}}>
          <LoadingAnimation type={"circular"} />
        </div>
      )}
    </div>
  );
}
