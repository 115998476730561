import React from "react";
import {Link} from '@mui/material'
import { useSelector } from "react-redux";


export function BuyBackCompany() {
  const info = useSelector((state) => state.company.companyInfo);
  return (
    <div>
      <div className="">
        <h2> company purchased by : </h2>
        <Link color="inherit"  href={`/company/${info.acquirer_company_id}`}>
          <div> {info.acquirer_company_name} </div>
          <div>
            {" "}
            {new Date(info.completion_termination_date).toLocaleDateString(
              "zh-Hans-CN"
            )}
          </div>
        </Link>
      </div>
    </div>
  );
}
