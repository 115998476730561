import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import { DataFilterTable, LoadingAnimation, ChartIcon } from "../../components";
import { getTdDrugData, setDataLoadingAnimation } from "../../Action";
import { get_filter_key, filterDataDrug } from "../../utils";
import numeral from "numeral";

export function AllDrugTable(props) {
  const { filtre, which_btn, action_btn } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const depth = useSelector((state) => state.diease.statusDrugDisplay);
  const date = useSelector((state) => state.config.date);
  const d = useSelector((state) => state.drug.data);
  const disease = useSelector((state) => state.diease.diseaseDisplay);
  const type = useSelector((state) => state.diease.drugTypeDisplay);
  const icons = ["DecreaseIcon","IncreaseIcon","LittleIncreaseIcon"];
  const iconsValueColor = ["#690505","#018539","#045225"];
  const sentimentFontColor = ["#555e68","#1e3628","#690505","#0b361d" ,"#4a0101"];
  const sentimentBackgroundColor = ["#d9d9d9","#94deb4","#f49999","#2abc68", "#fa5252"];
  //const MOADisplay = useSelector ( state => state.diease.MOADisplay);
  const theraFranchise = useSelector(
    (state) => state.diease.theraFranchiseDisplay
  );
  const target = useSelector((state) => state.diease.drugTargetDisplay);
  const load = useSelector((state) => state.config.dataLoading);
  const data = filterDataDrug(
    d,
    depth,
    disease,
    type,
    [],
    theraFranchise,
    target
  ).slice(0, 200);

  useEffect(() => {
    if (filtre !== -1) {
      dispatch(getTdDrugData(date, filtre, 30, action_btn[which_btn]));
      dispatch(setDataLoadingAnimation(true));
    } else {
      dispatch(getTdDrugData(date, -1, 1000, action_btn[which_btn]));
      dispatch(setDataLoadingAnimation(true));
    }
  }, [date, filtre, action_btn, which_btn, dispatch]);

  const go_to = (name, id) => {
    if (name === "drug") {
      navigate(`/drug/${id}`);
    }
    if (name === "company") {
      navigate(`/company/${id}`);
    }
  };
  
  const columns = [
    {
      name: "drug_true_name",
      header: "Drug",
      minWidth: 80,
      maxWidth: 150,
      defaultFlex: 1,
      render: ({ value, data }) => (
        <span className="title-link" onClick={() => go_to("drug", data.id_drug)}>{value}</span>
      ),
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("drug_true_name", data),
      },
      
    },
    {
      name: "company_display_name",
      header: "Company",
      minWidth: 90,
      maxWidth: 180,
      defaultFlex: 1,
      render: ({ value, data }) => (
        <span className="title-link" onClick={() => go_to("company", data.id_company)}>{value}</span>
      ),
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("company_display_name", data),
      },
    },
    {
      name: "disease_display_name",
      header: "Disease",
      defaultFlex: 1,      
      minWidth: 115,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("disease_display_name", data),
      },
    },
    // {
    //   name: "drug_type",
    //   header: "Drug Type",
    //   minWidth: 135,
    //   defaultFlex: 1,
    //   filterEditor: SelectFilter,
    //   filterEditorProps: {
    //     placeholder: "All",
    //     dataSource: get_filter_key("drug_type", data),
    //   },
    // },
    {
      name: "drug_current_phase",
      header: "Phase",
      defaultFlex:1,
      minWidth: 110,
      maxWidth: 130,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("drug_current_phase", data),
      },
    },
    {
      name: "volume_last_week",
      header: "Volume",
      defaultFlex:1,
      minWidth: 60,
      maxWidth: window.innerWidth === 1280 ? 70 : 90,
      type:"number",
      onRender: (cellProps, { data }) => {
        cellProps.style.display = "flex";
        cellProps.style.textAlign = 'center';
      },
      render: ({ value }) => <span> {numeral(value).format("0,0")} </span>
    },
    {
      name: "deviation_volume_last_week_52",
      header: "Trend Score",
      minWidth: 80,
      maxWidth: window.innerWidth === 1280 ? 100 : 120,
      defaultFlex: 1,
      type :"number",
      render: ({ value }) => {       
        return (
          <div className="all-drug-trending-table__trendScore-and-SentimentChange-cells">
            <ChartIcon logo={value > 75 ? icons[1] : (value <= 75 && value >=50 ? icons[2] : icons[0])} />
            <p className= "all-drug-trending-table__trendScore-and-SentimentChange-cells--value"
            style={{ color: value > 75 ? iconsValueColor[1] : (value <= 75 && value >= 50 ? iconsValueColor[2] : iconsValueColor[0]) }}>
              {value}
            </p>
          </div>
        );
      }   
    },  
    {
      name: "sentiement",
      header: "Sentiment",
      minWidth: 110,
      maxWidth: 130,
      defaultFlex: 1,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("sentiement", data),
      },
      render: ({ value }) => {
        return (
          <div className="all-drug-trending-table--sentiment_cells"
            style={{
              color: value === "positive" ? sentimentFontColor[1] : (value === "negative" ? sentimentFontColor[2] : (value === "very postive" ? sentimentFontColor[3] : (value === "very negative" ? sentimentFontColor[4] : sentimentFontColor[0]))) ,
              background: value === "positive" ? sentimentBackgroundColor[1] : (value === "negative" ? sentimentBackgroundColor[2] : (value === "very postive" ? sentimentBackgroundColor[3] : (value === "very negative" ? sentimentBackgroundColor[4] : sentimentBackgroundColor[0]))) ,
            }}
          >
            {value}
          </div>
        );
      },
    },
    {
      name: "deviation_sentiment_last_week_2",
      header: "Sentiment Change",
      minWidth: 90,
      maxWidth: 170,
      defaultFlex: 1,
      type:"number",
      render: ({ value }) => {       
        return (
          <div className="all-drug-trending-table__trendScore-and-SentimentChange-cells">
            <ChartIcon logo={value > 75 ? icons[1] : (value <= 75 && value >=50 ? icons[2] : icons[0])} />
            <p className= "all-drug-trending-table__trendScore-and-SentimentChange-cells--value"
            style={{ color: value > 75 ? iconsValueColor[1] : (value <= 75 && value >= 50 ? iconsValueColor[2] : iconsValueColor[0]) }}>
              {value}
            </p>
          </div>
        );
      }      
    },
  ];
  
  const filterValue = [
    { name: "drug_true_name", operator: "inlist", type: "select", value: "" },
    {
      name: "company_display_name",
      operator: "inlist",
      type: "select",
      value: "",
    },
    {
      name: "disease_display_name",
      operator: "inlist",
      type: "select",
      value: "",
    },
    { name: "drug_type", operator: "inlist", type: "select", value: "" },
    {
      name: "drug_current_phase",
      operator: "inlist",
      type: "select",
      value: "",
    },
    { name: "sentiement", operator: "inlist", type: "select", value: "" },
  ];

  return (
    <div className="all-drug-trending-table">
      {load === false ? (
        <DataFilterTable
          dataSource={data}
          columns={columns}
          filterValue={filterValue}
          defaultFilterName={[]}
        />
      ) : (
        <div className="loading-animation" style={{ height: "100%"}}>
          <LoadingAnimation type={"circular"} />
        </div>
      )}
    </div>
  );
}
