import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  MuiCard,
  PublicationAlertTable,
  RSSAlertTable,
  NCTAlertTable,
  TreatmentAlertTable,
  LrxChips,
} from "../../components";

import {
  getAlertPublications,
  setTabAlertPublicationAnimation,
  getAlertRSS,
  setTabAlertRSSAnimation,
} from "../../Action";

export function AlertPannel() {
  const dispatch = useDispatch();
  const date = useSelector((state) => state.config.date);
  const date_end = new Date(date);
  const date_start = new Date(
    new Date(date).setDate(new Date(date).getDate() - 7)
  );

  const tab_value_filter = [["shortage"], ["CRL", "Complete Response Letter"]];

  useEffect(() => {
    dispatch(getAlertPublications(date));
    dispatch(getAlertRSS(date));
    dispatch(setTabAlertPublicationAnimation(true));
    dispatch(setTabAlertRSSAnimation(true));
  }, [date, dispatch]);

  return (
    <div className="alert-pannel">
      <h2 className="dashboard_title">
        {" "}
        Alerts ({date_start.toLocaleDateString("zh-Hans-CN")} to{" "}
        {date_end.toLocaleDateString("zh-Hans-CN")})
      </h2>
      {tab_value_filter.map((element, index) => (
        <div className="news-and-social-tables" key={index}>
          <h2>Alerts on {element.join(" / ")}</h2>
          <div className="tending-pannel-alert_container">
            <div className="tending-pannel-alert_shared">
              <h2>
                Socials <LrxChips />
              </h2>
              <MuiCard mui_class={""}>
                <PublicationAlertTable defaultFilterName={element} />
              </MuiCard>
            </div>
            <div className="tending-pannel-alert_RSS">
              <h2>
                {" "}
                News <LrxChips />{" "}
              </h2>
              <MuiCard mui_class={""}>
                <RSSAlertTable defaultFilterName={element} />
              </MuiCard>
            </div>
          </div>
        </div>
      ))}
      <div className="tending-pannel-alert_container">
        <div className="tending-pannel-alert_shared">
          <h2 style={{ marginTop: "2%" }}>
            Alerts on Treatment <LrxChips />
          </h2>
          <MuiCard mui_class={""}>
            <TreatmentAlertTable />
          </MuiCard>
          <h2 style={{ marginTop: "3%" }}>
            Alerts on NCT <LrxChips />
          </h2>
          <MuiCard mui_class={""}>
            <NCTAlertTable />
          </MuiCard>
        </div>
      </div>
    </div>
  );
}
