import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import { DataFilterTable, LoadingAnimation } from "../../components";

import { get_filter_key } from "../../utils";
import { getDolRankingByDiseaseData, setdataDOLRankingByDiseaseTabAnimation } from "../../Action";
import numeral from "numeral";

export function DolRankingByDisease() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const date = useSelector((state) => state.config.date);
  const disease = useSelector((state) => state.diease.diseaseDisplay);
  const load = useSelector((state) => state.config.dataDOLRankingByDiseaseTabLoading);
  const data = useSelector((state) => state.dol.dol_ranking_by_disease_tab);
  const id_drug_competive = useSelector((state) => state.competitive.drugChooseForCompetitive);
  const is_drug_competive_empty = id_drug_competive.length === 0;
  const personas = useSelector((state) => state.dol.list_personas);
  
  function list_name_personas(object) {
    const personasSelected = Object.keys(object).filter(persona => object[persona] === true).map(persona => persona.toUpperCase());
    return personasSelected;
  }

  useEffect(() => {
    dispatch(getDolRankingByDiseaseData(disease, date, is_drug_competive_empty, list_name_personas(personas)));
    dispatch(setdataDOLRankingByDiseaseTabAnimation(true));

  }, [dispatch, disease, is_drug_competive_empty, date, personas]);

  const go_to = (name, id) => {
    if (name === "dol_profile") {
      navigate(`/dolprofile/${id}`);
    }
  };
  
  const commonColumns = [
    {
      name: "display_name",
      header: "Disease",
      minWidth: 140,
      flex: 2,
    },
    {
      name: "user_name",
      header: "Author ",
      flex: 2,
      minWidth: 130,
      render: ({ value, data }) => (
        <span
          className="title-link"
          onClick={() => go_to("dol_profile", data.id_dolprofile)}
        >
          {value}
        </span>
      ),
    },
    {
      name: "persona",
      header: "Persona",
      minWidth: 130,
      flex: 1,
    },
    {
      name: "profession_group_display_name",
      header: "Profession group",
      minWidth: 130,
      flex: 3,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("profession_group_display_name", data),
      },
    },
    {
      name: "profession_display_name",
      header: "Profession",
      minWidth: 130,
      flex: 3,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("profession_display_name", data),
      },
    },
    {
      name: "location",
      header: "Location",
      minWidth: 130,
      flex: 1,
    },
    {
      name: "user_followers_count",
      header: "Followers",
      maxWidth: 110,
      onRender: (cellProps, { data }) => {
        cellProps.style.display = "flex";
        cellProps.style.textAlign = 'center';
      },
      render: ({ value }) => <span> {numeral(value).format("0,0")} </span>
    }
  ];

  const columns_dolranking_disease = [
    ...commonColumns,
    {
      name: "nb_tweet",
      header: "Tweets",
      type:"number",
      maxWidth: 100,
      onRender: (cellProps, { data }) => {
        cellProps.style.display = "flex";
        cellProps.style.textAlign = 'center';
      },
    },
  ]

  const columns_tweets = [
    ...commonColumns,
    {
      name: "dol_tweet",
      header: "Tweets",
      type:"number",
      minWidth: 100,
      defaultFlex: 1,
      onRender: (cellProps, { data }) => {
        cellProps.style.display = "flex";
        cellProps.style.textAlign = 'center';
      },
    },    
    {
      name: "tweet_score",
      header: "Retweets",
      type:"number",
      maxWidth: 100,
      onRender: (cellProps, { data }) => {
        cellProps.style.display = "flex";
        cellProps.style.textAlign = 'center';
      },
    },
  ];

  const filterValue = [
    { name: "user_name", operator: "contains", type: "string", value: "" },
  ];
  
  return (
    <div className="dol-ranking-table">
      {load === false ? (
        <DataFilterTable
          dataSource={data}
          columns={is_drug_competive_empty ? columns_dolranking_disease : columns_tweets}
          filterValue={filterValue}
          enableFiltering={true}
          enablePagination={true}
        />
      ) : (
        <div className="dol-ranking-table--loading-animation">
          <LoadingAnimation type={"circular"} />
        </div>
      )}
    </div>
  );
}
