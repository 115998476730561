import React from "react";
import Switch from "@mui/material/Switch";

const colorSecondary = {
  "& .Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#f16739",
  },
  "& .MuiSwitch-track": {
    backgroundColor: "#f16739",
  },
  color: "success.main",
  "& .MuiSwitch-thumb": {
    backgroundColor: "#f16739",
  },
};

export function SliderTwoChoice(props) {
  const { choice, func_choice, choice_text } = props;

  const handleChange = (event) => {
    func_choice(!choice);
  };

  return (
    <span>
     { choice_text[0]}
      <Switch
        color="default"
        checked={choice}
        sx={colorSecondary}
        onChange={handleChange}
      />
     { choice_text[1]}
    </span>
  );
}
