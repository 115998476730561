import { format } from "date-fns";

import { api } from "../config";

export const getDolRankingData =
  (
    profession_group,
    profession,
    location,
    type,
    target,
    theraFranchise,
    disease,
    personas,
    pagination,
    rating
  ) =>
  async (dispatch) => {
    let pg_group = profession_group
      .map(function (el) {
        return el["id_profession_group"];
      })
      .join(";");

    let p_group = profession
      .map(function (el) {
        return el["id_profession"];
      })
      .join(";");

    let personas_group = personas
      .map(function (el) {
        return el;
      })
      .join(";");
    let ratings_group = rating
      .map(function (el) {
        return el;
      })
      .join(";");
    let disease_sent = disease
      .map(function (el) {
        return el["id_disease"];
      })
      .join(";");

    let target_sent = target
      .map(function (el) {
        return el["id_drug_target"];
      })
      .join(";");

    let theraFranchise_sent = theraFranchise
      .map(function (el) {
        return el["id_therapeutic_franchise"];
      })
      .join(";");

    let location_sent = location
      .map(function (el) {
        return el["location"];
      })
      .join(";");

    let type_sent = type
      .map(function (el) {
        return el["id_drug_type"];
      })
      .join(";");

    try {
      const { data } = await api.get("data/dol/ranking", {
        params: {
          personas: personas_group,
          id_speciality: p_group,
          id_profession_group: pg_group,
          disease: disease_sent,
          target: target_sent,
          theraFranchise: theraFranchise_sent,
          type: type_sent,
          location: location_sent,
          pagination: pagination,
          ratings: ratings_group
        },
      });
      dispatch({
        type: "GET_DOL_RANKING_TAB_DATA",
        value: data,
      });
    } catch (err) {
      dispatch({
        type: "ERROR_DATA_DOL",
        value: err,
      });
    }
    dispatch({
      type: "SET_DATA_DOL_RANKING_TAB_LOADING_ANIMATION",
      value: false,
    });
  };

export const getDolRankingByDrugData =
  (drugId, date, period, personas) => async (dispatch) => {
    let personas_group = personas
      .map(function (el) {
        return el;
      })
      .join(";");

    try {
      const { data } = await api.get(`data/dol/ranking/drug/${drugId}`, {
        params: {
          date: format(new Date(date), "yyyy-MM-dd"),
          period: period,
          personas: personas_group,
        },
      });
      dispatch({
        type: "GET_DOL_RANKING_BY_DRUG_TAB_DATA",
        value: data,
      });
    } catch (err) {
      dispatch({
        type: "ERROR_DATA_DOL",
        value: err,
      });
    }
    dispatch({
      type: "SET_DATA_DOL_RANKING_BY_DRUG_TAB_LOADING_ANIMATION",
      value: false,
    });
  };

export const getDolRankingByDiseaseData =
  (listDiseaseId, date, is_drug_competive_empty, personas) =>
  async (dispatch) => {
    let tab_disease_id = listDiseaseId
      .map(function (el) {
        return el["id_disease"];
      })
      .join(";");

    let personas_group = personas
      .map(function (el) {
        return el;
      })
      .join(";");

    try {
      const { data } = await api.get("data/dol/ranking/disease", {
        params: {
          diseaseId: tab_disease_id,
          date: format(new Date(date), "yyyy-MM-dd"),
          is_drug_competive_empty: is_drug_competive_empty,
          personas: personas_group,
        },
      });
      dispatch({
        type: "GET_DOL_RANKING_BY_DISEASE_TAB_DATA",
        value: data,
      });
    } catch (err) {
      dispatch({
        type: "ERROR_DATA_DOL",
        value: err,
      });
    }
    dispatch({
      type: "SET_DATA_DOL_RANKING_BY_DISEASE_TAB_LOADING_ANIMATION",
      value: false,
    });
  };

export const getDolProfileData = (dolprofileId) => async (dispatch) => {
  try {
    const { data } = await api.get(`data/dol/dolprofile/${dolprofileId}`);
    dispatch({
      type: "GET_DOLPROFILE_INFO_DATA",
      value: data[0],
    });
  } catch (err) {
    dispatch({
      type: "ERROR_DATA_DOL",
      value: err,
    });
  }
  dispatch({
    type: "SET_DATA_DOL_PROFILE_LOADING_ANIMATION",
    value: false,
  });
};

export const getDolRankingStatData =
  (
    profession_group,
    my_profession,
    location,
    type,
    target,
    theraFranchise,
    disease,
    personas
  ) =>
  async (dispatch) => {
    let pg_group = profession_group
      .map(function (el) {
        return el["id_profession_group"];
      })
      .join(";");

    let p_group = my_profession
      .map(function (el) {
        return el["id_profession"];
      })
      .join(";");

    let personas_group = personas
      .map(function (el) {
        return el;
      })
      .join(";");

    let disease_sent = disease
      .map(function (el) {
        return el["id_disease"];
      })
      .join(";");

    let target_sent = target
      .map(function (el) {
        return el["id_drug_target"];
      })
      .join(";");

    let theraFranchise_sent = theraFranchise
      .map(function (el) {
        return el["id_therapeutic_franchise"];
      })
      .join(";");

    let location_sent = location
      .map(function (el) {
        return el["location"];
      })
      .join(";");

    let type_sent = type
      .map(function (el) {
        return el["id_drug_type"];
      })
      .join(";");

    try {
      const { data } = await api.get("data/dol/ranking/stats", {
        params: {
          personas: personas_group,
          id_speciality: p_group,
          id_profession_group: pg_group,
          disease: disease_sent,
          target: target_sent,
          theraFranchise: theraFranchise_sent,
          type: type_sent,
          location: location_sent,
        },
      });
      dispatch({
        type: "GET_DOL_RANKING_STATS_DATA",
        value: data,
      });
    } catch (err) {
      dispatch({
        type: "ERROR_DATA_DOL",
        value: err,
      });
    }
    dispatch({
      type: "SET_DATA_DOL_RANKING_STAT_LOADING_ANIMATION",
      value: false,
    });
  };

export const getWorldCloudDolProfileData =
  (dolprofileId, year) => async (dispatch) => {
    try {
      const { data } = await api.get(
        `data/dol/dolprofile/${dolprofileId}/entity`,
        {
          params: {
            year: year,
          },
        }
      );
      dispatch({
        type: "GET_WORLDCLOUD_DOLPROFILE_INFO_DATA",
        value: data,
      });
    } catch (err) {
      dispatch({
        type: "ERROR_DATA_DOL",
        value: err,
      });
    }
    dispatch({
      type: "SET_DATA_DOL_PROFILE_WORLDCLOUD_LOADING_ANIMATION",
      value: false,
    });
  };

export const getGraphicsYearsDolProfileData =
  (dolprofileId) => async (dispatch) => {
    try {
      const { data } = await api.get(
        `data/dol/dolprofile/${dolprofileId}/years`
      );
      dispatch({
        type: "GET_GRAPHICS_YEARS_DOLPROFILE_INFO_DATA",
        value: data,
      });
    } catch (err) {
      dispatch({
        type: "ERROR_DATA_DOL",
        value: err,
      });
    }
    dispatch({
      type: "SET_DATA_DOL_PROFILE_GRAPHICS_LOADING_ANIMATION",
      value: false,
    });
  };

export const set_filter_ranking_dol_user = (value) => async (dispatch) => {
  dispatch({
    type: "SET_RANKING_DOL_USER",
    value: value,
  });
};

export const set_filter_personas_user = (value) => async (dispatch) => {
  dispatch({
    type: "SET_PERSONAS_USER",
    value: value,
  });
};

export const get_all_speciality = () => async (dispatch) => {
  try {
    const { data } = await api.get("data/profession");
    dispatch({
      type: "GET_ALL_SPECIALITY",
      value: data,
    });
  } catch (err) {
    dispatch({
      type: "ERROR_DATA_DOL",
      value: err,
    });
  }
};

export const set_filter_speciality = (value) => async (dispatch) => {
  dispatch({
    type: "SET_SPECIALITY_DOL_USER",
    value: value,
  });
};

export const remove_speciality = (value) => async (dispatch) => {
  dispatch({
    type: "REMOVE_SPECIALITY_DOL_USER",
    value: value,
  });
};

export const get_all_profession_group = () => async (dispatch) => {
  try {
    const { data } = await api.get("data/profession_group");
    dispatch({
      type: "GET_ALL_PROFESSION_GROUP",
      value: data,
    });
  } catch (err) {
    dispatch({
      type: "ERROR_DATA_DOL",
      value: err,
    });
  }
};

export const set_filter_profession_group = (value) => async (dispatch) => {
  dispatch({
    type: "SET_PROFESSION_GROUP_DOL_USER",
    value: value,
  });
};

export const remove_profession_group = (value) => async (dispatch) => {
  dispatch({
    type: "REMOVE_PROFESSION_GROUP_DOL_USER",
    value: value,
  });
};

export const get_all_location = () => async (dispatch) => {
  try {
    const { data } = await api.get("data/location");
    dispatch({
      type: "GET_ALL_LOCATION",
      value: data,
    });
  } catch (err) {
    dispatch({
      type: "ERROR_DATA_DOL",
      value: err,
    });
  }
};

export const set_filter_location = (value) => async (dispatch) => {
  dispatch({
    type: "SET_LOCATION_DOL_USER",
    value: value,
  });
};

export const remove_location = (value) => async (dispatch) => {
  dispatch({
    type: "REMOVE_LOCATION_DOL_USER",
    value: value,
  });
};

export const set_dolprofile_graph_year = (value) => async (dispatch) => {
  dispatch({
    type: "SET_DOLPROFILE_GRAPH_YEAR",
    value: value,
  });
};
