import React from 'react';
import {Avatar} from '@mui/material';
import {Link} from '@mui/material';
import {Box} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import {AuthLoginForm} from '../../layout'

import './style/home_page_style.css';



function Copyright() {
  return (
    <h2 className='title_copiright'>
      {'Copyright © '}
      <Link color="inherit" href="https://lend-rxtech.com/">
      {'2019 Lend-Rx Technology.'}
      </Link> <br/>
      {' All rights reserved'}<br/>
      {'contact@lend-rxtech.com'}
      <p><a href="https://lend-rxtech.com/terms-of-use.html" className="span-terms"><u>Terms of Use</u></a> | <a href="https://lend-rxtech.com/privacy-policy.html" className="span-terms"><u>Privacy Policy</u></a></p>
    </h2>
  );
}

export function LoginPage(){
  return (
    <div className='global_main'>
      <div className='global'>
        <Avatar sx={{ backgroundColor: '#F50057' }}>
          <LockIcon />
        </Avatar>
        <h1> Sign in </h1>
        <AuthLoginForm/>
      <Box mt={8}>
        <Copyright />
      </Box>
      </div>
    </div>
  );
}