import React, { useEffect} from "react";
import { useDispatch , useSelector} from 'react-redux'

import {AreaBarChartVolume, LoadingAnimation} from '..'

import {getCompetitiveGraphByTarget, setcompetitiveGraphTargetLoadingAnimation} from '../../Action'

export function CompetitiveVolumeGraphByTarget(props)  {
  const {is_percentage} = props
    const dispatch = useDispatch();
    const data =  useSelector ( state => state.competitive.competitiveGraphDataByTarget);
    const load = useSelector (state => state.config.graphTargetCompetitiveLoading);

    const depth = useSelector((state) => state.diease.statusDrugDisplay);
    const target= useSelector ( state => state.diease.drugTargetDisplay);
    const disease = useSelector ( state => state.diease.diseaseDisplay);
    const type = useSelector((state) => state.diease.drugTypeDisplay);
    const theraFranchise = useSelector(
      (state) => state.diease.theraFranchiseDisplay
    );


    useEffect(() => {
        dispatch(getCompetitiveGraphByTarget(depth, disease, target, type, theraFranchise));
        dispatch(setcompetitiveGraphTargetLoadingAnimation(true))
      }, [depth, target, disease,type,theraFranchise,  dispatch]);
      
    return (
      <div style={{ height: '40vh', width: "100%" }}>
        {load === false ?
          <AreaBarChartVolume data={data} is_percentage={is_percentage} display_all ={false}/> 
          : (
          <div className="loading-animation" style={{height: "100%"}}>
            <LoadingAnimation type={'linear'}/>
          </div>
          )}    
      </div>
    )
}