import React, { useState } from "react";
import { useSelector } from "react-redux";

import { ControlPannel, TrendingGraphPannel } from "../../layout";
import { GlobalLrxAnimation } from "../../components";

export function TDashboard() {
  const [value, setValue] = useState("DashboardDrug");
  const load = useSelector((state) => state.config.globalMetaDataLoading);
  return (
    <div>
      {!load ? (
        <div className="dashboard">
          <div className="dashboard--pannel">
            <ControlPannel value={value} />
          </div>
          <div className="dashboard--side">
            <TrendingGraphPannel value={value} setValue={setValue} />
          </div>
        </div>
      ) : (
        <div className="dashboard--loading">
          <div className="dashboard--pannel">
            <ControlPannel value={"DrugPage"} />
          </div>
          <div className="global-loading">
            <GlobalLrxAnimation />
          </div>
        </div>
      )}
    </div>
  );
}
