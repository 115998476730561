import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { useLocation } from "react-router-dom";
import { is_departement_is_social } from "../../utils";

import {
  AllDrugTable,
  AllCompanyTable,
  LegendTreemap,
  SliderMetricsDisplay,
  TreeMapTrendingDrug,
  TreeMapTrendingCompany,
  MuiCard,
  LrxChips,
  MuiTabsNavigation,
  VolumeDiseaseGraph,
  VolumeDiseaseTable,
  ModalNeedSocialAccess,
  ChartIcon,
  LrxBtn
} from "../../components";

import { getParamValueTabs } from "../../utils";
import { AlertPannel } from "../../layout";
import "./style/td_graph_pannel.css";
import trending from "../../../Asset/trendingdashboard.png";
import trendingAlert from "../../../Asset/trending-alert.png";
import trendingTicker from "../../../Asset/trending-ticker.png";

function DrugTrendingTabs() {
  const action_btn = ["day", "week", "month", "quarter", "year"];
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const user = useSelector((state) => state.auth.auth_name);
  const monParametreValue = getParamValueTabs(
    "periods",
    params,
    action_btn.length
  );
  const [which_btn, setbtn] = useState(monParametreValue);
  const [is_open, set_open] = useState(false);
  const handleChange = (open) => set_open(open);
  const date = useSelector((state) => state.config.date);

  return (
    <div>
      {is_departement_is_social(user) ? (
        <div className="trending-pannel-container">
          <h2>
            {" "}
            Trending Drugs {new Date(date).toLocaleDateString(
              "zh-Hans-CN"
            )}{" "}
            <LrxChips />{" "}
          </h2>
          <MuiCard mui_class={"dashboard-treemap-card"}>
            <TreeMapTrendingDrug
              filtre={-1}
              setbtn={setbtn}
              which_btn={which_btn}
              action_btn={action_btn}
            />
            <div className="treemap-card-legend-container">
              <div className="treemap-card-legend-container-row">
                <span className="treemap-card-legend">
                  <LegendTreemap />
                </span>
                <span className="treemap-card-slider-metrics">
                  <SliderMetricsDisplay />
                </span>
              </div>
            </div>
            <AllDrugTable
              filtre={-1}
              setbtn={setbtn}
              which_btn={which_btn}
              action_btn={action_btn}
            />
          </MuiCard>
        </div>
      ) : (
        <div className="locked-screen-trending-pannel">
          <ModalNeedSocialAccess more_info={is_open} onChange={handleChange}/>
          <img className="locked-screen-trending-pannel__img" src={trending} alt="background-trending"/>
          <div className="locked-screen-trending-pannel--icon-button">
            <div className="locked-screen-trending-pannel--icon-button__icon">  
              <ChartIcon logo={"LockedIcon"} />
            </div>
            <div className="need-premium-modal__validate">
              <LrxBtn
                action_btn={() => handleChange(true)}
                logo={"ActionSucess"}
                text_btn={"More Info"}
                my_className={"need-premium-modal__validate--btn"}
              /> 
            </div>            
          </div>
        </div>
      )}
    </div>
  );
}

function CompanyTrendingTabs() {
  const action_btn = ["day", "week", "month", "quarter", "year"];
  const [which_btn, setbtn] = useState(0);
  const [is_open, set_open] = useState(false);
  const handleChange = (open) => set_open(open);
  const user = useSelector((state) => state.auth.auth_name);
  const date = useSelector((state) => state.config.date);
  
  return (
    <div>
      {is_departement_is_social(user) ? (
        <div className="trending-pannel-container">
          <h2>
            {" "}
            Trending Tickers {new Date(date).toLocaleDateString("zh-Hans-CN")}
          </h2>
          <MuiCard mui_class={"dashboard-treemap-card"}>
            <TreeMapTrendingCompany
              setbtn={setbtn}
              which_btn={which_btn}
              action_btn={action_btn}
            />
            <div className="treemap-card-legend-container">
              <div className="treemap-card-legend-container-row">
                <LegendTreemap my_className={"treemap-card-legend"} />
                <span className="treemap-card-slider-metrics">
                  <SliderMetricsDisplay />
                </span>
              </div>
            </div>
            <AllCompanyTable which_btn={which_btn} action_btn={action_btn} />
          </MuiCard>
        </div>
      ) : (
        <div className="locked-screen-trending-pannel">
          <ModalNeedSocialAccess more_info={is_open} onChange={handleChange}/>
            <img className="locked-screen-trending-pannel__img" src={trendingTicker} alt="background-trending"/>
            <div className="locked-screen-trending-pannel--icon-button">
              <div className="locked-screen-trending-pannel--icon-button__icon">  
                <ChartIcon logo={"LockedIcon"} />
              </div>
              <div className="need-premium-modal__validate">
                <LrxBtn
                  action_btn={() => handleChange(true)}
                  logo={"ActionSucess"}
                  text_btn={"More Info"}
                  my_className={"need-premium-modal__validate--btn"}
                /> 
              </div>            
            </div>
        </div>
      )}
    </div>
  );
}

function DiseaseTrendingTabs() {
  const action_btn = ["day", "week", "month", "quarter"];
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const monParametreValue = getParamValueTabs(
    "periods",
    params,
    action_btn.length
  );
  const [which_btn, setbtn] = useState(monParametreValue);

  return (
    <div id>
      <h2> Trending Disease</h2>
      <MuiCard mui_class={"dashboard-treemap-card"}>
        <VolumeDiseaseGraph
          setbtn={setbtn}
          which_btn={which_btn}
          action_btn={action_btn}
        />
        <VolumeDiseaseTable which_btn={which_btn} action_btn={action_btn} />
      </MuiCard>
    </div>
  );
}

function AlertTrendingTabs() {
  const [is_open, set_open] = useState(false);
  const handleChange = (open) => set_open(open);
  const user = useSelector((state) => state.auth.auth_name);
  return (
    <div>
      {is_departement_is_social(user) ? (
        <div>
          <AlertPannel />
        </div>
      ) :
      <div className="locked-screen-trending-pannel">
        <ModalNeedSocialAccess more_info={is_open} onChange={handleChange}/>
          <img className="locked-screen-trending-pannel__img" src={trendingAlert} alt="background-trending"/>
          <div className="locked-screen-trending-pannel--icon-button">
            <div className="locked-screen-trending-pannel--icon-button__icon">  
              <ChartIcon logo={"LockedIcon"} />
            </div>
            <div className="need-premium-modal__validate">
              <LrxBtn
                action_btn={() => handleChange(true)}
                logo={"ActionSucess"}
                text_btn={"More Info"}
                my_className={"need-premium-modal__validate--btn"}
              /> 
            </div>            
          </div>
      </div>
      }{" "}
    </div>
  );
}

function TabPanel(props) {
  const { value } = props;
  if (value === "Trending Drug") return <DrugTrendingTabs />;
  if (value === "Trending Ticker") return <CompanyTrendingTabs />;
  if (value === "Trending Disease") return <DiseaseTrendingTabs />;
  if (value === "Alerts") return <AlertTrendingTabs />;
}

export function TrendingGraphPannel(props) {
  const { setValue } = props;
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const type_enum = ["Trending Drug", "Trending Ticker", "Alerts"];
  const monParametreValue = getParamValueTabs("tabs", params, type_enum.length);
  const [tabs_value, set_tabs_value] = useState(monParametreValue);
  const user = useSelector((state) => state.auth.auth_name);

  useEffect(() => {
    if (tabs_value === 1) {
      setValue("DashboardCompany");
    } else {
      setValue("DashboardDrug");
    }
  }, [tabs_value, setValue]);

  return (
    <div className="trending-page">
      <div className="trending-tab-navigation-container">
        <MuiTabsNavigation
          type_enum={type_enum}
          type_of_content={tabs_value}
          setValue={set_tabs_value}
          is_premium_user={is_departement_is_social(user)}
        />
      </div>
      <TabPanel value={type_enum[tabs_value]} />
    </div>
  );
}
