import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import { DataFilterTable, LoadingAnimation } from "..";
import { getNCTDiseaseTab, setNCTDiseaseTabAnimation } from "../../Action";
import { get_filter_key } from "../../utils";

export function NCTDiseaseTable() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const disease = useSelector((state) => state.diease.diseaseDisplay);
  const target = useSelector((state) => state.diease.drugTargetDisplay);
  const data = useSelector((state) => state.drug.NCTTab);
  const load = useSelector((state) => state.config.tabNCTDiseaseLoading);

  const go_to = (name, id) => {
    if (name === "nct") {
      const win = window.open(
        "https://www.clinicaltrials.gov/study/" + id,
        "_blank"
      );
      if (win != null) win.focus();
    }
    if (name === "drug") {
      navigate(`/drug/${id}`);
    }
  };
  useEffect(() => {
    dispatch(getNCTDiseaseTab(disease, target));
    dispatch(setNCTDiseaseTabAnimation(true));
  }, [disease, target, dispatch]);

  const columns = [
    {
      name: "nct",
      header: "NCT Number",
      defaultFlex: 1,
      maxWidth: 134,
      minWidth: 102,
      render: ({ value, data }) => (
        <span className="title-link" onClick={() => go_to("nct", data.nct)}>{value}</span>
      ),
    },
    {
      name: "phase",
      header: "Phase",
      defaultFlex: 1,
      maxWidth: 140,
      minWidth: 110,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("phase", data),
      },
    },
    {
      name: "drug_name",
      header: "Drug",
      defaultFlex: 1,
      minWidth: 115,
      maxWidth: 140,
      render: ({ value, data }) => (
        <span className="title-link" onClick={() => go_to("drug", data.id_drug)}>{value}</span>
      ),
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("drug_name", data),
      },
    },
    {
      name: "original_primary_completion_date",
      header: "Primary Completion Date",
      defaultFlex: 1,
      maxWidth: 208,
      minWidth: 85,
    },
    {
      name: "changes",
      header: "Last Trial Update",
      defaultFlex: 1,
      minWidth: 180,
    },
    {
      name: "primary_completion_date_change",
      header: "Timing Change",
      type:"number",
      defaultFlex: 1,
      maxWidth: 145,
      minWidth: 50,
    },
    {
      name: "original_version_date",
      header: "Date Last Trial Update",
      defaultFlex: 1,
      maxWidth: 185,
      minWidth: 85,
    },
  ];

  const filterValue = [
    { name: "drug_name", operator: "inlist", type: "select", value: "" },
    { name: "status", operator: "inlist", type: "select", value: "" },
    { name: "phase", operator: "inlist", type: "select", value: "" },
    {
      name: "disease_name",
      operator: "inlist",
      type: "select",
      value: "",
    },
  ];

  return (
    <div style={{ height: 600, width: "100%", paddingTop: 30 }}>
      {load === false ? (
        <DataFilterTable
          dataSource={data}
          columns={columns}
          enableFiltering={true}
          filterValue={filterValue}
          defaultFilterName={[]}
        />
      ) : (
        <div className="loading-animation" style={{height: "100%"}}>
          <LoadingAnimation type={"circular"} />
        </div>
      )}
    </div>
  );
}
