const initialState = {
  drugChooseForCompetitive: [],
  competitiveGraphData: [],
  competitiveData: [],
  competitiveGraphDataByTarget: [],
  competitiveGraphDataByCompany: [],
  timeChartcompetitiveData: [],
  competitiveDataNews: [],
  treemap: [],
};

export function getCompetitiveBQ(state = initialState, action) {
  let nextState;
  switch (action.type) {
    case "GET_COMPETITIVE_GRAPH":
      nextState = {
        ...state,
        competitiveGraphData: action.value,
      };
      return nextState || state;

    case "GET_COMPETITIVE_TREEMAP":
      nextState = {
        ...state,
        treemap: action.value,
      };
      return nextState || state;

    case "GET_COMPETITIVE_GRAPH_BY_TARGET":
      nextState = {
        ...state,
        competitiveGraphDataByTarget: action.value,
      };
      return nextState || state;

    case "GET_COMPETITIVE_GRAPH_BY_TYPE":
      nextState = {
        ...state,
        competitiveGraphDataByType: action.value,
      };
      return nextState || state;

    case "GET_COMPETITIVE_GRAPH_BY_COMPANY":
      nextState = {
        ...state,
        competitiveGraphDataByCompany: action.value,
      };
      return nextState || state;

    case "GET_COMPETITIVE_DATA":
      nextState = {
        ...state,
        competitiveData: action.value,
      };
      return nextState || state;

    case "SET_DRUG_COMPETITIVE_CHOOSE":
      nextState = {
        ...state,
        drugChooseForCompetitive: action.value,
      };
      return nextState || state;

    case "GET_COMPETITIVE_TIMECHART_DATA":
      nextState = {
        ...state,
        timeChartcompetitiveData: action.value,
      };
      return nextState || state;

    case "GET_RSS_DISEASE_TAB":
      nextState = {
        ...state,
        competitiveDataNews: action.value,
      };
      return nextState || state;

    case "ERROR_DATA_DRUG":
      return state;

    default:
      return state;
  }
}
