import {api} from '../config'; 

export const getAllDisease= () => async (dispatch) => {
    try {
        const {data} = await api.get("data/disease")
        dispatch({
          type: "GET_ALL_DISEASE",
          value: data,
        })
      }
      catch (err) {
        dispatch({
          type: "ERROR_DATA_DISEASE",
          value: err,
      })
      }
};

export const setMyDisease= (array) => async (dispatch) => {
  dispatch({
    type: "SET_DISEASE_DISPLAY",
    value: array,
  })
}


export const removeMyDisease= (array) => async (dispatch) => {
  dispatch({
    type: "REMOVE_DISEASE_DISPLAY",
    value: array,
  })
}

export const setMyDrug=(id_drug) => async(dispatch) => {
  dispatch({
    type: "SET_DRUG_DISPLAY",
    value: id_drug,
  })
}

export const getAllMOA= () => async (dispatch) => {
  try {
      const {data} = await api.get("data/MOA")
      dispatch({
        type: "GET_ALL_MOA",
        value: data,
      })
    }
    catch (err) {
      dispatch({
        type: "ERROR_DATA_DISEASE",
        value: err,
    })
    }
};

export const setMyMOA= (array) => async (dispatch) => {
  dispatch({
    type: "SET_MOA_DISPLAY",
    value: array,
  })
}


export const getAllDrugType= () => async (dispatch) => {
  try {
      const {data} = await api.get("data/drugType")
      dispatch({
        type: "GET_ALL_DRUG_TYPE",
        value: data,
      })
    }
    catch (err) {
      dispatch({
        type: "ERROR_DATA_DISEASE",
        value: err,
    })
    }
};

export const setMyDrugType= (array) => async (dispatch) => {
  dispatch({
    type: "SET_DRUG_TYPE_DISPLAY",
    value: array,
  })
}

export const removeMyDrugType= (array) => async (dispatch) => {
  dispatch({
    type: "REMOVE_DRUG_TYPE_DISPLAY",
    value: array,
  })
}




export const getAllTheraFranchise = () => async (dispatch) => {
  try {
      const {data} = await api.get("data/theraFranchise")
      dispatch({
        type: "GET_ALL_THERA_FRANCHISE",
        value: data,
      })
    }
    catch (err) {
      dispatch({
        type: "ERROR_DATA_DISEASE",
        value: err,
    })
    }
};

export const setMyTheraFranchise= (array) => async (dispatch) => {
  dispatch({
    type: "SET_THERA_FRANCHISE",
    value: array,
  })
}

export const removeMyTheraFranchise= (array) => async (dispatch) => {
  dispatch({
    type: "REMOVE_THERA_FRANCHISE",
    value: array,
  })
}


export const getAllTarget= () => async (dispatch) => {
  try {
      const {data} = await api.get("data/target")
      dispatch({
        type: "GET_ALL_TARGET",
        value: data,
      })
    }
    catch (err) {
      dispatch({
        type: "ERROR_DATA_DISEASE",
        value: err,
    })
    }
};

export const setMyTarget= (array) => async (dispatch) => {
  dispatch({
    type: "SET_TARGET",
    value: array,
  })
}


export const removeMyTarget= (array) => async (dispatch) => {
  dispatch({
    type: "REMOVE_TARGET",
    value: array,
  })
}

export const resetDisplay= () => async (dispatch) => {
  dispatch({
    type: "RESET_DISPLAY_METADATA",
  })
}


resetDisplay()
