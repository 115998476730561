import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { getAllCompany } from "../../Action";

import { LrxMenuInput } from "../../components";

export function SelectAllCompany(props) {
  const { name, is_filter } = props;

  const nameItem = useSelector((state) => state.company.nameCompany);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [companySelected, setCompanySelected] = useState("");

  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    if (nameItem && nameItem.length === 0) {
      dispatch(getAllCompany());
    }
  }, [dispatch, nameItem]);

  const go_to = (newValue) => {
    navigate(`/company/${newValue.id_company}`);
    if (companySelected !== newValue.company_display_name) {
      setCompanySelected(newValue.company_display_name);
    }
  };

  const ListFiltered = nameItem.filter((user) =>
    user.company_display_name.toLowerCase().includes(searchValue.toLowerCase())
  );

  const listDrug50first = ListFiltered.slice(0, 50);

  return (
    <div className="control-pannel-search-entity">
      <LrxMenuInput action_input={setSearchValue} name={name} />
        <h3 className="control-pannel-search-entity--nb-select-filter">
          {ListFiltered.length === 0
            ? "Company not found !"
            : ListFiltered.length + " companies available"}
        </h3>
      <div className="control-pannel-search-entity__list-object-filter">
        <div class="control-pannel-search-entity__list-object-filter--form">
          <form>
            {listDrug50first.map((item, index) => (
              <div key={index}>
                <label>
                  <input
                    type="checkbox"
                    onChange={() => {
                      go_to(item, is_filter);
                    }}
                    name={item.company_display_name}
                    checked={companySelected.includes(
                      item.company_display_name
                    )}
                  ></input>
                  <span>{item.company_display_name}</span>
                </label>
              </div>
            ))}
          </form>
        </div>
      </div>
    </div>
  );
}
