import React from "react";
import { useSelector } from "react-redux";
import { VerticalBarChart } from "../../components";
import { get_nb_drug_by_target_by_years, interpolateColors } from "../../utils";

function getUniqueKeys(objects) {
  const uniqueKeys = objects.reduce((keys, obj) => {
    const objKeys = Object.keys(obj);
    objKeys.forEach((key) => {
      if (key !== "name" && !keys.includes(key)) {
        keys.push(key);
      }
    });
    return keys;
  }, []);

  return uniqueKeys;
}

export function CompetitiveDetailInactiveByTarget(props) {
  const dataAD = useSelector(
    (state) => state.competitive.timeChartcompetitiveData[1]
  );
  const load = false;

  const set_color = (size) => {
    return interpolateColors(size);
  };

  const tabsYear = ["2016", "2017", "2018", "2019", "2020", "2021", "2022"];
  const transformedArray = [];
  for (const year of tabsYear) {
    const result = get_nb_drug_by_target_by_years(parseInt(year), dataAD);
    const entries = Object.entries(result);
    entries.sort((a, b) => b[1] - a[1]);
    const top15Elements = entries.slice(0, 10);
    const formattedData = top15Elements.reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});
    transformedArray.push(formattedData);
  }

  const datakeys = getUniqueKeys(transformedArray);

  return !load ? (
    <div className="company-graph-pannel__treatement-tabs__details-graph__phase-graph--chart">
      <VerticalBarChart
        data={transformedArray}
        keys={datakeys}
        colors={set_color(datakeys.length)}
      />{" "}
    </div>
  ) : null;
}
