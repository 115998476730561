import React, { useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import Kalend, { CalendarView } from "kalend";
import { startOfDay, endOfDay } from "date-fns";
import "kalend/dist/styles/index.css";

import { getCatalystCompanyTab } from "../../Action";
import { LoadingAnimation } from "../../components";


const color = {
  "PDUFA" : "#B3E2CD",
  "CONGRESS" : "#FDCDAC",
  "TRIAL_COMPLETION" : "#CBD5E8",
  "TRIAL_PRIMARY_COMPLETION" :"#F4CAE4",
  "TRIAL_START" : "#E6F5C9"
}
export function CatalystCompanyCalendar(props) {
  const { id_company } = props;
  const dispatch = useDispatch();
  const date = useSelector((state) => state.config.date);
  const data = useSelector((state) => state.company.catalystTab);
  const load = false;

  useEffect(() => {
    dispatch(getCatalystCompanyTab(id_company, date));
  }, [id_company, date ,dispatch]);

  const RSScompanyCalendar = data.map(function (el, idx) {
    return  {
      id: idx,
      title: el['company_display_name'],
      startAt: startOfDay(new Date(el['catalyst_date'])).toISOString(),
      endAt: endOfDay(new Date(el['catalyst_date'])).toISOString(),
      summary: el['catalyst_type'] +" for " + el['drug_display_name'],
      color: color[el['catalyst_type']],
      calendarID: "work",
    }
  });

  const onNewEventClick = (data) => {
    const msg = `New event click action\n\n Callback data:\n\n${JSON.stringify({
      hour: data.hour,
      day: data.day,
      startAt: data.startAt,
      endAt: data.endAt,
      view: data.view,
      event: "click event ",
    })}`;
    console.log(msg);
  };

  const onEventClick = (data) => {
    const msg = `Click on event action\n\n Callback data:\n\n${JSON.stringify(
      data
    )}`;
    console.log(msg);
  };

  const onEventDragFinish = (data) => {
    console.log(data);
  };

  return (
    <div style={{ height: 500, width: "80%", paddingLeft:"20%"  }}>
      {load === false ? (
        <Kalend
          kalendRef={props.kalendRef}
          onNewEventClick={onNewEventClick}
          initialView={CalendarView.MONTH}
          disabledViews={["threeDays","week","day"]}
          onEventClick={onEventClick}
          events={RSScompanyCalendar}
          initialDate={new Date().toISOString()}
          hourHeight={60}
          timezone={"Europe/Berlin"}
          onEventDragFinish={onEventDragFinish}
          onStateChange={props.onStateChange}
          selectedView={props.selectedView}
          showTimeLine={true}
          isDark={false}
          autoScroll={false}
        />
      ) : (
        <LoadingAnimation type={"circular"} />
      )}
    </div>
  );
}
