import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { IconButton } from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { InfoPannel } from "../../layout";

import { getParamValueTabs, is_departement_is_social } from "../../utils";

import {
  SentimentVolumeDrugGraph,
  TopicsGraph,
  PublicationTable,
  NCTGraph,
  NCTTable,
  RSSTable,
  MuiTabsNavigation,
  MuiCard,
  MuiCollapse,
  BtnCsvExport,
  TweetDisplay,
  LrxChips,
  DiseasePhaseGraph,
  AllDrugCompanyDetailByPhaseLegend,
  ModalNeedSocialAccess,
  ChartIcon,
  LrxBtn,
  DolRankingByDrug,
  SliderTweetsDisplay,
  PersonasGraph,
} from "../../components";

import therapeutics from "../../../Asset/therapeuticsdashboard.png";

function SocialTabs(props) {
  const { drugId, name } = props;
  const action_btn = ["day", "week", "month", "quarter"];
  const location = useLocation();
  const user = useSelector((state) => state.auth.auth_name);
  const params = new URLSearchParams(location.search);
  const monParametreValue = getParamValueTabs(
    "periods",
    params,
    action_btn.length
  );
  const [which_btn, setbtn] = useState(monParametreValue);
  const [is_open, set_open] = useState(false);
  const handleChange = (open) => set_open(open);
  const date = useSelector((state) => state.config.date);
  const [switch_content, set_switch_content] = useState(false);

  return (
    <div className="drug-graph-pannel">
      {is_departement_is_social(user) ? (
        <div>
          <div>
            <h2>Social Engagement and Sentiment : {name}  <LrxChips />{" "}</h2>{" "}
          </div>
          <MuiCard mui_class={""}>
            <SentimentVolumeDrugGraph
              drugId={drugId}
              setbtn={setbtn}
              which_btn={which_btn}
              action_btn={action_btn}
            />
            <RSSTable
              rssValue={0}
              drugId={drugId}
              filter_date={date}
              period={action_btn[which_btn]}
              enableFiltering={false}
              enablePagination={false}
              rsstabclassName={
                "drug-graph-pannel__social-tabs__volume-container__rss-tab"
              }
            />
          </MuiCard>
          <div className="drug-pannel-side">
            <div className="drug-pannel_radar">
              <div>
                <h2>
                  Topics {new Date(date).toLocaleDateString("zh-Hans-CN")}
                </h2>
                <MuiCard mui_class={""}>
                  <TopicsGraph
                    drugId={drugId}
                    which_btn={which_btn}
                    action_btn={action_btn}
                  />
                </MuiCard>
              </div>
            </div>
            <div className="drug-pannel_tabshared">
              <div className="drug-pannel_tabshared--header">
                <h2>
                  {" "}
                  {!switch_content ? "Tweets" : "Shared Content"} {new Date(date).toLocaleDateString("zh-Hans-CN")} {"  "}
                  <LrxChips chips_type={"topics"} drugId={drugId} />
                </h2>
                <SliderTweetsDisplay switch_content={switch_content} set_switch_content={set_switch_content} />
              </div>
              {!switch_content ? (
                <MuiCard mui_class={"drug-pannel_tabshared__mui-card"}>
                  <TweetDisplay drugId={drugId} period={action_btn[which_btn]} />
                </MuiCard>
              ) : (
                <MuiCard mui_class={""}>
                  <PublicationTable
                    drugId={drugId}
                    if_history={false}
                    which_btn={which_btn}
                    action_btn={action_btn}
                  />
                </MuiCard>
              )}
            </div>
          </div>
          <div>
            <h2>
              {" "}
              Drug Tweets Leaders {new Date(date).toLocaleDateString("zh-Hans-CN")} {"  "}
            </h2>
            <div className="drug-pannel_dol_drug">
              <MuiCard mui_class={"dol_drug_muicard"}>
                <div className="graph-dol-drug-container">
                  <DolRankingByDrug drugId={drugId} period={action_btn[which_btn]} />
                  <PersonasGraph/>
                </div>
              </MuiCard>
            </div>
          </div>
        </div>
      ) : (
        <div className="locked-screen-drug-pannel">
          <ModalNeedSocialAccess more_info={is_open} onChange={handleChange} />
          <img
            className="locked-screen-drug-pannel__img"
            src={therapeutics}
            alt="background-therapeutics"
          />
          <div className="locked-screen-drug-pannel--icon-button">
            <div className="locked-screen-drug-pannel--icon-button__icon">
              <ChartIcon logo={"LockedIcon"} />
            </div>
            <div className="need-premium-modal__validate">
              <LrxBtn
                action_btn={() => handleChange(true)}
                logo={"ActionSucess"}
                text_btn={"More Info"}
                my_className={"need-premium-modal__validate--btn"}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function TreatmentsTabs(props) {
  const { drugId, name } = props;
  const [show_active, set_show] = useState(false);
  const [isRotated, setRotated] = useState(false);
  const handleChange = () => {
    set_show(!show_active);
    setRotated(!isRotated);
  };

  return (
    <div className="drug-graph-pannel">
      <h2>Treatments : {name} </h2>
      <MuiCard mui_class={""}>
        <div className="drug-graph-pannel__treatement-tabs__header-graph">
          <h2 className="drug-graph-pannel__treatement-tabs__header-graph--title">
            {" "}
            ACTIVE TREATMENTS
          </h2>
          <BtnCsvExport
            my_className={
              "drug-graph-pannel__treatement-tabs__header-graph--action-btn"
            }
            data_to_export={{ location: "drug", name: "treatment_drug" }}
          />
        </div>
        <DiseasePhaseGraph drugId={drugId} is_active={0} />
        <AllDrugCompanyDetailByPhaseLegend is_nct={false} />
        <div className="drug-graph-pannel__treatement-tabs__header-graph--inactive-treatments">
          <h2 className="drug-graph-pannel__treatement-tabs__header-graph--title">
            INACTIVE TREATMENTS
          </h2>
          <div className="controlPannel-box-expandIcon">
            <IconButton
              className={`controlPannel-box-expandIcon ${
                isRotated ? "rotated" : ""
              }`}
              onClick={() => {
                handleChange();
              }}
            >
              <ExpandMoreIcon />
            </IconButton>
          </div>
        </div>
        <MuiCollapse is_open={show_active}>
          <DiseasePhaseGraph drugId={drugId} is_active={1} />
        </MuiCollapse>
      </MuiCard>
    </div>
  );
}

function ClinicalTabs(props) {
  const { drugId, name } = props;
  return (
    <div className="drug-graph-pannel">
      <h2>Clinical trials : {name}</h2>
      <MuiCard mui_class={""}>
        <AllDrugCompanyDetailByPhaseLegend is_nct={true} />
        <NCTGraph drugId={drugId} />
        <NCTTable drugId={drugId} if_history={true} />
      </MuiCard>
    </div>
  );
}

function NewsTabs(props) {
  const { drugId, name } = props;
  return (
    <div className="drug-graph-pannel">
      <h2>Press Releases</h2>
      <MuiCard mui_class={""}>
        <RSSTable
          rssValue={1}
          drugId={drugId}
          name={name}
          rsstabclassName={"drug-graph-pannel__news-tabs__rss-tab"}
        />
      </MuiCard>
    </div>
  );
}

function TabPanel(props) {
  const { value, drugId } = props;
  const info = useSelector((state) => state.drug.drugInfo);
  const name = info.drugInfo ? info.drugInfo.drug_true_name : null;
  if (value === "Social") return <SocialTabs drugId={drugId} name={name} />;
  if (value === "Treatments")
    return <TreatmentsTabs drugId={drugId} name={name} />;
  if (value === "Clinical trial")
    return <ClinicalTabs drugId={drugId} name={name} />;
  if (value === "News") return <NewsTabs drugId={drugId} name={name} />;
  return null;
}

export function DrugGraphPannel(props) {
  const { drugId } = props;
  const type_enum = ["Social", "Treatments", "Clinical trial", "News"];
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const monParametreValue = getParamValueTabs("tabs", params, type_enum.length);
  const [type_of_content, setValue] = useState(monParametreValue);
  const user = useSelector((state) => state.auth.auth_name);

  if (drugId !== "-1") {
    return (
      <div className="drug-graph-pannel--page">
        <InfoPannel drugId={drugId} value={0} type_of_content={type_of_content}   />
        <div className="drug-graph-pannel--page--info">
          <div className="drug-graph-pannel--page--info__tab-navigation-container">
            <MuiTabsNavigation
              type_enum={type_enum}
              type_of_content={type_of_content}
              setValue={setValue}
              is_premium_user={is_departement_is_social(user)}
            />
          </div>
          <TabPanel value={type_enum[type_of_content]} drugId={drugId} />
        </div>
      </div>
    );
  } else return null;
}
