import React from "react";
import { ModalNeedPremiumAccount, ModalDownloadCsv } from "../../../components";
import { useSelector } from "react-redux";
import { is_group_is_premium, filterDataDrug } from "../../../utils";

const export_name = {
  treatment_company: "allDrug",
  treatment_drug: "TreatmentGraph",
  treatment_competitive: "timeChartcompetitiveData",
  drug_company: "allDrugTab",
  all_drug: "nameDrug",
  RSS_company: "RSSTab",
  catalyst_company: "catalystTab",
  nct_company: "nctCompanyTab",
  nct_competitive: "NCTTab",
  RSS_competitive: "competitiveDataNews",
};

const exclude_column = {
  treatment_company: ["2", "5", "7", "9", "11", "12"],
  treatment_drug: ["4"],
  treatment_competitive: ["2", "5", "7", "9", "11", "12"],
  drug_company: [
    "id_drug",
    "drug_display_name",
    "drug_brand_name",
    "id_disease",
    "id_therapeutic_franchise",
    "id_target",
    "id_company_lead",
    "id_company",
    "id_company_partner",
    "id_drug_type",
    "PHASE_ORDER",
    "id",
  ],
  all_drug: [
    "id_drug",
    "id_disease",
    "id_therapeutic_franchise",
    "id_target",
    "id_company_lead",
    "id_company_partner",
    "id_partner_company",
    "id_company",
    "id_drug_type",
    "id",
  ],
  RSS_company: [
    "id_disease",
    "id_target",
    "id_therapeutic_franchise",
    "id_drug_type",
    "id_company_lead",
    "id_company_partner",
    "id_drug",
    "id",
  ],
  catalyst_company: ["id_drug", "id_company", "id"],
  nct_company: [
    "id_disease",
    "id_treatment_map",
    "id_drug",
    "company_id_lead",
    "company_id_partner",
    "id_therapeutic_franchise",
    "name",
    "id",
  ],
  RSS_competitive: [
    "id_disease",
    "id_target",
    "id_therapeutic_franchise",
    "id_drug_type",
    "id_company_lead",
    "id_company_partner",
    "id_drug",
    "id",
  ],
  nct_competitive: [
    "id_disease",
    "id_treatment_map",
    "id_drug",
    "company_id_lead",
    "company_id_partner",
    "id_therapeutic_franchise",
    "name",
    "id",
    "id_drug_target",
  ],
};

const is_array_type = {
  treatment_company: true,
  treatment_drug: true,
  treatment_competitive: true,
  drug_company: false,
  all_drug: false,
  RSS_company: false,
  catalyst_company: false,
  nct_company: false,
  RSS_competitive: false,
  nct_competitive: false,
};

const need_filter = {
  treatment_company: false,
  treatment_drug: false,
  treatment_competitive: false,
  drug_company: false,
  all_drug: true,
  RSS_company: false,
  catalyst_company: false,
  nct_company: false,
  RSS_competitive: false,
  nct_competitive: false,
};

const rename_column = {
  treatment_company: {
    0: "DRUG NAME",
    1: "PHASE",
    3: "START_DATE",
    4: "END_DATE",
    6: "DISEASE",
    8: "TYPE",
    10: "THERAPEUTIC FRANCHISE",
    13: "TARGET",
  },
  treatment_drug: {
    0: "DISEASE",
    1: "PHASE",
    2: "START_DATE",
    3: "END_DATE",
  },
  treatment_competitive: {
    0: "DRUG NAME",
    1: "PHASE",
    3: "START_DATE",
    4: "END_DATE",
    6: "DISEASE",
    8: "TYPE",
    10: "THERAPEUTIC FRANCHISE",
    13: "TARGET",
  },
  drug_company: {},
  all_drug: {},
  RSS_company: {},
  catalyst_company: {},
  nct_company: {},
  RSS_competitive: {},
  nct_competitive: {},
};

export function BtnCsvExport(props) {
  const { my_className, data_to_export } = props;
  const depth = useSelector((state) => state.diease.statusDrugDisplay);
  const disease = useSelector((state) => state.diease.diseaseDisplay);
  const target = useSelector((state) => state.diease.drugTargetDisplay);
  const type = useSelector((state) => state.diease.drugTypeDisplay);
  const theraFranchise = useSelector(
    (state) => state.diease.theraFranchiseDisplay
  );

  const is_array = is_array_type[data_to_export.name];

  let datakeep = useSelector((state) =>
    is_array
      ? state[data_to_export.location][export_name[data_to_export.name]][0]
        ? state[data_to_export.location][
            export_name[data_to_export.name]
          ][0].slice(1)
        : []
      : state[data_to_export.location][export_name[data_to_export.name]]
  );

  if (need_filter[data_to_export.name]) {
    datakeep = filterDataDrug(
      datakeep,
      depth,
      disease,
      type,
      [],
      theraFranchise,
      target
    );
  }

  const excludedColumns = exclude_column[data_to_export.name];
  const columnNames = rename_column[data_to_export.name];
  const filteredData = datakeep
    ? datakeep.map((item) => {
        const filteredItem = {};
        Object.entries(item).forEach(([key, value]) => {
          if (!excludedColumns.includes(key)) {
            const newKey = columnNames[key] ? columnNames[key] : key;
            filteredItem[newKey] = value;
          }
        });
        return filteredItem;
      })
    : [];

  const data_keys = filteredData[0] ? Object.keys(filteredData[0]) : [];
  const user = useSelector((state) => state.auth.auth_name);
  return (
    <div className={`${my_className}`}>
      {is_group_is_premium(user) ? (
        <ModalDownloadCsv
          logo={"saveIcon"}
          text_btn={"export to excel"}
          data_keys={data_keys}
          name_csv={data_to_export.name}
          data_to_export={filteredData}
        />
      ) : (
        <ModalNeedPremiumAccount
          logo={"saveIcon"}
          text_btn={"export to excel"}
        />
      )}
    </div>
  );
}
