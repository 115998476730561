export const setGlobalLoadingAnimation = (bool) => async (dispatch) => {
  dispatch({
    type: "SET_GLOBAL_LOADING",
    value: bool,
  });
};

export const setTMLoadingAnimation = (bool) => async (dispatch) => {
  dispatch({
    type: "SET_TM_LOADING_ANIMATION",
    value: bool,
  });
};

export const setTMTTLoadingAnimation = (bool) => async (dispatch) => {
  dispatch({
    type: "SET_TMTT_LOADING_ANIMATION",
    value: bool,
  });
};

export const setTMCompetDrugLoadingAnimation = (bool) => async (dispatch) => {
  dispatch({
    type: "SET_COMPETITIVE_TREEMAP_LOADING_ANIMATION",
    value: bool,
  });
};

export const setDataLoadingAnimation = (bool) => async (dispatch) => {
  dispatch({
    type: "SET_DATA_LOADING_ANIMATION",
    value: bool,
  });
};

export const setVolumeDiseaseTabLoadingAnimation =
  (bool) => async (dispatch) => {
    dispatch({
      type: "SET_VOLUME_DISEASE_TAB_LOADING_ANIMATION",
      value: bool,
    });
  };

export const setDataTTLoadingAnimation = (bool) => async (dispatch) => {
  dispatch({
    type: "SET_DATA_TT_LOADING_ANIMATION",
    value: bool,
  });
};

export const setGraphLoadingAnimation = (bool) => async (dispatch) => {
  dispatch({
    type: "SET_GRAPH_LOADING_ANIMATION",
    value: bool,
  });
};

export const setGraphRadarLoadingAnimation = (bool) => async (dispatch) => {
  dispatch({
    type: "SET_GRAPH_RADAR_LOADING_ANIMATION",
    value: bool,
  });
};

export const setTabshareAnimation = (bool) => async (dispatch) => {
  dispatch({
    type: "SET_TAB_SHARE_LOADING_ANIMATION",
    value: bool,
  });
};

export const setBigTabshareAnimation = (bool) => async (dispatch) => {
  dispatch({
    type: "SET_BIG_TAB_SHARE_LOADING_ANIMATION",
    value: bool,
  });
};

export const setTabAlertPublicationAnimation = (bool) => async (dispatch) => {
  dispatch({
    type: "SET_ALERT_PUBLICATION_LOADING_ANIMATION",
    value: bool,
  });
};

export const setTabAlertRSSAnimation = (bool) => async (dispatch) => {
  dispatch({
    type: "SET_ALERT_RSS_LOADING_ANIMATION",
    value: bool,
  });
};

export const setGraphDrugLoadingAnimation = (bool) => async (dispatch) => {
  dispatch({
    type: "SET_GRAPH_DRUG_LOADING_ANIMATION",
    value: bool,
  });
};

export const setInfoLoadingAnimation = (bool) => async (dispatch) => {
  dispatch({
    type: "SET_INFO_LOADING_ANIMATION",
    value: bool,
  });
};

export const setCompanyDrugTabLoadingAnimation = (bool) => async (dispatch) => {
  dispatch({
    type: "SET_TAB_COMPANY_DRUG_LOADING_ANIMATION",
    value: bool,
  });
};

export const setGraphCompetitiveDataDrugLoadingAnimation =
  (bool) => async (dispatch) => {
    dispatch({
      type: "SET_GRAPH_COMPETITIVE_DATA_DRUG_LOADING_ANIMATION",
      value: bool,
    });
  };

export const setCompanyDrugGraphLoadingAnimation =
  (bool) => async (dispatch) => {
    dispatch({
      type: "SET_GRAPH_DRUG_COMPANY_LOADING_ANIMATION",
      value: bool,
    });
  };

export const setCongressTDLoadingAnimation = (bool) => async (dispatch) => {
  dispatch({
    type: "SET_TAB_CONGRESS_DRUG_LOADING_ANIMATION",
    value: bool,
  });
};

export const setTMCongressLoadingAnimation = (bool) => async (dispatch) => {
  dispatch({
    type: "SET_TM_CONGRESS_LOADING_ANIMATION",
    value: bool,
  });
};

export const setcompetitiveDataLoadingAnimation =
  (bool) => async (dispatch) => {
    dispatch({
      type: "SET_COMPETITIVE_DATA_LOADING_ANIMATION",
      value: bool,
    });
  };

export const setcompetitiveGraphLoadingAnimation =
  (bool) => async (dispatch) => {
    dispatch({
      type: "SET_COMPETITIVE_GRAPH_LOADING_ANIMATION",
      value: bool,
    });
  };

export const setcompetitiveGraphTargetLoadingAnimation =
  (bool) => async (dispatch) => {
    dispatch({
      type: "SET_COMPETITIVE_GRAPH_TARGET_LOADING_ANIMATION",
      value: bool,
    });
  };

export const setcompetitiveGraphTypeLoadingAnimation =
  (bool) => async (dispatch) => {
    dispatch({
      type: "SET_COMPETITIVE_GRAPH_TYPE_LOADING_ANIMATION",
      value: bool,
    });
  };

export const setcompetitiveGraphCompanyLoadingAnimation =
  (bool) => async (dispatch) => {
    dispatch({
      type: "SET_COMPETITIVE_GRAPH_COMPANY_LOADING_ANIMATION",
      value: bool,
    });
  };

export const setTabCatalystCompetitiveLoadingAnimation =
  (bool) => async (dispatch) => {
    dispatch({
      type: "SET_TAB_CATALYST_COMPETITIVE_LOADING_ANIMATION",
      value: bool,
    });
  };

export const setNCTTreatmentDetailsGraphLoadingAnimation =
  (bool) => async (dispatch) => {
    dispatch({
      type: "SET_NCT_TREATMENT_DETAILS_GRAPH_LOADING_ANIMATION",
      value: bool,
    });
  };

export const setNCTGraphAnimation = (bool) => async (dispatch) => {
  dispatch({
    type: "SET_NCT_GRAPH_LOADING_ANIMATION",
    value: bool,
  });
};

export const setNCTDiseaseGraphAnimation = (bool) => async (dispatch) => {
  dispatch({
    type: "SET_GRAPH_NCT_DISEASE_LOADING_ANIMATION",
    value: bool,
  });
};

export const setNCTCompanyGraphAnimation = (bool) => async (dispatch) => {
  dispatch({
    type: "SET_NCT_COMPANY_GRAPH_LOADING_ANIMATION",
    value: bool,
  });
};

export const setNCTTabAnimation = (bool) => async (dispatch) => {
  dispatch({
    type: "SET_NCT_TAB_LOADING_ANIMATION",
    value: bool,
  });
};

export const setNCTCompanyTabAnimation = (bool) => async (dispatch) => {
  dispatch({
    type: "SET_NCT_COMPANY_TAB_LOADING_ANIMATION",
    value: bool,
  });
};

export const setNCTDiseaseTabAnimation = (bool) => async (dispatch) => {
  dispatch({
    type: "SET_NCT_DISEASE_TAB_LOADING_ANIMATION",
    value: bool,
  });
};

export const setAlertNCTTabAnimation = (bool) => async (dispatch) => {
  dispatch({
    type: "SET_ALERT_NCT_TAB_LOADING_ANIMATION",
    value: bool,
  });
};

export const setGraphPhaseDrugAnimation = (bool) => async (dispatch) => {
  dispatch({
    type: "SET_GRAPH_PHASE_DRUG_LOADING_ANIMATION",
    value: bool,
  });
};

export const setGraphTreatmentAnimation = (bool) => async (dispatch) => {
  dispatch({
    type: "SET_GRAPH_TREATMENT_LOADING_ANIMATION",
    value: bool,
  });
};

export const setRSSTabAnimation = (bool) => async (dispatch) => {
  dispatch({
    type: "SET_RSS_TAB_LOADING_ANIMATION",
    value: bool,
  });
};

export const setRSSCompanyTabAnimation = (bool) => async (dispatch) => {
  dispatch({
    type: "SET_RSS_COMPANY_TAB_LOADING_ANIMATION",
    value: bool,
  });
};

export const setRSSDiseaseLoadingAnimation = (bool) => async (dispatch) => {
  dispatch({
    type: "SET_RSS_DISEASE_LOADING_ANIMATION",
    value: bool,
  });
};

export const setDrugDynamicSearchLoadingAnimation = (bool) => (dispatch) => {
  dispatch({
    type: "SET_DRUG_DYNAMIC_SEARCH_LOADING_ANIMATION",
    value: bool,
  });
};

export const setCongressAllCardsLoadingAnimation = (bool) => (dispatch) => {
  dispatch({
    type: "SET_CONGRESS_ALL_CARDS_LOADING_ANIMATION",
    value: bool,
  });
};

export const setCompanyNCTHistoByPhaseLoadingAnimation =
  (bool) => async (dispatch) => {
    dispatch({
      type: "SET_DETAILS_COMPANY_GRAPH_ANIMATION",
      value: bool,
    });
  };

export const setGraphPhaseCompanyLoadingAnimation =
  (bool) => async (dispatch) => {
    dispatch({
      type: "SET_GRAPH_PHASE_COMPANY_LOADING_ANIMATION",
      value: bool,
    });
  };

export const setCompanyCatalystTabLoadingAnimation =
  (bool) => async (dispatch) => {
    dispatch({
      type: "SET_COMPANY_CATALYST_TAB_LOADING_ANIMATION",
      value: bool,
    });
  };

export const setTreatmentTabLoadingAnimation = (bool) => async (dispatch) => {
  dispatch({
    type: "SET_TREATMENT_TAB_LOADING_ANIMATION",
    value: bool,
  });
};

export const setdrugAllCongressLoadingAnimation =
  (bool) => async (dispatch) => {
    dispatch({
      type: "SET_DRUG_CONFERENCE_ANIMATION",
      value: bool,
    });
  };

export const setWorldCloudDataAnimation = (bool) => (dispatch) => {
  dispatch({
    type: "SET_WROLD_CLOUD_DRUG_DISEASE_LOADING_ANIMATION",
    value: bool,
  });
};

export const setTweetDataAnimation = (bool) => (dispatch) => {
  dispatch({
    type: "SET_DRUG_TWEET_DATA_LOADING_ANIMATION",
    value: bool,
  });
};

export const setdataDOLRankingTabAnimation = (bool) => (dispatch) => {
  dispatch({
    type: "SET_DATA_DOL_RANKING_TAB_LOADING_ANIMATION",
    value: bool,
  });
};

export const setdataDOLRankingStatAnimation = (bool) => (dispatch) => {
  dispatch({
    type: "SET_DATA_DOL_RANKING_STAT_LOADING_ANIMATION",
    value: bool,
  });
};

export const setdataDOLRankingByDrugTabAnimation = (bool) => (dispatch) => {
  dispatch({
    type: "SET_DATA_DOL_RANKING_BY_DRUG_TAB_LOADING_ANIMATION",
    value: bool,
  });
};

export const setdataDOLRankingByDiseaseTabAnimation = (bool) => (dispatch) => {
  dispatch({
    type: "SET_DATA_DOL_RANKING_BY_DISEASE_TAB_LOADING_ANIMATION",
    value: bool,
  });
};

export const setdataDOLProfileAnimation = (bool) => (dispatch) => {
  dispatch({
    type: "SET_DATA_DOL_PROFILE_LOADING_ANIMATION",
    value: bool,
  });
};

export const setdataDOLProfileWorldCloudAnimation = (bool) => (dispatch) => {
  dispatch({
    type: "SET_DATA_DOL_PROFILE_WORLDCLOUD_LOADING_ANIMATION",
    value: bool,
  });
};

export const setdataDOLProfileGraphicsAnimation = (bool) => (dispatch) => {
  dispatch({
    type: "SET_DATA_DOL_PROFILE_GRAPHICS_LOADING_ANIMATION",
    value: bool,
  });
};
