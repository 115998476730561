import React, { useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux'

import {setGraphRadarLoadingAnimation, getDataTopics} from '../../Action'
import {LoadingAnimation ,PieChartTopics} from '../../components'

export function TopicsGraph(props) {
  const {drugId, which_btn, action_btn} = props
  const dispatch = useDispatch();
  const date = useSelector (state => state.config.date);
  const load = useSelector (state => state.config.radarDrugLoading);
  const data = useSelector (state => state.drug.topics);
  const personas = useSelector((state) => state.dol.list_personas);
  
  const foundKey = Object.keys(personas).find(key => personas[key] === true);
  const persona_name_selected = foundKey ? foundKey.toUpperCase() : null;
  const is_personas_selected = Object.values(personas).some(value => value === true);  

  useEffect(() => {
    if (drugId !== "-1") {
      if (is_personas_selected){
        dispatch(getDataTopics(date, drugId, action_btn[which_btn], persona_name_selected))
        dispatch(setGraphRadarLoadingAnimation(true))
      } else {
        dispatch(getDataTopics(date, drugId, action_btn[which_btn], 'NULL'))
        dispatch(setGraphRadarLoadingAnimation(true))  
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, drugId, action_btn[which_btn], personas, dispatch]);

  return (
    <div style={{ width: "100%", height:"598px", position:"relative" }}>
    {load === false ?
      <PieChartTopics data={data} fontSize={13} innerRadius={140} outerRadius={200} displayName={true}/> 
      :
      <div className="loading-animation" style={{height:"100%"}}>
        <LoadingAnimation type={'circular'}/>
      </div>
    }
  </div>
  );
}