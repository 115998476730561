import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { setDateRange } from "../../../../Action";

const datePickercss = {
  ".MuiInputBase-root-MuiOutlinedInput-root": {
    "border-color": "none",
  },
  ".MuiInput-underline:hover:not(.Mui-disabled):before": {
    border: "none",
  },
};

export function DatePickerMonth() {
  const date = useSelector((state) => state.config.date);
  const [selectedDate, handleDateChange] = useState(date);
  const dispatch = useDispatch();

 // function setToMonday(date) {
 //   var day = date.getDay() || 7;
 //   if (day !== 0) date.setHours(-24 * (day - 0));
 //   return date;
 // }

  useEffect(() => {
    dispatch(setDateRange(selectedDate));
  }, [selectedDate, dispatch]);

  return (
    <div className="control-pannel-date-picker">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          srx={datePickercss}
          label="-"
          value={date}
          onChange={handleDateChange}
          minDate={new Date("2016-01-04")}
          maxDate={new Date("2024-10-30")/*setToMonday(new Date())*/}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
    </div>
  );
}
