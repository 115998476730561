import React from "react";

import './style/legend_company_details.css';

export function AllDrugCompanyDetailByPhaseLegend(props){
  const {is_nct} = props

      return (
        <ul className='ul_chart_phases_and_targets'>
        <li>
          <div className='container'>
          <div className="carre" style={{'backgroundColor' :"#ad345c"}}> </div>
          <div style={{'paddingLeft': '5px'}}>Phase 1</div> 
          </div>
        </li>
  
        <li>
          <div className='container'>
          <div className="carre" style={{'backgroundColor' :"#e2823d"}}> </div>
          <div style={{'paddingLeft': '5px'}}>Phase 2</div> 
          </div>
        </li>
  
        <li>
          <div className='container'>
          <div className="carre" style={{'backgroundColor' :"#27789E"}}> </div>
          <div style={{'paddingLeft': '5px'}}>Phase 3</div> 
          </div>
        </li>
  
       { !is_nct ? <li>
          <div className='container'>
          <div className="carre" style={{'backgroundColor' :"#2B6530"}}> </div>
          <div style={{'paddingLeft': '5px'}}>Nda/bla</div> 
          </div>
        </li>: null }
  
        { !is_nct ?<li>
          <div className='container'>
          <div className="carre" style={{'backgroundColor' :"#59AB46"}}> </div>
          <div style={{'paddingLeft': '5px'}}>Approved</div> 
          </div>
        </li> : null}
      </ul>
      );
}