import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { MuiModal, DataFilterTable } from "../../../components";
import { get_info_by_nct } from "../../../Action";

export function ModalInfoNCT(props) {
  const { open, handleClose, nct } = props;
  const dispatch = useDispatch();
  const NCTInfo = useSelector((state) => state.drug.infoByNCT);

  const arrayRSS = NCTInfo
    ? NCTInfo.map(function (el, index) {
        return {
          id: index,
          link: el["link"],
          sentiment: el["clinical_trial_sentiment"],
          published_at: el["published_at"],
        };
      }).filter((item) => item.link !== null)
    : [];

  const go_to = (name, id) => {
    if (name === "nct") {
      const win = window.open(
        "https://clinicaltrials.gov/ct2/show/" + id,
        "_blank"
      );
      if (win != null) win.focus();
    }
    if (name === "link") {
      const win = window.open(id, "_blank");
      if (win != null) win.focus();
    }
  };

  const columns = [
    {
      name: "published_at",
      header: "published_at",
      defaultFlex: 1,
      minWidth: 75,
      maxWidth: 100,
    },
    {
      name: "link",
      header: "link RSS",
      defaultFlex: 1,
      minWidth: 400,
      maxWidth: 500,
      render: ({ value }) => (
        <span onClick={() => go_to("link", value)}>{value}</span>
      ),
    },
    {
      name: "sentiment",
      header: "sentiment",
      defaultFlex: 1,
      minWidth: 60,
      maxWidth: 60,
    },
  ];

  useEffect(() => {
    if (open === true) {
      dispatch(get_info_by_nct(nct));
    }
  }, [open, nct, dispatch]);

  return NCTInfo[0] ? (
    <div>
      <MuiModal open={open} onClose={handleClose}>
        <div className="info-rss-modal">
          <div className="info-rss-modal--header">
            <h2 onClick={() => go_to("nct", NCTInfo[0].nct)}>
              <span> {NCTInfo[0].nct}</span>
            </h2>
            {NCTInfo[0].acronym ? <h4>{NCTInfo[0].acronym} Trial </h4> : null}
          </div>
          <div className="info-rss-modal--kpi">
            <div className="info-rss-modal--kpi-item">
              <h3>Trial Details</h3>
              <ul>
                <li>
                  {" "}
                  <span>Date</span> : {NCTInfo[0].start_date} to{" "}
                  {NCTInfo[0].original_expected_completion_date}
                </li>
                <li>
                  {" "}
                  <span>primary completion date</span> :{" "}
                  {NCTInfo[0].original_primary_completion_date}
                </li>
                <li>
                  {" "}
                  <span>Time Frame</span> :{" "}
                  {NCTInfo[0].primary_outcome_time_frame}
                </li>
                <li>
                  {" "}
                  <span>primary endpoint </span> :{" "}
                  {NCTInfo[0].primary_outcome_endpoint}
                </li>
                <li>
                  <span>Cohort</span> : {NCTInfo[0].enrollment}
                </li>
                <li>
                  <span>Age : </span>
                  {NCTInfo[0].enrollment_age}
                </li>
                <li>
                  <span>Nb of center : </span>
                  {NCTInfo[0].locations_number}
                </li>
              </ul>
            </div>
            <div className="info-rss-modal--kpi-item">
              <h3>KPIsDrug</h3>
              <ul>
                <li>
                  <span>Drug approved :</span>{" "}
                  {NCTInfo[0].product_already_approved ? "True" : "False"}
                </li>
                <li>
                  <span>Status :</span> {NCTInfo[0].status}
                </li>
                <li>
                  <span>Phase :</span> {NCTInfo[0].phase}
                </li>
              </ul>
            </div>
          </div>
          <div className="info-rss-modal--rss-tab">
            <h3>Trial Results</h3>
            <div>Press Release</div>
            {arrayRSS.length !== 0 ? (
              <div className="rss-tab-content">
                <DataFilterTable
                  dataSource={arrayRSS}
                  columns={columns}
                  enableFiltering={true}
                  filterValue={[]}
                  defaultFilterName={[]}
                />
              </div>
            ) : (
              "No Press Release"
            )}
          </div>
        </div>
      </MuiModal>
    </div>
  ) : null;
}
