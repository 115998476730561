import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getDataTreeMapTT, setTMTTLoadingAnimation } from "../../Action";
import {
  TreeMapSentiment,
  MUItoogleBtn,
  LoadingAnimation,
} from "./../../components";

export function TreeMapTrendingCompany(props) {
  const { filtre, setbtn, action_btn, which_btn } = props;
  const dispatch = useDispatch();
  const data = useSelector((state) => state.company.treemap);
  const date = useSelector((state) => state.config.date);
  const load = useSelector((state) => state.config.treemapTTLoading);
  const depth = useSelector((state) => state.diease.statusCompanyDisplay);
  const metric1 = useSelector((state) => state.config.TDmetric1);
  const metric2 = useSelector((state) => state.config.TDmetric2);

  useEffect(() => {
    dispatch(
      getDataTreeMapTT(date, 50, metric1, metric2, action_btn[which_btn])
    );
    dispatch(setTMTTLoadingAnimation(true));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, filtre, metric1, metric2, action_btn[which_btn], dispatch]);

  const setAllFilter = (data) => {
    return data.filter(
      (e) => e.name === depth.find((element) => element === e.name)
    );
  };

  return (
    <div className="tree-map-trending-ticker">
      <div style={{ paddingBottom: "5px"}}>
        <MUItoogleBtn
          action_btn={action_btn}
          setbtn={setbtn}
          which_btn={which_btn}
        />
      </div>
      {load === false ? (
        <div>
          <TreeMapSentiment data={setAllFilter(data)} filter={"navigation"} />
        </div>
      ) : (
        <div className="loading-animation" style={{ height: "350px" }}>
          <LoadingAnimation type={"circular"} />
        </div>
      )}
    </div>
  );
}
