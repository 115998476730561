import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { TimeLineChart, LoadingAnimation } from "../../components";
import { filterDataDrugWithCondition } from "../../utils";

import {
  getAllCompanyDrugGraph,
  setCompanyDrugGraphLoadingAnimation,
} from "../../Action";

export function AllDrugCompanyGraph(props) {
  const { filtre, is_active } = props;
  const dispatch = useDispatch();
  const dataAD = useSelector((state) => state.company.allDrug[is_active]);
  const load = useSelector((state) => state.config.graphDrugCompanyLoading);

  const depth = useSelector((state) => state.diease.statusDrugDisplay);
  const disease = useSelector((state) => state.diease.diseaseDisplay);
  const type = useSelector((state) => state.diease.drugTypeDisplay);
  const MOADisplay = useSelector((state) => state.diease.MOADisplay);
  const theraFranchise = useSelector(
    (state) => state.diease.theraFranchiseDisplay
  );
  const targetDisplay = useSelector((state) => state.diease.drugTargetDisplay);

  const filterData = dataAD
    ? filterDataDrugWithCondition(
        dataAD,
        depth,
        disease,
        type,
        MOADisplay,
        theraFranchise,
        targetDisplay
      )
    : [];

  useEffect(() => {
    if (filtre !== -1) {
      if (is_active === 0) {
        dispatch(getAllCompanyDrugGraph(filtre));
        dispatch(setCompanyDrugGraphLoadingAnimation(true));
      }
    }
  }, [filtre, is_active, dispatch]);

  filterData?.map((l, index) => {
    if (index !== 0) {
      l[3] = new Date(l[3]);
      l[4] = new Date(l[4]);
    }
    return [l[3], l[4]];
  });

  return (
    <div className="all-treatment-company-graph">
      {load === false ? (
        <TimeLineChart
          dataAD={filterData}
          infoDrug={dataAD}
          TimeLineStart={new Date("2022-01-01")}
          TimeLineEnd={new Date()}
          
        />
      ) : (
        <div className="loading-animation" style={{height:"100px"}}>
          <LoadingAnimation type={"circular"} />
        </div>
      )}
    </div>
  );
}
