import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { filter_by_theraFranchise, getParamId } from "../../../utils";
import { setMyDisease, removeMyDisease } from "../../../Action";
import { LrxMenuInput } from "../../../components";
import { Tooltip } from "@mui/material";
import { IconButton } from "@mui/material";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

export function BtnDiseaseDisplay() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const nameItem = useSelector((state) => state.diease.nameDisease);
  const my_disease = useSelector((state) => state.diease.diseaseDisplay);
  const theraFranchise = useSelector(
    (state) => state.diease.theraFranchiseDisplay
  );

  const updateURLParamsRef = useRef();
  const [searchValue, setSearchValue] = useState("");
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  updateURLParamsRef.current = (paramName, paramValue) => {
    const params = new URLSearchParams(window.location.search);
    params.set(paramName, paramValue);
    const nouvelleURL = `${window.location.pathname}?${params}`;
    navigate(nouvelleURL);
  };

  const paramDisease = getParamId(
    "diseases",
    params
  );

  useEffect(() => {
    const id_d = paramDisease.split(";");
    for (let i = 0; i < id_d.length; ++i) {
      const dId = parseInt(id_d[i]);
      if (dId) {
        const c = nameItem.filter(
          (item) => item.id_disease === dId
        );
        if (c) {
          dispatch(setMyDisease(c[0]));
        }
      }
    }
  }, [dispatch, nameItem, paramDisease]);

  useEffect(() => {
    updateURL();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, my_disease]);

  const updateURL = (event) => {
    let disease_sent = my_disease
      ? my_disease
          .map(function (el) {
            return el["id_disease"];
          })
          .join(";")
      : [];
    updateURLParamsRef.current("diseases", disease_sent);
  };

  const handleChange = (event) => {
    const itemName = event.target.name;
    const selectedItem = my_option.find(
      (item) => item.disease_display_name === itemName
    );

    if (my_disease.includes(selectedItem)) {
      dispatch(removeMyDisease(selectedItem.id_disease));
    } else {
      dispatch(setMyDisease(selectedItem));
    }
  };

  const clearFilter = () => {
    my_disease.forEach((item) => {
      dispatch(removeMyDisease(item.id_disease));
    });
  };

  const my_option = filter_by_theraFranchise(nameItem, theraFranchise);

  const diseaseListFiltered = my_option.filter((user) =>
    user.disease_display_name.toLowerCase().includes(searchValue.toLowerCase())
  );

  const listDisease50first = diseaseListFiltered.slice(0, 50);

  return (
    <div className="control-pannel-search-entity">
      <LrxMenuInput
        action_input={setSearchValue}
        name={"Search for a disease"}
      />
      <h3 className="control-pannel-search-entity--nb-select-filter">
        {" "}
        {diseaseListFiltered.length === 0
          ? "Disease not found !"
          : diseaseListFiltered.length + " diseases available"}{" "}
      </h3>
      <h3 className="control-pannel-search-entity--nb-select-filter">
        {" "}
        {my_disease.length === 0
          ? null
          : my_disease.length + " disease(s) selected"}{" "}
      </h3>
      {my_disease.length === 0 ? null : (
        <Tooltip title="Delete">
          <IconButton onClick={clearFilter}>
            <RestartAltIcon />
          </IconButton>
        </Tooltip>
      )}
      <div className="control-pannel-search-entity__list-object-filter">
        <div class="control-pannel-search-entity__list-object-filter--form">
          <form>
            {my_disease.map((item, index) => (
              <div key={index}>
                <label>
                  <input
                    type="checkbox"
                    onChange={handleChange}
                    name={item.disease_display_name}
                    checked={my_disease.includes(item)}
                  ></input>
                  <span>{item.disease_display_name}</span>
                </label>
              </div>
            ))}
          </form>
        </div>
        <div class="control-pannel-search-entity__list-object-filter--form">
          <form>
            {listDisease50first.map((item, index) =>
              my_disease.includes(item) ? null : (
                <div key={index}>
                  <label>
                    <input
                      type="checkbox"
                      onChange={handleChange}
                      name={item.disease_display_name}
                      checked={my_disease.includes(item)}
                    ></input>
                    <span>{item.disease_display_name}</span>
                  </label>
                </div>
              )
            )}
          </form>
        </div>
      </div>
    </div>
  );
}
