import React from "react";
import { useSelector } from "react-redux";

import { WordCloudChart, LoadingAnimation } from "../../components";


export function DiseaseDolWorldCloud() {
  const load = useSelector((state) => state.config.worldcloudDOLProfileLoading);
  const data = useSelector((state) => state.dol.worldcloud_dolprofile_info.total_disease);

  return (
    <div style={{height: "187px"}}>
      {load === false ? (
        <WordCloudChart words={data} />
      ) : (
        <div className="loading-animation" style={{height:"100%"}}>
          <LoadingAnimation type={"circular"} />
        </div>
      )}
    </div>
  );
}
